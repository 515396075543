<navbar></navbar>
<div class="wrapper">
    <div class="main-content" *ngIf="!isLoggedIn && !isGuest">
        <p class="return-link" routerLink="/micarrito"><i class="fas fa-chevron-left"></i> Mi carrito</p>
        <div class="title">
            <p class="front-text">Cuenta</p>
            <!-- <p class="bg-text">Cuenta</p> -->
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <p style="margin-bottom: 20px;">No hemos detectado una sesión iniciada.</p>
        <form [formGroup]="mailForm">
            <label class="primary-label">Correo</label>
            <input type="email" name="guestMail" formControlName="mail" class="primary-input"
                placeholder="Ingresa tu correo">
            <div class="checkout-btns">
                <button class="primary-btn" style="font-size: 18px;" routerLink="/login">Iniciar Sesión</button>
                <button class="secondary-btn" style="font-size: 18px;"
                    [class.disabled-btn]="mailForm.get('mail').invalid" (click)="continueAsGuest()">Continuar como
                    invitado</button>
            </div>
        </form>
    </div>
    <div class="main-content" *ngIf="(isLoggedIn || isGuest) && showAddressForm">
        <p class="return-link" routerLink="/micarrito"><i class="fas fa-chevron-left"></i> Mi carrito</p>
        <div class="title">
            <p class="front-text">dirección de entrega</p>
            <!-- <p class="bg-text">dirección de entrega</p> -->
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <form [formGroup]="addressInfo">
            <ng-container *ngIf="addressList && addressList.length > 0">
                <label class="primary-label">Selecciona una Opción</label>
                <select formControlName="selectedAddress">
                    <option select value=''>Nueva Dirección</option>
                    <option *ngFor="let address of addressList;let i = index" [value]="i">{{address.street}}
                        #{{address.extNumber}} {{address.suburb}}</option>
                </select>
            </ng-container>

            <ng-container *ngIf="addressInfo.get('selectedAddress').value==''">
                <div class="add-address-inputs" [formGroup]="addressInfo">
                    <div class="row">
                        <div class="half-width">
                            <div class="input">
                                <label for="firstName" class="primary-label">Nombres</label>
                                <input type="text" name="firstName" id="firstName" formControlName="firstName"
                                    minlength="2" maxlength="14"
                                    [class.error-outline]="addressInfo.get('firstName').invalid && addressInfo.get('firstName').touched">
                                <p class="error-label"
                                    *ngIf="addressInfo.get('firstName').invalid && addressInfo.get('firstName').touched">
                                    Por favor ingresa un nombre válido. Máximo 14 caracteres.</p>
                            </div>
                        </div>
                        <div class="half-width">
                            <div class="input">
                                <label for="lastName" class="primary-label">Apellidos</label>
                                <input type="text" name="lastName" id="lastName" formControlName="lastName"
                                    minlength="2" maxlength="14"
                                    [class.error-outline]="addressInfo.get('lastName').invalid && addressInfo.get('lastName').touched">
                                <p class="error-label"
                                    *ngIf="addressInfo.get('lastName').invalid && addressInfo.get('lastName').touched">
                                    Por favor ingresa un apellido válido. Máximo 14 caracteres.</p>
                            </div>
                        </div>
                    </div>
                    <label for="email" class="primary-label">Email</label>
                    <input type="text" name="email" id="email" formControlName="email"
                        [class.error-outline]="addressInfo.get('email').invalid && addressInfo.get('email').touched">
                    <p class="error-label" *ngIf="addressInfo.get('email').invalid && addressInfo.get('email').touched">
                        Por favor ingresa un correo válido.</p>
                    <label for="phone" class="primary-label">Teléfono</label>
                    <input type="text" name="phone" id="phone" formControlName="phone" minlength="10" maxlength="10"
                        [class.error-outline]="addressInfo.get('phone').invalid && addressInfo.get('phone').touched">
                    <p class="error-label" *ngIf="addressInfo.get('phone').invalid && addressInfo.get('phone').touched">
                        Por favor ingresa un teléfono válido. Debe contener 10 caracteres.</p>
                    <label for="city" class="primary-label">Ciudad</label>
                    <input type="text" name="city" id="city" formControlName="city" minlength="2"
                        [class.error-outline]="addressInfo.get('city').invalid && addressInfo.get('city').touched">
                    <p class="error-label" *ngIf="addressInfo.get('city').invalid && addressInfo.get('city').touched">
                        Por favor ingresa una ciudad.</p>
                    <div class="row">
                        <div class="half-width">
                            <div class="input">
                                <label for="street" class="primary-label">Calle</label>
                                <input type="text" name="street" id="street" formControlName="street" minlength="2"
                                    maxlength="25"
                                    [class.error-outline]="addressInfo.get('street').invalid && addressInfo.get('street').touched">
                                <p class="error-label"
                                    *ngIf="addressInfo.get('street').invalid && addressInfo.get('street').touched">Por
                                    favor ingresa una calle válida. Máximo 25 caracteres.</p>

                            </div>
                        </div>
                        <div class="half-width">
                            <div class="input">
                                <label for="extNumber" class="primary-label">Número Exterior</label>
                                <input type="text" name="extNumber" id="extNumber" formControlName="extNumber"
                                    minlength="1" maxlength="5"
                                    [class.error-outline]="addressInfo.get('extNumber').invalid && addressInfo.get('extNumber').touched">
                                <p class="error-label"
                                    *ngIf="addressInfo.get('extNumber').invalid && addressInfo.get('extNumber').touched">
                                    Por favor ingresa un número exterior válido. Máximo 5 caracteres.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="half-width">
                            <div class="input">
                                <label for="suburb" class="primary-label">Colonia</label>
                                <input type="text" name="suburb" id="suburb" formControlName="suburb" minlength="2"
                                    maxlength="30"
                                    [class.error-outline]="addressInfo.get('suburb').invalid && addressInfo.get('suburb').touched">
                                <p class="error-label"
                                    *ngIf="addressInfo.get('suburb').invalid && addressInfo.get('suburb').touched">Por
                                    favor ingresa una colonia válida. Máximo 30 caracteres.</p>
                            </div>
                        </div>
                        <div class="half-width">
                            <div class="input">
                                <label for="zipCode" class="primary-label">Código Postal</label>
                                <input type="text" name="zipCode" id="zipCode" formControlName="zipCode" minlength="5"
                                    maxlength="5"
                                    [class.error-outline]="addressInfo.get('zipCode').invalid && addressInfo.get('zipCode').touched">
                                <p class="error-label"
                                    *ngIf="addressInfo.get('zipCode').invalid && addressInfo.get('zipCode').touched">Por
                                    favor ingresa un código postal válido. Debe contener 5 caracteres.</p>
                            </div>
                        </div>
                    </div>
                    <label for="crossStreet" class="primary-label">Entre calles</label>
                    <input type="text" name="crossStreet" id="crossStreet" formControlName="crossStreet"
                        placeholder="Calle 1 y Calle 2" minlength="2" maxlength="35"
                        [class.error-outline]="addressInfo.get('crossStreet').invalid && addressInfo.get('crossStreet').touched">
                    <p class="error-label"
                        *ngIf="addressInfo.get('crossStreet').invalid && addressInfo.get('crossStreet').touched">Por
                        favor ingresa un valor válido. Máximo 35 caracteres.</p>
                    <label for="reference" class="primary-label">Referencia</label>
                    <input type="text" name="reference" id="reference" formControlName="reference"
                        placeholder="¿Cómo podemos identificar tu casa? Por favor incluye el número interior si existe"
                        minlength="2" maxlength="25"
                        [class.error-outline]="addressInfo.get('reference').invalid && addressInfo.get('reference').touched">
                    <p class="error-label"
                        *ngIf="addressInfo.get('reference').invalid && addressInfo.get('reference').touched">Por favor
                        ingresa una referencia válida. Máximo 25 caracteres.</p>
                </div>
            </ng-container>
            <div class="checkout-btns" *ngIf="!showShippingRates">

                <!-- <button class="primary-btn"
                    [class.disabled-btn]="!addressInfo.valid && addressInfo.get('selectedAddress').value==''"
                    (click)="calculateShipping()" style="margin-top: -25px;height: 50px; font-size: 18px;">PROCEDER AL PAGO</button> -->
            </div>
            <!-- <p *ngIf="!showShippingRates" class="disclaimer">Pagando con Oxxo Pay, el envío es gratis y está sujeto a
                las paqueterías disponibles al momento de recibir el pago.</p> -->
            <!-- <div *ngIf="showShippingRates" style="margin-top: 20px;">
                <label class="primary-label">Selecciona una opción</label>
                <div class="shipping-carrier" [class.selected-carrier]="selectedCarrierId==rate.idRate"
                    *ngFor="let rate of shippingRates; let i = index"
                    (click)="selectCarrier(rate.idRate,rate.total,i<3 && hasFreeShipping,rate.carrier)">
                    <div class="carrier-radio">
                        <div class="radio-inner"></div>
                    </div>
                    <div style="display: flex;flex-direction: column;">
                        <p class="shipping-main">{{rate.carrier}} <span class="shipping-total"
                                [class.free-shipping-crossed]="i<3 && hasFreeShipping">{{rate.total | currency}}</span> &nbsp;<span
                                class="shipping-total" *ngIf="i<3 && hasFreeShipping">{{0 | currency}}</span></p>
                        <p class="shipping-secondary">Entrega en {{rate.deliveryDays}} día(s)</p>
                    </div>
                </div>
            </div> -->
        </form>
    </div>


    <div class="main-content" *ngIf="(isLoggedIn || guestMail!='') && showPaymentForm">
        <p class="return-link" (click)="showAddress()"><i class="fas fa-chevron-left"></i> Dirección</p>
        <div class="title">
            <p class="front-text">Método de Pago</p>
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <form [formGroup]="paymentInfo" *ngIf="!isGuest" class="add-address-inputs" id="payment-form">
            <div class="card-images"><img src="/assets/Icons/cards1.png" alt=""><img src="/assets/Icons/cards2.png"
                    alt=""></div>
            <!-- <select formControlName="selectedCard"
                *ngIf="currentUser?.paymentMethods && currentUser?.paymentMethods.length > 0">
                <option selected value="">Nueva tarjeta</option>
                <option [value]="i" *ngFor="let card of currentUser.paymentMethods; let i = index;">{{card.brand |
                    uppercase}} {{card.card_number}}</option>
            </select> -->
            <ng-container *ngIf="paymentInfo.get('selectedCard').value==''">
                <label class="primary-label">Nombre del Tarjeta habiente</label>
                <input type="text" formControlName="name" class="primary-input"
                    placeholder="Nombre tal y como aparece en la tarjeta"
                    [class.error-outline]="paymentInfo.get('name').invalid && paymentInfo.get('name').touched"
                    data-openpay-card="holder_name">
                <p class="error-label" *ngIf="paymentInfo.get('name').invalid && paymentInfo.get('name').touched">Por
                    favor ingresa un nombre.</p>
                <label class="primary-label">Numero de la Tarjeta</label>
                <input type="text" formControlName="number" class="primary-input" placeholder="4242424242424242"
                    mask="0000000000000000"
                    [class.error-outline]="paymentInfo.get('number').invalid && paymentInfo.get('number').touched"
                    data-openpay-card="card_number">
                <p class="error-label" *ngIf="paymentInfo.get('number').invalid && paymentInfo.get('number').touched">
                    Por favor ingresa un número válido.</p>
                <div class="row">
                    <div class="third-width">
                        <div class="input">
                            <label class="primary-label">Mes de expiración</label>
                            <select formControlName="month" name="month" class="primary-input"
                                data-openpay-card="expiration_month">
                                <option selected disabled>Mes</option>
                                <option value="01">Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06">Junio</option>
                                <option value="07">Julio</option>
                                <option value="08">Agosto</option>
                                <option value="09">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                    </div>
                    <div class="third-width">
                        <div class="input">
                            <label class="primary-label">Año de expiración</label>
                            <select formControlName="year" name="year" class="primary-input"
                                data-openpay-card="expiration_year">
                                <option selected disabled>Año</option>
                                <option value="21">2021</option>
                                <option value="22">2022</option>
                                <option value="23">2023</option>
                                <option value="24">2024</option>
                                <option value="25">2025</option>
                                <option value="26">2026</option>
                                <option value="27">2027</option>
                                <option value="28">2028</option>
                                <option value="29">2029</option>
                                <option value="30">2030</option>
                            </select>
                        </div>
                    </div>
                    <div class="third-width">
                        <div class="input">
                            <label class="primary-label">CVC</label>
                            <input type="text" formControlName="cvc" class="primary-input" placeholder="Ej: 123"
                                mask="0000"
                                [class.error-outline]="paymentInfo.get('cvc').invalid && paymentInfo.get('cvc').touched"
                                data-openpay-card="cvv2">
                            <p class="error-label"
                                *ngIf="paymentInfo.get('cvc').invalid && paymentInfo.get('cvc').touched">Por favor
                                ingresa un cvc.</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- <div class="input" *ngIf="paymentInfo.get('selectedCard').value!=''">
                <label class="primary-label">CVC</label>
                <input type="text" formControlName="cvc" class="primary-input" placeholder="Ej: 123" mask="0000"
                    [class.error-outline]="paymentInfo.get('cvc').invalid && paymentInfo.get('cvc').touched"
                    data-openpay-card="cvv2">
                <p class="error-label" *ngIf="paymentInfo.get('cvc').invalid && paymentInfo.get('cvc').touched">Por
                    favor
                    ingresa un cvc.</p>
            </div> -->
            <div class="checkout-btns">
                <div class="openpay">
                    <div class="openpay-logo">
                        Transacciones realizadas via:
                        <div class="openpay-banner"><img src="/assets/Logos/openpay.png" alt=""></div>
                    </div>
                    <div class="secure-banner">
                        <img src="/assets/Icons/security.png">Tus pagos se realizan de forma segura con encriptación de
                        256 bits.
                    </div>
                </div>
                <!-- <button class="primary-btn" (click)="createToken()">Token</button> -->
                <button class="primary-btn" (click)="payWithOpenPay()"
                    [class.btn-disabled]="paymentInfo.invalid && paymentInfo.get('selectedCard').value==''">PAGAR</button>
            </div>
        </form>
        <div *ngIf="isGuest" style="line-height: 1.5em  ;">
            <p>Los invitados no pueden pagar utilizando tarjeta de crédito. Para usar una tarjeta de crédito o débito
                por favor
                inicia sesión.</p>
        </div>
    </div>
    <div class="main-content" *ngIf="showBankTransfer">
        <div class="title">
            <p class="front-text">Transferencia Bancaria</p>
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <div class="bank-details">
            <p style="line-height:1.5em">Se ha generado tu recibo de pago. Puedes consultarlo <a target="_blank"
                    [href]="bankTransfer.url">aquí</a> o desde la sección "Mi Cuenta" en cualquier momento. Es necesario
                seguir las instrucciones incluidas en tu recibo para completar tu pedido.</p>
            <button class="primary-btn" (click)="createOrderWithBankTransfer()">CONFIRMAR PEDIDO</button>
        </div>
    </div>
    <div class="main-content" *ngIf="showCashPayment">
        <div class="title">
            <p class="front-text">Pago en Tienda</p>
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <div class="bank-details">
            <p style="line-height:1.5em">Se ha generado tu recibo de pago. Puedes consultarlo <a target="_blank"
                    [href]="storePayment.url">aquí</a> o desde la sección "Mi Cuenta" en cualquier momento. Es necesario
                seguir las instrucciones incluidas en tu recibo para completar tu pedido.</p>
            <button class="primary-btn" (click)="createOrderWithCashPayment()">CONFIRMAR PEDIDO</button>
        </div>
    </div>
    <div class="sidebar">
        <p class="summary-title">Resumen de compra</p>
        <p class="checkout-cost">Subtotal: <span style="float: right; font-weight: 700;">{{totalCost | currency}}</span>
        </p>
        <p class="checkout-cost" *ngIf="shippingCost==0 && totalCost<2000 && !freeShippingCoupon">Envio: <span
                style="float: right; font-weight: 700;"><img class="dhl-logo"
                    src="../../../assets/Logos/Paqueterias/DHL.png" alt="">Por calcular</span></p>
        <p class="checkout-cost" *ngIf="(shippingCost==0 && totalCost>=2000) || freeShippingCoupon ">Envio: <span
                style="float: right; font-weight: 700;"><img class="dhl-logo"
                    src="../../../assets/Logos/Paqueterias/DHL.png" alt="">Gratis</span></p>
        <p class="checkout-cost" *ngIf="shippingCost!=0 && !freeShippingCoupon">Envio: <span
                style="float: right; font-weight: 700;"><img class="dhl-logo"
                    src="../../../assets/Logos/Paqueterias/DHL.png" alt="">{{shippingCost |
                currency}}</span></p>
        <p class="checkout-cost">Descuento <span style="float: right; font-weight: 700;">-{{discount | currency}}</span>
        </p>
        <p class="discount-title">Código de descuento</p>
        <div class="coupon-input-container"><input type="text" placeholder="Ingrese un código" class="coupon-input"
                [(ngModel)]="couponCode" (keyup.enter)="applyCoupon()"><button class="btn-link"
                (click)="applyCoupon()">Aplicar</button></div>
        <p class="valid-code">{{couponText}}</p>
        <p class="invalid-code">{{couponError}}</p>
        <div class="show-payment-btns" *ngIf="!(showPaymentForm || showBankTransfer || showCashPayment)">
            <button class="primary-btn" (click)="showPayment()"
                [class.disabled-btn]="!addressInfo.valid && addressInfo.get('selectedAddress').value==''">IR AL
                PAGO</button>
        </div>

        <div class="checkout-section" [class.paypal-hidden]="!(showPaymentForm || showOxxoPay)">
            <p class="checkout-cost">Total: <span
                    style="float: right; font-weight: 700;">{{totalCost+shippingCost-discount | currency}}</span></p>
            <!-- <div class="card-images"><img src="../../../assets/Logos/cards.png"></div> -->
            <!-- <button class="primary-btn" style="display: none;" (click)="payWithStripe()" *ngIf="isLoggedIn"
                [class.btn-disabled]="paymentInfo.invalid && paymentInfo.get('selectedCard').value==''">PAGAR CON
                TARJETA</button> -->
            <!-- <button class="primary-btn oxxo-btn"
                [class.disabled-btn]="(!addressInfo.valid && addressInfo.get('selectedAddress').value=='') || totalCost>10000 "
                style="height: 40px;display: none;" *ngIf="this.currentUser" (click)="displayOxxoPay()"><img
                    src="../../../assets/Icons/oxxopay.svg"></button> -->
            <button class="primary-btn mercadopago-btn" style="margin-top: 10px; margin-bottom: 10px;"
                (click)="initMercadoPago()" [class.paypal-hidden]="!showPaymentForm"><img
                    src="../../../assets/Logos/mercadopago.png" alt="Mercado Pago" class="btn-logo"></button>
            <div #paypal></div>
            <button style="margin-top: 10px; margin-bottom: 10px;" class="primary-btn" (click)="payWithOpenPayBank()"
                *ngIf="!isGuest">TRANSFERENCIA BANCARIA</button>
            <button style="margin-top: 10px; margin-bottom: 10px;" class="primary-btn" (click)="payWithOpenPayCash()"
                *ngIf="!isGuest">PAGO EN TIENDA</button>
        </div>

    </div>
    <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium" name="paymentSpinner">
        <p class="loading">Procesando pago...</p>
    </ngx-spinner>
    <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium" name="shippingSpinner">
        <p class="loading">Calculando envíos...</p>
    </ngx-spinner>
    <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium" name="OxxoLoader">
        <p class="loading">Generando Ficha de Pago...</p>
    </ngx-spinner>
    <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium" name="genericSpinner">
        <p class="loading">Cargando...</p>
    </ngx-spinner>
</div>