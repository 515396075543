import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { map } from 'rxjs/operators';
import { CartService } from './cart.service';
import { ProductService } from './product.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private orderList;
  private orderCollection: AngularFirestoreCollection

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': 'Basic c2tfOGQ1YTFlZTFkYzYxNDE0NzgzZmZkN2I4MTE2NWQzODQ6'
    })
  }

  constructor(private firestore: AngularFirestore,
    public productService: ProductService,
    public cartService: CartService,
    public userService: UserService, private http: HttpClient,
    private functions: AngularFireFunctions) {
    this.orderCollection = this.firestore.collection('orders');
    this.orderList = this.orderCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

  }

  async addOrder(order, cart, products, userID) {
    return new Promise(async (resolve, reject) => {
      await this.clearCart(cart, products, userID)
      this.orderCollection.add(order).then((docRef) => {
        console.log(docRef.id);
        resolve(docRef.id)
      })
    })
  }

  clearCart(cart, products, userID) {
    for (let item of cart.items) {
      for (let product of products) {
        if (product.id == item.parentId) {
          product.subproducts[item.index].stock -= item.quantity
          this.productService.updateProduct(product.id, product)
        }
      }
    }
    localStorage.setItem('cartGM', JSON.stringify({ items: [] }))
    this.cartService.setTokens({ items: [] });
    if (userID && userID != '') return this.userService.changeCart(userID, false)
  }

  updateOrder(id, order) {
    return this.orderCollection.doc(id).update(order);
  }

  getOrders() {
    return this.orderList;
  }

  getOrderById(id) {
    return this.orderCollection.doc(id).valueChanges();
  }

  getOrdersByUserId(uid) {
    let orderCollection = this.firestore.collection<any>('orders', ref => ref.where('uid', '==', uid));
    let orderList = orderCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )
    return orderList
  }

  deleteOrder(id) {
    return this.orderCollection.doc(id).delete();
  }

  async getOrderStatus(customer_id, transaction_id) {
    let getOrder = this.functions.httpsCallable('getOpenpayOrderStatus')
    return getOrder({ customer_id, transaction_id }).toPromise()
    /* return await this.http.get(`https://api.openpay.mx/v1/m3bhmq0oerrym5ucu4ly/charges/${transaction_id}`, this.httpOptions).toPromise() */
  }

}
