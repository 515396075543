<navbar></navbar>
<div class="wrapper">
    <p class="header">Aviso de Privacidad</p>
    <pre class="content">
En cumplimiento a las disposiciones legales relacionadas con la Protección de Datos Personales en Posesión de los Particulares, Guerrero Mobile Voz y Datos, S.A. de C.V., (Guerrero Mobile), con domicilio en Calle Coscomate No. 71, Colonia Toriello Guerra, Código Postal 14050, Alcaldía Tlalpan, Ciudad de México, hace de su conocimiento que sus datos personales (identificación, patrimoniales, financieros), y sensibles (geolocalización de dispositivo) se tratarán de acuerdo con las siguientes finalidades:

A. Primarias
    • Clientes (proponentes o solicitantes).
Se utilizarán para realizar transacciones comerciales, prevención y detección de fraude y operaciones ilícitas, venta de productos, así como recibir y dar seguimiento a las solicitudes para el ejercicio de los Derechos de los Titulares.

B. Secundarias
    • Con fines de mercadotecnia, publicidad o investigación comercial para ofrecerle otros de nuestros productos, servicios, promociones y para el caso de clientes, además de lo anterior, beneficios adicionales.
    • Con fines de evaluar la calidad del servicio y realizar estudios estadísticos.
    • Con la finalidad de invitarlo a participar en concursos, actividades recreativas o altruistas.
    • Invitarlo a participar en estudios de mercado que permitan conocer su opinión sobre sitios web, plataformas digitales o aplicaciones que tienen como propósito verificar la posibilidad de mejorar cualquiera de sus elementos, para su posterior uso en una versión productiva.
Si usted no está de acuerdo con las finalidades secundarias, podrá manifestar su negativa. Deberá proporcionar su escrito con atención al Departamento de Protección de Datos Personales mediante correo electrónico a la dirección notificaciones@gmeshop.com.mx, según corresponda:
    • En caso de haber proporcionado a Guerrero Mobile sus datos en forma personali o directaii, podrá hacerlo en cualquier momento.
    • Si Guerrero Mobile obtiene sus datos de forma indirectaiii, tendrá un plazo de cinco días hábiles.
Los datos a que se refiere este Aviso podrán ser transferidos a:
    • Sociedades relacionadas o subsidiarias, con la finalidad de ofrecerle otros productos y servicios;
    • Autoridades mexicanas y extranjeras, con la finalidad de dar cumplimiento a nuestras obligaciones derivadas de leyes, tratados o acuerdos internacionales como institución de seguros, obligaciones tributarias, así como para el cumplimiento de notificaciones o requerimientos oficiales o de carácter judicial.
    • Entidades extranjeras para el mantenimiento o cumplimiento de la relación jurídica con Guerrero Mobile.

En caso de que realicemos alguna transferencia adicional que requiera su consentimiento expreso, se recabará el mismo previamente.
Para ejercer su derecho de Revocación o el ejercicio de sus derechos ARCOiv, deberá proporcionar su escrito con atención al Departamento de Protección de Datos Personales mediante correo electrónico a la dirección notificaciones@gmeshop.com.mx.
Además del ejercicio de sus derechos de Revocación o ARCO, usted podrá limitar el uso o divulgación de sus datos personales, mediante su inscripción en el Registro Público para Evitar la Publicidad (REPEP).
Guerrero Mobile en sus sitios Web utiliza cookies para ayudarnos a optimizar su experiencia de compra. A través del uso de cookies podemos, por ejemplo, personalizar en su favor nuestra página de inicio de manera que nuestras pantallas se desplieguen de mejor manera de acuerdo a su tipo de navegador. Las cookies también nos permiten ofrecerle recomendaciones personalizadas respecto de productos o servicios, y correos electrónicos. Las cookies no son software espía, y www.gmeshop.mx no recopila datos de múltiples sitios o comparte con terceros la información que obtenemos a través de cookies.  
Los sitios pueden permitir emitir publicidad o la ejecución de funciones de terceros que envían "cookies" al equipo de cómputo de los usuarios. Mediante el uso de estas "cookies" no se identifica personalmente a los usuarios, únicamente a su equipo de cómputo.
Los usuarios podrán cambiar sus opciones a través de sus equipos de cómputo y/o navegador de Internet para dejar de aceptar "cookies" o bien confirmar si las permiten o rechazan. Sin embargo, si desactiva las “cookies” puede ocasionar, no tener acceso a algunas de las funciones personalizadas de nuestro Sitio Web.
Asimismo, Guerrero Mobile utiliza web beacons. Los web beacons también nos permiten monitorear su comportamiento en medios electrónicos. Utilizamos web beacons para determinar cuándo y cuántas veces una página ha sido vista. Utilizamos esta información para fines de mercadotecnia, pero únicamente para nuestras propias prácticas de mercadotecnia. 
En caso de realizar cambios o actualizaciones al presente Aviso, Guerrero Mobile los publicará en la página www.gmeshop.mx.
Para cualquier asunto o duda relacionada con este Aviso de Privacidad, puede contactarnos al correo electrónico notificaciones@gmeshop.com.mx, o bien, al siguiente número telefónico: 55 3471 2452.
En todo caso recomendamos visite la página de internet frecuentemente.
En caso de alguna duda puede usted consultar al INAI en www.inai.org.mx
Última actualización: 15 de Junio de 2021.
___________________________________________________
i Cuando el Titular entrega los datos de forma personal y física a Guerrero Mobile o a su representante.
ii Cuando el Titular entrega los datos de forma directa, como: Medios electrónicos, ópticos, sonoros, visuales, o cualquier otra tecnología como correo postal, internet o vía telefónica.
iii Cuando Guerrero Mobile obtiene los datos de forma indirecta como: fuente de acceso público o transferencia.
iv ARCO: derechos de Acceso, Rectificación, Cancelación y Oposición
    </pre>
</div>