import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Splide from '@splidejs/splide';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { CharacteristicService } from 'src/app/services/characteristic.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';

declare var gtag;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  product
  id = ""
  selectedImage
  selectedColor
  selectedMemory
  selectedSection
  errorText
  url360
  show360 = false
  splideRelated
  discountedPrice
  relatedProducts
  shareURL = ""
  twitterURL = ""
  cart = { items: [] }
  hasChars = false

  subproductIndex
  selectedSubproduct

  currentRate = 0

  charsToSelect = []
  colorsToSelect = []

  selectedValues = []

  extraChars

  currentUser
  userWishlist = []
  uid

  utm_source = ''
  utm_medium = ''
  utm_campaign = ''

  icons = [
    'https://firebasestorage.googleapis.com/v0/b/gm-eshop.appspot.com/o/specs%2Fscreen-size.png?alt=media&token=32e3f495-ef6c-417b-a322-ba0780445dc0',
    'https://firebasestorage.googleapis.com/v0/b/gm-eshop.appspot.com/o/specs%2Fcpu.png?alt=media&token=99763ff8-8da1-4c58-9ea1-d629544903af',
    'https://firebasestorage.googleapis.com/v0/b/gm-eshop.appspot.com/o/specs%2FRAM.png?alt=media&token=80a4dd2c-ee51-404a-9a45-0f123ed2e351',
    'https://firebasestorage.googleapis.com/v0/b/gm-eshop.appspot.com/o/specs%2FOS.png?alt=media&token=8698356e-c4b2-4d4b-a7cf-6625b0f8aeb3',
    'https://firebasestorage.googleapis.com/v0/b/gm-eshop.appspot.com/o/specs%2Fcamera.png?alt=media&token=52a9596d-c7c4-41af-8d70-7364380b3c36',
    'https://firebasestorage.googleapis.com/v0/b/gm-eshop.appspot.com/o/specs%2Fbattery.png?alt=media&token=955971d3-8f3a-4de7-b4d3-c1da18fbf85e',
  ]

  constructor(private route: ActivatedRoute,
    public router: Router,
    public productService: ProductService,
    public cartService: CartService,
    private sanitizer: DomSanitizer,
    public charService: CharacteristicService,
    public userService: UserService,
    public firebaseAuthService: AuthService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.utm_source = this.route.snapshot.queryParams['utm_source'];
    this.utm_medium = this.route.snapshot.queryParams['utm_medium'];
    this.utm_campaign = this.route.snapshot.queryParams['utm_campaign'];


    this.productService.getProductById(this.id).pipe(first()).subscribe((res) => {
      this.product = res;
      gtag('event', 'producto_visto', {
        'utm_source': this.utm_source,
        'utm_medium': this.utm_medium,
        'utm_campaign': this.utm_campaign,
        'Producto': this.product.name
      })
      localStorage.setItem('utms', JSON.stringify({ campaign: this.utm_campaign, source: this.utm_source, medium: this.utm_medium, product: this.product.name }))
      if (this.product.reviews) {
        this.product.reviews.sort((a, b) => {
          if (a.date < b.date) return 1
          return -1
        })
        for (let review of this.product.reviews) {
          review.date = this.transformDate(review.date)
        }
      }

      this.firebaseAuthService.getCurrentUser().then((res) => {
        if (res != undefined) {
          this.uid = res.uid
          this.userService.getUserById(res.uid).subscribe((res) => {
            this.currentUser = res;
            this.userWishlist = []
            for (let product of res.wishlist) {
              this.userWishlist.push(product.id)
            }
          })
        }
      });

      this.route.queryParams.subscribe(async (params) => {
        this.subproductIndex = params.subproductIndex
        if (!params.subproductIndex) this.subproductIndex = 0
        this.selectedSubproduct = this.product.subproducts[this.subproductIndex]
        this.charsToSelect = []
        this.selectedValues = []
        if (!this.hasChars) {
          this.populateChars()
          this.hasChars = true
        }
        this.charService.getNonVariableChars().pipe(first()).subscribe((res: any) => {
          this.extraChars = []
          for (let char of res) {
            if (!this.extraChars.includes(char.name) && this.selectedSubproduct.chars[char.name]) {
              this.extraChars.push(char.name)
            }
          }
        })
        if (this.product.reviews) this.calculateRating()
        if (!this.product.reviews) this.currentRate = 0
      })
      this.discountedPrice = this.selectedSubproduct.price - (this.selectedSubproduct.price * (this.selectedSubproduct.discount / 100))
      if (this.selectedSubproduct.discount == 0 || !this.selectedSubproduct.discount) this.discountedPrice = this.selectedSubproduct.price
      this.url360 = this.sanitizer.bypassSecurityTrustHtml(res.img360)
      this.selectedImage = this.selectedSubproduct.images[0]
      this.productService.getProductsByCategory(this.product.category).subscribe(res => {
        res = res.filter(product => product.subproducts?.length && product.isVisible)
        for (var i = res.length - 1; i >= 0; --i) {
          res[i].totalStock = res[i].subproducts.reduce((previous, current) => previous + current.stock, 0)
          let colors = []
          if (!res[i].subproducts.length) {
            res.splice(i, 1);
          } else {
            for (let product of res[i].subproducts) {
              if (!colors.some(color => color.code == product.chars.Color.code)) {
                colors.push(product.chars.Color)
              }
            }
          }
          res[i].colors = colors
        }
        this.relatedProducts = res.sort((a, b) => {
          if (a.date < b.date) return 1
          return -1
        })
        setTimeout(() => {
          try {
            this.splideRelated = new Splide('#splide_related', {
              perPage: 4,
              drag: false,
              type: 'loop',
              classes: {
                prev: 'splide__arrow--prev prev-arrow-new-grey',
                next: 'splide__arrow--next next-arrow-new-grey',
              },
              pagination: false,
              breakpoints: {
                800: {
                  perPage: 1,
                }
              }
            }).mount();
          } catch (error) { }
        }, 500)

      })
    });

    this.shareURL = `https://gmeshop.mx/#/producto/${this.id}`
    this.twitterURL = `https://twitter.com/intent/tweet?text=¡Checa este producto que encontré en la tienda de GM e-shop, está increíble y a un súper precio!&url=https%3A%2F%2Fgmeshop.mx%2F%23%2Fproducto%2F${this.id}`
    this.selectedSection = "caracteristicas"
    this.cartService.getTokens().subscribe((res) => {
      this.cart = res;
    })
    if (localStorage.getItem('cartGM')) {
      this.cartService.setTokens(JSON.parse(localStorage.getItem('cartGM')));
    } else {
      localStorage.setItem('cartGM', JSON.stringify({ items: [] }))
      this.cartService.setTokens({ items: [] });
    }
  }

  transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: "numeric", month: "long", year: "numeric" };
    return d.toLocaleDateString("es-ES", options);
  }

  calculateRating() {
    let tempSum = 0
    for (let review of this.product.reviews) {
      tempSum += review.rating
    }
    this.currentRate = (tempSum / this.product.reviews.length)
  }

  populateChars() {
    this.charService.getVariableChars().pipe(first()).subscribe((res: any) => {
      this.charsToSelect = []
      this.selectedValues = []
      for (let char of res) {
        let hasValue = false
        if (char.name != 'Color') {
          this.charsToSelect.push({ name: char.name, values: [] })
        }
        for (let subproduct of this.product.subproducts) {
          let array = []
          if (char.name != 'Color') {
            array = this.charsToSelect[this.charsToSelect.length - 1].values
          }
          if (subproduct.chars[char.name] && !array.includes(subproduct.chars[char.name])) {
            if (char.name != 'Color' && subproduct.chars[char.name]) {
              array.push(subproduct.chars[char.name])
              if (!hasValue) {
                this.selectedValues.push(this.selectedSubproduct.chars[char.name])
                hasValue = true
              }
            }
            else if (!this.hasColor(this.colorsToSelect, subproduct.chars[char.name].name)) {
              this.colorsToSelect.push(subproduct.chars[char.name])
            }
          }
        }
        if (this.charsToSelect.length > 0 && this.charsToSelect[this.charsToSelect.length - 1].values.length == 0) this.charsToSelect.pop()
      }
      this.selectedColor = this.colorsToSelect[0].name
      for (let char of this.charsToSelect) {
        char.values.sort((a, b) => {
          if (a < b) return 1
          return -1
        })
      }
    })
  }

  hasColor(array, color) {
    for (let item of array) {
      if (item.name == color) return true
    }
    return false
  }

  selectImage(image) {
    this.show360 = false
    this.selectedImage = image;
  }

  select360() {
    this.selectedImage = ''
    this.show360 = true
  }

  selectColor(color) {
    if (this.findSubproduct(color, this.selectedValues)) return this.selectedColor = color;
    this.charsToSelect[0].values.forEach((value) => {
      if (this.findSubproduct(color, [value])) {
        this.selectedColor = color
        return this.selectedValues[0] = value
      }
    })
    /* window.alert('No hay un producto con esas características.')
    var mobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
    if(mobile)alert('No hay un producto con esas características.') */
  }

  selectChar(option, i) {
    let tempValues = this.selectedValues.concat([])
    tempValues[i] = option
    if (this.findSubproduct(this.selectedColor, tempValues)) return this.selectedValues[i] = option;
    window.alert('No hay un producto con esas características.')
    var mobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
    if (mobile) alert('No hay un producto con esas características.')
  }

  existsCharCombination(option, i) {
    let tempValues = this.selectedValues.concat([])
    tempValues[i] = option
    return this.findSubproduct(this.selectedColor, tempValues)
  }

  combinationHasStock(option, i) {
    let tempValues = this.selectedValues.concat([])
    tempValues[i] = option
    let subproduct = this.findSubproductAlt(this.selectedColor, tempValues)
    if (!subproduct) return false
    return subproduct.stock > 0
  }

  findSubproduct(color, selectedValues) {
    let i = 0
    for (let subproduct of this.product.subproducts) {
      let willSelect = true
      if (subproduct.chars.Color.name == color) {
        let x = 0
        for (let char of selectedValues) {
          if (subproduct.chars[this.charsToSelect[x].name] != char) {
            willSelect = false
          }
          x++
        }
      } else {
        willSelect = false
      }
      if (willSelect) {
        this.selectedSubproduct = subproduct
        this.selectedImage = this.selectedSubproduct.images[0]
        this.show360 = false
        this.subproductIndex = i
        this.discountedPrice = this.selectedSubproduct.price - (this.selectedSubproduct.price * (this.selectedSubproduct.discount / 100))
        return true
      }
      i++
    }
    return false
  }

  findSubproductAlt(color, selectedValues) {
    let i = 0
    for (let subproduct of this.product.subproducts) {
      let willSelect = true
      if (subproduct.chars.Color.name == color) {
        let x = 0
        for (let char of selectedValues) {
          if (subproduct.chars[this.charsToSelect[x].name] != char) {
            willSelect = false
          }
          x++
        }
      } else {
        willSelect = false
      }
      if (willSelect) {
        return subproduct
      }
      i++
    }
    return null
  }

  selectSection(section) {
    this.selectedSection = section
  }

  addToCart() {
    let tempProduct = {
      parentId: this.product.id,
      price: this.selectedSubproduct.price,
      discount: this.selectedSubproduct.discount,
      name: this.selectedSubproduct.name,
      quantity: 1,
      image: this.selectedSubproduct.images[0],
      chars: this.selectedSubproduct.chars,
      weight: this.selectedSubproduct.weight,
      size: this.selectedSubproduct.size,
      index: this.subproductIndex,
      ProductCode: this.selectedSubproduct.ProductCode || '',
      UnitCode: this.selectedSubproduct.UnitCode || '',
      Unit: this.selectedSubproduct.Unit || ''
    }
    let productFound = false;
    for (let i of this.cart.items) {
      if (i.name === tempProduct.name) {
        i.quantity++;
        productFound = true;
      }
    }
    if (this.currentUser) this.userService.changeCart(this.currentUser.id, true)
    if (!productFound) this.cart.items.push(tempProduct);
    this.cartService.setTokens(this.cart);
    localStorage.setItem('cartGM', JSON.stringify(this.cart))
    alert('Producto agregado al carrito')
  }

  addProductAndBuy() {
    this.addToCart()
    this.router.navigate(['/micarrito'])
  }

  navigateTo(product) {
    console.log(product);
    this.router.navigate(["/producto", product]).then(() => {
      location.reload()
    })
  }

  addToWishlist(id) {
    console.log('click')
    let wishlist = []
    if (this.currentUser.wishlist) {
      wishlist = this.currentUser.wishlist
    }
    wishlist.push({ id: id, date: new Date() })
    this.userService.updateWishlist(this.uid, wishlist)
  }

  removeFromWishlist(id) {
    let wishlist = []
    if (this.currentUser.wishlist) {
      wishlist = this.currentUser.wishlist
      let index = this.findWithAttr(wishlist, 'id', id)
      if (index != -1) {
        wishlist.splice(index, 1)
        this.userService.updateWishlist(this.uid, wishlist)
      }
    }
  }

  findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

}
