<navbar></navbar>
<div class="product-wrapper" *ngIf="selectedSubproduct">
    <div class="product-options">
        <p class="return" [routerLink]="['/tienda','todos','todos']"><i class="fas fa-chevron-left"></i>
            {{product.category | titlecase}} / {{selectedSubproduct.name}}</p>
        <div class="product-imgs" *ngIf="selectedSubproduct.images">
            <div class="thumbnail" [class.selected]="selectedImage==image"
                *ngFor="let image of selectedSubproduct.images;" (mouseover)="selectImage(image)"
                (click)="selectImage(image)">
                <img [src]="image">
            </div>
        </div>
        <div class="product-main-img" *ngIf="!show360">
            <img [src]="selectedImage">
            <img src="../../../assets/Icons/wishlist-on.png"
                (click)="removeFromWishlist(product.id); $event.stopPropagation();" class="wishlist"
                *ngIf="currentUser && currentUser.wishlist && userWishlist.includes(product.id)">
            <img src="../../../assets/Icons/wishlist-off.png"
                (click)="addToWishlist(product.id); $event.stopPropagation();" class="wishlist"
                *ngIf="currentUser && (!currentUser.wishlist || !userWishlist.includes(product.id))">
        </div>
        <div class="product-details">
            <div class="title">
                <p class="front-text">{{selectedSubproduct.name}}</p>
                <ng-template #t let-fill="fill">
                    <span class="star" [class.full]="fill === 100">
                        <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                    </span>
                </ng-template>
                <ngb-rating [(rate)]="currentRate" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
                <!-- <div style="display: flex;"><p class="rating-text">{{currentRate | number: '1.0-2'}}</p> -->
                <!-- <p class="bg-text">{{selectedSubproduct.name}}</p> -->
            </div>
            <div class="tags">
                <div class="new" *ngIf="selectedSubproduct.isNew">
                    <img src="assets/Icons/Star.svg" style="height: 50%;">LANZAMIENTO
                </div>
                <div class="discount" *ngIf="selectedSubproduct.discount > 0">
                    <img src="assets/Icons/Discount.svg" style="height: 50%;"><span
                        style="color:#4273DF; font-weight: 700;">{{((selectedSubproduct.price -
                        selectedSubproduct.discount)*100)/selectedSubproduct.price |
                        number: '1.1-1'}}%</span> DESCUENTO
                </div>
                <div class="sold-out" *ngIf="selectedSubproduct.stock<=0">
                    <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
                </div>
            </div>
            <div class="colors">
                <p>Colores:</p>
                <div class="color-options">
                    <div (click)="selectColor(color.name)" class="color-option"
                        [class.selected-color]="selectedColor==color.name" [style]='"background-color:"+ color.code+";"'
                        *ngFor="let color of colorsToSelect"></div>
                </div>
            </div>
            <div class="memory" *ngFor="let char of charsToSelect;index as i">
                <p>{{char.name}}:</p>
                <div class="memory-options">
                    <div class="memory-option" [class.selected-filled]="selectedValues[i]==option"
                        [class.disabled]="!existsCharCombination(option,i)" *ngFor="let option of char.values"
                        (click)="selectChar(option,i)">{{option}}<div class="crossed-option"
                            *ngIf="!combinationHasStock(option,i)"></div>
                    </div>

                </div>
            </div>
            <div class="social-media">
                <p>Compartir en:</p>
                <div class="social-icons">
                    <a class="twitter-share-button" [href]="twitterURL" [data-share-url]="shareURL">
                        <i class="fab fa-twitter"></i></a>
                    <div class="fb-share-button" [data-href]="shareURL" data-layout="button" data-size="small"><a
                            target="_blank"
                            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fgmeshop.mx%2F%23%2Fproducto%2FIhbZJNHF0ExQb1nF3G70&amp;src=sdkpreparse"
                            class="fb-xfbml-parse-ignore"><i class="fab fa-facebook"></i></a></div>
                </div>
            </div>
            <div class="product-price">
                <p [class.crossed-price]="selectedSubproduct.discount > 0">{{selectedSubproduct.price | currency}}</p>
                <p *ngIf="selectedSubproduct.discount > 0">{{selectedSubproduct.discount | currency}}</p>
            </div>
            <div class="product-btns">
                <button class="secondary-btn" [class.disabled-btn]="selectedSubproduct.stock<=0"
                    (click)="addToCart()">Agregar al
                    carrito</button>
                <button class="primary-btn" [class.disabled-btn]="selectedSubproduct.stock<=0"
                    (click)="addProductAndBuy()">Comprar ahora</button>
            </div>
            <p class="out-of-stock-text" *ngIf="selectedSubproduct.stock<=0">Este producto está agotado. Por favor
                selecciona otro o intenta más tarde.</p>
        </div>
    </div>
    <div class="product-sections">
        <p class="section-tab" [class.selected-tab]="selectedSection=='caracteristicas'"
            (click)="selectSection('caracteristicas')">Descripción</p>
        <p class="section-tab" [class.selected-tab]="selectedSection=='especificaciones'"
            (click)="selectSection('especificaciones')">Especificaciones</p>
        <p class="section-tab" [class.selected-tab]="selectedSection=='reseñas'" (click)="selectSection('reseñas')">
            Reseñas</p>
    </div>
    <div class="product-section" *ngIf="selectedSection=='caracteristicas'">
        <p class="section-title">Descripción</p>
        <p class="section-text">{{product.description}}</p>
    </div>
    <div class="product-section" *ngIf="selectedSection=='especificaciones'">
        <p class="section-title">Especificaciones</p>
        <div class="product-specs">
            <ng-container *ngFor="let spec of product.specs|keyvalue;index as i">
                <div class="spec" *ngIf="spec.value.name!=''">
                    <p class="spec-title"><img *ngIf="product.category=='Smartphones'"
                            [src]="icons[i]">{{spec.value.name}}</p>
                    <p class="spec-text">{{spec.value.desc}}</p>
                </div>
            </ng-container>
        </div>
        <div class="non-variable-chars">
            <p class="section-title">Características</p>
            <div class="extra-char-list">
                <p *ngFor="let char of extraChars;index as i"><span style="font-weight: 500;">{{extraChars[0]}}:</span>
                    {{selectedSubproduct.chars[extraChars[0]]}}</p>
            </div>
        </div>
    </div>
    <div class="product-section" *ngIf="selectedSection=='reseñas'">
        <ng-container *ngIf="product.reviews">
            <p class="section-title">Reseñas ({{product.reviews.length}})</p>
            <div class="ratings">
                <div class="reviews">
                    <div class="review" *ngFor="let review of product.reviews">
                        <div class="review-rating">
                            <ngb-rating [(rate)]="review.rating" [starTemplate]="t" [readonly]="true" [max]="5">
                            </ngb-rating>
                        </div>
                        <p class="review-name">{{review.name}}<br>{{review.date}}</p>
                        <p class="review-text">{{review.review}}</p>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!product.reviews">
            <p class="section-title">Reseñas (Sin reseñas)</p>
        </ng-container>
    </div>
    <div class="related-products" *ngIf="relatedProducts && relatedProducts.length > 1">
        <div class="title title-small">
            <p class="front-text">Productos Relacionados</p>
            <!-- <p class="bg-text">Productos Similares</p> -->
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <div class="splide" id="splide_related">
            <div class="splide__track">
                <ul class="splide__list">
                    <ng-container *ngFor="let relProduct of relatedProducts">
                        <li class="splide__slide product" *ngIf="!(relProduct.id == product.id)"
                            (click)="navigateTo(relProduct.id)">
                            <div class="tags-slider">
                                <p class="new" *ngIf="relProduct.subproducts[0].isNew">LANZAMIENTO</p>
                                <p class="discount" *ngIf="relProduct.subproducts[0].discount > 0">
                                    <span style="color:#4273DF; font-weight: 700;">{{((relProduct.subproducts[0].price -
                                        relProduct.subproducts[0].discount)*100)/relProduct.subproducts[0].price |
                                        number:
                                        '1.1-1'}}%</span> DESCUENTO
                                </p>
                                <p class="sold-out" *ngIf="relProduct.totalStock<=0">AGOTADO</p>
                            </div>
                            <div class="product-img">
                                <img [src]="relProduct.subproducts[0].images[0]">
                            </div>
                            <p class="product-category">{{relProduct.category}}</p>
                            <p class="product-name">{{relProduct.name}} </p>
                            <p class="product-price" *ngIf="relProduct.subproducts[0].discount > 0">
                                {{relProduct.subproducts[0].discount | currency}} <span
                                    class="product-discounted-price crossed-price">{{product.subproducts[0].price |
                                    currency}}</span></p>
                            <p class="product-price" *ngIf="relProduct.subproducts[0].discount <= 0">
                                {{relProduct.subproducts[0].price | currency}}</p>
                            <div class="product-colors">
                                <div class="product-color" [style]='"background-color:"+ color.code+";"'
                                    *ngFor="let color of relProduct.colors"></div>
                            </div>
                            <img src="../../../assets/Icons/wishlist-on.png"
                                (click)="removeFromWishlist(relProduct.id); $event.stopPropagation();" class="wishlist"
                                *ngIf="currentUser && currentUser.wishlist && userWishlist.includes(relProduct.id)">
                            <img src="../../../assets/Icons/wishlist-off.png"
                                (click)="addToWishlist(relProduct.id); $event.stopPropagation();" class="wishlist"
                                *ngIf="currentUser && (!currentUser.wishlist || !userWishlist.includes(relProduct.id))">
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</div>
<footer-inverted></footer-inverted>