<navbar></navbar>
<div class="landing-wrapper">
  <div class="splide" id="splide_main">
    <div class="splide__track">
      <ul class="splide__list">
        <li class="splide__slide main-slide" *ngFor="let slide of sliderImages; index as i"
          [style]="'background-image: url(' + slide + ');'"></li>
      </ul>
    </div>
  </div>
  <div class="landing-categories">
    <div class="category" [routerLink]="['/tienda', 'Computación', 'todos']">
      <img src="/assets/Icons/laptop-outline.svg" alt="" class="category-icon" />
      <p>Computación</p>
    </div>
    <div class="category" [routerLink]="['/tienda', 'Audífonos', 'todos']">
      <img src="/assets/Icons/earbufs-outline.svg" alt="" class="category-icon" />
      <p>Audífonos</p>
    </div>
    <div class="category" [routerLink]="['/tienda', 'Smartphones', 'todos']">
      <img src="/assets/Icons/phone-outline.svg" alt="" class="category-icon" />
      <p>Smartphones</p>
    </div>
    <div class="category" [routerLink]="['/tienda', 'Smartwatch', 'todos']">
      <img src="/assets/Icons/watch-outline.svg" alt="" class="category-icon" />
      <p>Smartwatch</p>
    </div>
    <div class="category" [routerLink]="['/tienda', 'Smart Home', 'todos']">
      <img src="/assets/Icons/home-outline.svg" alt="" class="category-icon" />
      <p>Smart Home</p>
    </div>
  </div>

  <div class="landing-categories-mobile">
    <div class="splide" id="categories-slider">
      <div class="splide__track">
        <ul class="splide__list">
          <li class="splide__slide category-mobile" [routerLink]="['/tienda', 'Computación', 'todos']">
            <img src="/assets/Icons/laptop-outline.svg" alt="" class="category-icon" />
            <p>Computación</p>
          </li>
          <li class="splide__slide category-mobile" [routerLink]="['/tienda', 'Audífonos', 'todos']">
            <img src="/assets/Icons/earbufs-outline.svg" alt="" class="category-icon" />
            <p>Audífonos</p>
          </li>
          <li class="splide__slide category-mobile" [routerLink]="['/tienda', 'Smartphones', 'todos']">
            <img src="/assets/Icons/phone-outline.svg" alt="" class="category-icon" />
            <p>Smartphoness</p>
          </li>
          <li class="splide__slide category-mobile" [routerLink]="['/tienda', 'Smartwatch', 'todos']">
            <img src="/assets/Icons/watch-outline.svg" alt="" class="category-icon" />
            <p>Smartwatch</p>
          </li>
          <li class="splide__slide category-mobile" [routerLink]="['/tienda', 'Smart Home', 'todos']">
            <img src="/assets/Icons/home-outline.svg" alt="" class="category-icon" />
            <p>Smart Home</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="slider-line line-left"></div>
    <div class="slider-line line-right"></div>
  </div>
  <!--<div class="landing-slider">

    </div>-->
  <!--<div class="landing-sponsors">

    </div>-->
  <div class="new-products">
    <div class="title" *ngIf="sliderTitles" id="first">
      <img src="../../../assets/Logos/first-title.svg" id="first-img" />
      <p class="front-text first-title">{{ sliderTitles[0] }}</p>
    </div>
    <div class="splide" id="splide_new_products">
      <div class="splide__track">
        <ul class="splide__list">
          <ng-container *ngFor="let product of products; index as i">
            <li class="splide__slide product" *ngIf="product.subproducts[0].images"
              [routerLink]="['/producto', product.id]">
              <div class="product-img">
                <img [src]="product.subproducts[0].images[0]" />
              </div>
              <div class="tags">
                <p class="new" *ngIf="product.subproducts[0].isNew"><img src="assets/Icons/Star.svg"
                    style="height: 50%;">LANZAMIENTO</p>
                <p class="discount" *ngIf="product.subproducts[0].discount > 0"><img src="assets/Icons/Discount.svg"
                    style="height: 50%;">
                  <span style="color:#4273DF; font-weight: 700;">{{((product.subproducts[0].price -
                    product.subproducts[0].discount)*100)/product.subproducts[0].price
                    | number: '1.1-1'}}%</span> DESCUENTO
                </p>
                <p class="sold-out" *ngIf="product.totalStock <= 0">
                  <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
                </p>
              </div>
              <p class="product-category">{{ product.category | titlecase }}</p>
              <p class="product-name">{{ product.name }}</p>
              <p class="product-price" *ngIf="product.subproducts[0].discount">
                {{
                product.subproducts[0].discount | currency
                }}
                <span class="product-discounted-price">{{
                  product.subproducts[0].price | currency
                  }}</span>
              </p>
              <p class="product-price" *ngIf="!product.subproducts[0].discount">
                {{ product.subproducts[0].price | currency }}
              </p>
              <div class="product-colors" *ngIf="product.colors">
                <div class="product-color" [style]="'background-color:' + color.code + ';'"
                  *ngFor="let color of product.colors"></div>
              </div>
              <img src="../../../assets/Icons/wishlist-on.png" (click)="
                  removeFromWishlist(product.id); $event.stopPropagation()
                " class="wishlist" *ngIf="
                  currentUser &&
                  currentUser.wishlist &&
                  userWishlist.includes(product.id)
                " />
              <img src="../../../assets/Icons/wishlist-off.png"
                (click)="addToWishlist(product.id); $event.stopPropagation()" class="wishlist" *ngIf="
                  currentUser &&
                  (!currentUser.wishlist || !userWishlist.includes(product.id))
                " />
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div class="splide" id="splide_new_products_2">
      <div class="splide__track">
        <ul class="splide__list">
          <ng-container *ngFor="let product of smartphones">
            <li class="splide__slide product" *ngIf="product.subproducts[0].images"
              [routerLink]="['/producto', product.id]">
              <div class="product-img">
                <img [src]="product.subproducts[0].images[0]" />
              </div>
              <div class="tags">
                <p class="new" *ngIf="product.subproducts[0].isNew"><img src="assets/Icons/Star.svg"
                    style="height: 50%;">LANZAMIENTO</p>
                <p class="discount" *ngIf="product.subproducts[0].discount > 0"><img src="assets/Icons/Discount.svg"
                    style="height: 50%;">
                  <span style="color:#4273DF; font-weight: 700;">{{((product.subproducts[0].price -
                    product.subproducts[0].discount)*100)/product.subproducts[0].price
                    | number: '1.1-1'}}%</span> DESCUENTO
                </p>
                <p class="sold-out" *ngIf="product.totalStock <= 0">
                  <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
                </p>
              </div>
              <p class="product-category">{{ product.category | titlecase }}</p>
              <p class="product-name">{{ product.name }}</p>
              <p class="product-price" *ngIf="product.subproducts[0].discount">
                {{
                product.subproducts[0].discount | currency
                }}
                <span class="product-discounted-price">{{
                  product.subproducts[0].price | currency
                  }}</span>
              </p>
              <p class="product-price" *ngIf="!product.subproducts[0].discount">
                {{ product.subproducts[0].price | currency }}
              </p>
              <div class="product-colors" *ngIf="product.colors">
                <div class="product-color" [style]="'background-color:' + color.code + ';'"
                  *ngFor="let color of product.colors"></div>
              </div>
              <img src="../../../assets/Icons/wishlist-on.png" (click)="
                  removeFromWishlist(product.id); $event.stopPropagation()
                " class="wishlist" *ngIf="
                  currentUser &&
                  currentUser.wishlist &&
                  userWishlist.includes(product.id)
                " />
              <img src="../../../assets/Icons/wishlist-off.png"
                (click)="addToWishlist(product.id); $event.stopPropagation()" class="wishlist" *ngIf="
                  currentUser &&
                  (!currentUser.wishlist || !userWishlist.includes(product.id))
                " />
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div class="blue-section">
    <div class="marketplaces-container" id="marketplaces">
      <div class="perks">
        <div class="perk">
          <img src="../../../assets/Icons/perk-1.png" />
          <p>
            Múltiples formas<br />
            de pago
          </p>
          <div class="tooltip">
            <p>
              Trabajamos con las plataformas de pago más seguras en el mundo.<br />
              Para resguardar los datos de tus tarjetas de crédito o débito
              integramos a nuestro eCommerce STRIPE, la plataforma de pagos con
              tarjetas más segura en el mundo.<br />
              Paypal es bienvenido. Si tienes cuenta de PAYPAL no dudes en
              realizar tu pago, contamos con la integración.<br />
              Si deseas hacer tu pago por medio de Mercado Pago, adelante,
              puedes aprovechar de los beneficios que te ofrece la
              plataforma.<br /><br />
              Despreocúpate, te tenemos cubierto.
            </p>
            <div class="tooltip-logos">
              <div class="logo-container">
                <img src="../../../assets/Logos/paypal-alt.svg" class="secondary-logo" />
              </div>
              <div class="logo-container">
                <img src="../../../assets/Logos/mercadopago.png" class="secondary-logo" />
              </div>
              <div class="logo-container">
                <img src="../../../assets/Logos/credit-cards.svg" class="cards" />
              </div>
            </div>
          </div>
        </div>
        <div class="perk">
          <img src="../../../assets/Icons/perk-2.png" />
          <p>
            Garantía de 1 año<br />
            con el proveedor
          </p>
          <div class="tooltip">
            <p>
              Garantía de 1 año con el proveedor: Si tu celular cuenta con un
              defecto de fábrica, no te preocupes, contamos con 1 año de
              garantía directamente con el proveedor. El proceso es muy
              sencillo:<br />
              <span class="shiny-text">Paso 1:</span> Envía un whatsapp al
              número 56-2327-2210 o un correo a
              <span class="shiny-text">hola@gmeshop.com.mx</span> explicando la
              falla de tu producto.<br />
              <span class="shiny-text">Paso 2:</span> Envía tu producto a
              nuestras oficinas. Te generaremos una guía de reembarque para que
              lo lleves a tu sucursal de paquetería más cercana.<br />
              <span class="shiny-text">Paso 3:</span> Recibido el producto en
              nuestras instalaciones enviaremos el producto a la marca para que
              lo revise y lo arregle.<br />
              <span class="shiny-text">Paso 4:</span> En caso de que el defecto
              de fábrica no haya podido ser arreglado te enviaremos uno
              completamente nuevo.<br />
            </p>
          </div>
        </div>
        <div class="perk">
          <img src="../../../assets/Icons/perk-3.png" />
          <p>
            Envíos gratis en<br />
            toda la República
          </p>
          <div class="tooltip center-tooltip">
            <p>
              Envíos gratis a toda la República Mexicana: No pagues envío,
              nosotros lo hacemos por ti.<br />
              Aprovecha esta promoción y compra tu smartphone sin costo de envío
              por inauguración de nuestro eCommerce. Envíamos por la paquetería
              que tu elijas al momento de completar tu compra. Usa los códigos
              de descuento por costo de envío que tenemos para ti en nuestro
              instagram.
            </p>
            <div class="shipping-logos">
              <!-- <img src="../../../assets/Logos/Paqueterias/Estafeta.png" />
              <img src="../../../assets/Logos/Paqueterias/Redpack.png" />
              <img src="../../../assets/Logos/Paqueterias/Fedex.png" /> -->
              <img src="../../../assets/Logos/Paqueterias/DHL.png" />
              <!-- <img src="../../../assets/Logos/Paqueterias/99minutos.png" /> -->
            </div>
          </div>
        </div>
        <!-- <div class="perk">
                    <img src="../../../assets/Icons/perk-4.png">
                    <p>Productos 100% originales</p>
                    <div class="tooltip right-tooltip">
                        <p>Productos 100% originales: Te garantizamos la originalidad de los productos
                            que nos compres. La familia GM te respalda.</p>
                    </div>
                </div> -->
        <div class="perk">
          <img src="../../../assets/Icons/certif.png" />
          <p>Productos con sello GM Certified*</p>
          <div class="tooltip right-tooltip">
            <p>
              Productos con sello GM Certified: Somos muy cuidadosos con los
              productos que enviamos, es por eso que les ponemos un sello de
              certificación, esto quiere decir que el producto que te enviamos
              cumple las condiciones de nuevo para que no tengas que preocuparte
              de nada. (sellado, sin uso, y completo).
            </p>
          </div>
        </div>
      </div>
      <p class="marketplaces-title">
        Compra a través <br /><span class="yellow-text">de los Marketplaces</span>
      </p>
      <div class="marketplaces">
        <a href="https://www.linio.com.mx/s/guerrero-mobile-voz-y-datos-sa-de-cv" target="_blank">
          <div class="marketplace">
            <img src="../../../assets/Logos/lineo.svg" alt="" style="transform: scale(2)" />
          </div>
        </a>
        <a href="https://www.amazon.com.mx/s?me=A3OM4HANF0P6SL&marketplaceID=A1AM78C64UM0Y8" target="_blank">
          <div class="marketplace">
            <img src="../../../assets/Logos/amazon.svg" alt="" style="transform: scale(2)" />
          </div>
        </a>
        <a href="https://www.walmart.com.mx/celulares/smartphones/celulares-desbloqueados/xiaomi-redmi-9-desbloqueado-ram-dual-sim-32-gb-3-gb-ram-6-53-pulgadas-carbon-grey-xiaomi-redmi-9-carbon-grey_00694105964581"
          target="_blank">
          <div class="marketplace">
            <img src="../../../assets/Logos/walmart.svg" alt="" style="transform: scale(3.5)" />
          </div>
        </a>
        <a href="https://tienda.mercadolibre.com.mx/gm-eshop" target="_blank">
          <div class="marketplace">
            <img src="../../../assets/Logos/mercado.png" alt="" class="logo-large" />
          </div>
        </a>
        <a href="https://www.claroshop.com/tienda/124751/GM-ESHOP/pagina=1" target="_blank">
          <div class="marketplace">
            <img src="../../../assets/Logos/claro.svg" alt="" class="logo-large" style="transform: scale(1.5)" />
          </div>
        </a>
        <a href="https://www.liverpool.com.mx/tienda/sp/GM%20e-shop/3075" target="_blank">
          <div class="marketplace">
            <img src="../../../assets/Logos/liverpool-s.svg" alt="" style="width: 90%; height: 90%" />
          </div>
        </a>
        <a href="https://www.sears.com.mx/producto/405638/samsung-galaxy-a11-negro-64gb-desbloqueado--earbuds-2/"
          target="_blank">
          <div class="marketplace">
            <img src="../../../assets/Logos/sears.svg" alt="" class="logo-xlarge" />
          </div>
        </a>
      </div>
    </div>
    <img src="../../../assets/Icons/band-landing.svg" alt="" class="band-landing" />
    <div class="popular-sales">
      <div class="title" id="second">
        <img src="../../../assets/Logos/title-second.svg" id="second-img" />
        <p class="front-text">
          Los que se hicieron <span class="yellow-text">virales</span>
        </p>
      </div>
      <img src="../../../assets/Icons/popular-first.svg" alt="" id="popular-first" />
      <img src="../../../assets/Icons/popular-second.svg" alt="" id="popular-second" />
      <img src="../../../assets/Icons/popular-third.svg" alt="" id="popular-third" />
      <div class="popular-slider" *ngIf="
          smartphones && computers && smarthomes && smartwatches && headphones
        ">
        <div class="main-product" [routerLink]="['/producto', smartphones[0].id]">
          <div class="tags">
            <p class="new" *ngIf="smartphones[0].subproducts[0].isNew"><img src="assets/Icons/Star.svg"
                style="height: 50%;">LANZAMIENTO</p>
            <p class="discount" *ngIf="smartphones[0].subproducts[0].discount > 0"><img src="assets/Icons/Discount.svg"
                style="height: 50%;">
              <span style="color:#4273DF; font-weight: 700;">{{((smartphones[0].subproducts[0].price -
                smartphones[0].subproducts[0].discount)*100)/smartphones[0].subproducts[0].price | number:
                '1.1-1'}}%</span>DESCUENTO
            </p>
            <p class="sold-out" *ngIf="smartphones[0].totalStock <= 0">
              <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
            </p>
          </div>
          <img [src]="smartphones[0].subproducts[0].images[0]" />
          <p class="product-name">{{ smartphones[0].name }}</p>
          <p class="product-price" *ngIf="smartphones[0].subproducts[0].discount">
            {{
            smartphones[0].subproducts[0].discount| currency
            }}
            <span class="product-discounted-price">{{
              smartphones[0].subproducts[0].price | currency
              }}</span>
          </p>
          <p class="product-price" *ngIf="!smartphones[0].subproducts[0].discount">
            {{ smartphones[0].subproducts[0].price | currency }}
          </p>
          <img src="../../../assets/Icons/wishlist-on.png" (click)="
              removeFromWishlist(smartphones[0].id); $event.stopPropagation()
            " class="wishlist" *ngIf="
              currentUser &&
              currentUser.wishlist &&
              userWishlist.includes(smartphones[0].id)
            " />
          <img src="../../../assets/Icons/wishlist-off.png"
            (click)="addToWishlist(smartphones[0].id); $event.stopPropagation()" class="wishlist" *ngIf="
              currentUser &&
              (!currentUser.wishlist ||
                !userWishlist.includes(smartphones[0].id))
            " />
        </div>
        <div class="secondary-products">
          <div class="secondary-product" [routerLink]="['/producto', smartphones[1].id]">
            <div class="tags">
              <p class="new" *ngIf="smartphones[1].subproducts[0].isNew">
                <img src="assets/Icons/Star.svg" style="height: 50%;">LANZAMIENTO
              </p>
              <p class="discount" *ngIf="smartphones[1].subproducts[0].discount > 0"><img
                  src="assets/Icons/Discount.svg" style="height: 50%;">
                <span style="color:#4273DF; font-weight: 700;">{{((smartphones[1].subproducts[0].price -
                  smartphones[1].subproducts[0].discount)*100)/smartphones[1].subproducts[0].price | number:
                  '1.1-1'}}%</span>
                DESCUENTO
              </p>
              <p class="sold-out" *ngIf="smartphones[1].totalStock <= 0">
                <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
              </p>
            </div>
            <img [src]="smartphones[1].subproducts[0].images[0]" />
            <p class="product-name">{{ smartphones[1].name }}</p>
            <p class="product-price" *ngIf="smartphones[1].subproducts[0].discount">
              {{
              smartphones[1].subproducts[0].discount | currency
              }}
              <span class="product-discounted-price">{{
                smartphones[1].subproducts[0].price | currency
                }}</span>
            </p>
            <p class="product-price" *ngIf="!smartphones[1].subproducts[0].discount">
              {{ smartphones[1].subproducts[0].price | currency }}
            </p>
            <img src="../../../assets/Icons/wishlist-on.png" (click)="
                removeFromWishlist(smartphones[1].id); $event.stopPropagation()
              " class="wishlist" *ngIf="
                currentUser &&
                currentUser.wishlist &&
                userWishlist.includes(smartphones[1].id)
              " />
            <img src="../../../assets/Icons/wishlist-off.png" (click)="
                addToWishlist(smartphones[1].id); $event.stopPropagation()
              " class="wishlist" *ngIf="
                currentUser &&
                (!currentUser.wishlist ||
                  !userWishlist.includes(smartphones[1].id))
              " />
          </div>
          <div class="secondary-product" [routerLink]="['/producto', smartphones[2].id]">
            <div class="tags">
              <p class="new" *ngIf="smartphones[2].subproducts[0].isNew">
                <img src="assets/Icons/Star.svg" style="height: 50%;">LANZAMIENTO
              </p>
              <p class="discount" *ngIf="smartphones[2].subproducts[0].discount > 0"><img
                  src="assets/Icons/Discount.svg" style="height: 50%;">
                <span style="color:#4273DF; font-weight: 700;">{{((smartphones[2].subproducts[0].price -
                  smartphones[2].subproducts[0].discount)*100)/smartphones[2].subproducts[0].price | number:
                  '1.1-1'}}%</span>
                DESCUENTO
              </p>
              <p class="sold-out" *ngIf="smartphones[2].totalStock <= 0">
                <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
              </p>
            </div>
            <img [src]="smartphones[2].subproducts[0].images[0]" />
            <p class="product-name">{{ smartphones[2].name }}</p>
            <p class="product-price" *ngIf="smartphones[2].subproducts[0].discount">
              {{
              smartphones[2].subproducts[0].discount| currency
              }}
              <span class="product-discounted-price">{{
                smartphones[2].subproducts[0].price | currency
                }}</span>
            </p>
            <p class="product-price" *ngIf="!smartphones[2].subproducts[0].discount">
              {{ smartphones[2].subproducts[0].price | currency }}
            </p>
            <img src="../../../assets/Icons/wishlist-on.png" (click)="
                removeFromWishlist(smartphones[2].id); $event.stopPropagation()
              " class="wishlist" *ngIf="
                currentUser &&
                currentUser.wishlist &&
                userWishlist.includes(smartphones[2].id)
              " />
            <img src="../../../assets/Icons/wishlist-off.png" (click)="
                addToWishlist(smartphones[2].id); $event.stopPropagation()
              " class="wishlist" *ngIf="
                currentUser &&
                (!currentUser.wishlist ||
                  !userWishlist.includes(smartphones[2].id))
              " />
          </div>
          <div class="secondary-product" [routerLink]="['/producto', smartwatches[0].id]">
            <div class="tags">
              <p class="new" *ngIf="smartwatches[0].subproducts[0].isNew">
                <img src="assets/Icons/Star.svg" style="height: 50%;">LANZAMIENTO
              </p>
              <p class="discount" *ngIf="smartwatches[0].subproducts[0].discount > 0"><img
                  src="assets/Icons/Discount.svg" style="height: 50%;">
                <span style="color:#4273DF; font-weight: 700;">{{((smartwatches[0].subproducts[0].price -
                  smartwatches[0].subproducts[0].discount)*100)/smartwatches[0].subproducts[0].price | number:
                  '1.1-1'}}%</span>
                DESCUENTO
              </p>
              <p class="sold-out" *ngIf="smartwatches[0].totalStock <= 0">
                <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
              </p>
            </div>
            <img [src]="smartwatches[0].subproducts[0].images[0]" />
            <p class="product-name">{{ smartwatches[0].name }}</p>
            <p class="product-price" *ngIf="smartwatches[0].subproducts[0].discount">
              {{
              smartwatches[0].subproducts[0].discount| currency
              }}
              <span class="product-discounted-price">{{
                smartwatches[0].subproducts[0].price | currency
                }}</span>
            </p>
            <p class="product-price" *ngIf="!smartwatches[0].subproducts[0].discount">
              {{ smartwatches[0].subproducts[0].price | currency }}
            </p>
            <img src="../../../assets/Icons/wishlist-on.png" (click)="
                removeFromWishlist(smartwatches[0].id); $event.stopPropagation()
              " class="wishlist" *ngIf="
                currentUser &&
                currentUser.wishlist &&
                userWishlist.includes(smartwatches[0].id)
              " />
            <img src="../../../assets/Icons/wishlist-off.png" (click)="
                addToWishlist(smartwatches[0].id); $event.stopPropagation()
              " class="wishlist" *ngIf="
                currentUser &&
                (!currentUser.wishlist ||
                  !userWishlist.includes(smartwatches[0].id))
              " />
          </div>
          <div class="secondary-product" [routerLink]="['/producto', headphones[0].id]">
            <div class="tags">
              <p class="new" *ngIf="headphones[0].subproducts[0].isNew">
                <img src="assets/Icons/Star.svg" style="height: 50%;">LANZAMIENTO
              </p>
              <p class="discount" *ngIf="headphones[0].subproducts[0].discount > 0"><img src="assets/Icons/Discount.svg"
                  style="height: 50%;">
                <span style="color:#4273DF; font-weight: 700;">{{((headphones[0].subproducts[0].price -
                  headphones[0].subproducts[0].discount)*100)/headphones[0].subproducts[0].price | number:
                  '1.1-1'}}%</span>
                DESCUENTO
              </p>
              <p class="sold-out" *ngIf="headphones[0].totalStock <= 0">
                <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
              </p>
            </div>
            <img [src]="headphones[0].subproducts[0].images[0]" />
            <p class="product-name">{{ headphones[0].name }}</p>
            <p class="product-price" *ngIf="headphones[0].subproducts[0].discount">
              {{
              headphones[0].subproducts[0].discount| currency
              }}
              <span class="product-discounted-price">{{
                headphones[0].subproducts[0].price | currency
                }}</span>
            </p>
            <p class="product-price" *ngIf="!headphones[0].subproducts[0].discount">
              {{ headphones[0].subproducts[0].price | currency }}
            </p>
            <img src="../../../assets/Icons/wishlist-on.png" (click)="
                removeFromWishlist(headphones[0].id); $event.stopPropagation()
              " class="wishlist" *ngIf="
                currentUser &&
                currentUser.wishlist &&
                userWishlist.includes(headphones[0].id)
              " />
            <img src="../../../assets/Icons/wishlist-off.png" (click)="
                addToWishlist(headphones[0].id); $event.stopPropagation()
              " class="wishlist" *ngIf="
                currentUser &&
                (!currentUser.wishlist ||
                  !userWishlist.includes(headphones[0].id))
              " />
          </div>
        </div>
      </div>
    </div>

    <div class="perks-mobile">
      <div class="perk-mobile">
        <div class="outer-circle">
          <div class="inner-circle">
            <img src="../../../assets/Icons/perk-finger.png" alt="" class="perk-img" />
          </div>
          <div class="mobile-tooltip">
            <p>Tu compra segura y protegida.</p>
          </div>
        </div>
      </div>
      <div class="perk-mobile">
        <div class="outer-circle">
          <div class="inner-circle">
            <img src="../../../assets/Icons/perk-2.png" alt="" class="perk-img" style="height: 45%" />
          </div>
          <div class="mobile-tooltip mobile-tooltip-right">
            <p>Garantía de 12 meses</p>
          </div>
        </div>
      </div>
      <div class="perk-mobile">
        <div class="outer-circle">
          <div class="inner-circle">
            <img src="../../../assets/Icons/certif.png" alt="" class="perk-img" />
          </div>
          <div class="mobile-tooltip">
            <p>Peroductos con sello *GM Certified</p>
          </div>
        </div>
      </div>
      <div class="perk-mobile">
        <div class="outer-circle">
          <div class="inner-circle">
            <img src="../../../assets/Icons/perk-3.png" alt="" class="perk-img" style="height: 40%" />
          </div>
          <div class="mobile-tooltip mobile-tooltip-right">
            <p>Envíos gratis en todo México</p>
          </div>
        </div>
      </div>
      <div class="perk-mobile">
        <div class="outer-circle">
          <div class="inner-circle">
            <img src="../../../assets/Icons/perk-1.png" alt="" class="perk-img" />
          </div>
          <div class="mobile-tooltip">
            <p>Múltiples formas de pago</p>
          </div>
        </div>
      </div>
      <div class="perk-mobile">
        <div class="outer-circle">
          <div class="inner-circle">
            <img src="../../../assets/Icons/perk-smile.png" alt="" class="perk-img" />
          </div>
          <div class="mobile-tooltip mobile-tooltip-right">
            <p>El mejor servicio y atención.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="splide" id="splide_sponsors">
      <div class="splide__track">
        <ul class="splide__list">
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/motorola.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/oppo.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/samsung.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/nokia.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/hisense.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/huawei.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/realme.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/xiaomi.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/alcatel_logo.svg" alt="" class="sponsor-logo" />
          </li>
          <li class="splide__slide sponsor">
            <img src="../../../assets/Logos/Sponsors/zte.svg" alt="" class="sponsor-logo" />
          </li>
        </ul>
      </div>
    </div>
    <div class="community">
      <img src="../../../assets/Icons/instagram-second.svg" alt="" id="insta-second" />
      <img src="../../../assets/Icons/instagram-third.svg" alt="" id="insta-third" />
      <div class="title insta-title">
        <img src="../../../assets/Icons/instagram-first.svg" id="insta-first" />
        <p class="front-text">
          La comunidad <span class="yellow-text">GM</span> <br />en instagram
        </p>
        <!-- <p class="bg-text">{{sliderTitles[2]}}</p> -->
      </div>

      <iframe style="border: 0; width: 95%; height: 305px" class="embedSocial" scrolling="no"
        src="https://embedsocial.com/facebook_album/pro_instagram/4c169bdddab763ebe8e07f87466af742be74d87f"></iframe>
      <!-- <iframe style='border: 0; width: 90%; height: 700px;' scrolling=yes src='https://embedsocial.com/facebook_album/pro_hashtag/2c08a59fe6037d26ce6afe9a6aee1e48e2895f15'></iframe> -->
    </div>
    <div class="accolades-section">
      <p class="accolades-title">
        ¡Entregamos más de
        <span class="yellow-text">10,000 smartphones y accesorios</span> al mes!
      </p>
      <div class="accolades">
        <div class="accolade first-accolade">
          <p><span class="bold">Top Sellers</span><br />en Marketplaces</p>
        </div>
        <div class="accolade second-accolade">
          <p><span class="bold">Atención 100%</span><br />personalizada</p>
        </div>
        <div class="accolade third-accolade">
          <p><span class="bold">99% de eficiencia</span><br />en envíos</p>
        </div>
      </div>
    </div>
    <div class="process-section">
      <div class="process-title">
        <p>¿Qué proceso seguimos para hacerte llegar tu producto?</p>
      </div>
      <div class="processes">
        <div class="process">
          <div class="number">1</div>
          <div class="process-text">Recibimos tu pedido</div>
        </div>
        <div class="process">
          <div class="number">2</div>
          <div class="process-text">Se aprueba el pago</div>
        </div>
        <div class="process">
          <div class="number">3</div>
          <div class="process-text">Empacamos tu pedido</div>
        </div>
        <div class="process">
          <div class="number">4</div>
          <div class="process-text">Solicitamos recolección</div>
        </div>
        <div class="process">
          <div class="number">5</div>
          <div class="process-text">Monitoreamos tu pedido</div>
        </div>
        <div class="process">
          <div class="number">6</div>
          <div class="process-text">Te llega tu pedido ¡A disfrutar!</div>
        </div>
      </div>
    </div>
    <div class="refunds-section">
      <div class="refunds-text">
        <p class="refunds-title">
          ¿No recibiste lo que pediste<br /><span class="yellow-text">o presenta alguna falla?</span>
        </p>
        <img src="../../../assets/Icons/separator-refunds.svg" />
        <p class="refunds-content">
          ¡No te preocupes! El equipo GM te tiene cubierto. Sabemos que un
          smartphone es una inversión importante para tu bolsillo, es por eso
          que puedes confiar en nosotros.
        </p>
      </div>
      <div class="refunds-contacts" id="contacto">
        <a href="mailto:hola@gmeshop.com.mx" target="blank" class="refund-link">
          <div class="contact">
            <img src="../../../assets/Icons/mail.svg" />
            <div class="tooltip-contact">hola@gmeshop.com.mx</div>
          </div>
        </a>
        <a class="refund-link">
          <div class="contact">
            <img src="../../../assets/Icons/phone.svg" alt="" />
            <div class="tooltip-contact">56-2327-2210</div>
          </div>
        </a>
        <a href="https://www.wa.link/9jy4xz" target="blank" class="refund-link">
          <div class="contact">
            <img src="../../../assets/Icons/whatsapp-icon.svg" alt="" />
            <div class="tooltip-contact">56-2327-2210</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<footer></footer>

<a href="https://www.wa.link/9slb7b" target="blank">
  <div class="whatsapp">
    <img src="../../../assets/Logos/whatsapp-logo.svg" alt="whatsapp" />
  </div>
</a>

<div class="social-media-links">
  <a href="https://www.instagram.com/gmeshop.mx/" target="_blank">
    <div class="social-media">
      <div class="instagram"></div>
    </div>
  </a>
  <a href="" target="_blank">
    <div class="social-media">
      <div class="youtube"></div>
    </div>
  </a>
  <a href="https://www.facebook.com/gmeshop.mx" target="_blank">
    <div class="social-media">
      <div class="facebook"></div>
    </div>
  </a>
</div>

<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading">Cargando Datos...</p>
</ngx-spinner>

<script>
  (function (d, s, id) {
    var js;
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://embedsocial.com/embedscript/in.js";
    d.getElementsByTagName("head")[0].appendChild(js);
  })(document, "script", "EmbedSocialInstagramScript");
</script>

<!--
    <div class="splide" id="">
        <div class="splide__track">
            <ul class="splide__list">
                <li class="splide__slide">
                    
                </li>
                <li class="splide__slide">
                    
                </li>
                <li class="splide__slide">
                    
                </li>
            </ul>
        </div>
    </div>
-->