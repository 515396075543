import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-profiles',
  templateUrl: './admin-profiles.component.html',
  styleUrls: ['./admin-profiles.component.scss']
})
export class AdminProfilesComponent implements OnInit {
  users
  usersFiltered
  orders

  constructor(public userService: UserService, public orderService: OrderService) { }

  async ngOnInit() {
    this.userService.getUsers().pipe(first()).subscribe(res => {
      this.users = res
      this.orderService.getOrders().subscribe(res => {
        this.orders = res
        this.users.forEach(user => {
          user.orders = 0
          this.orders.forEach(order => {
            if (order.uid == user.id) {
              user.orders++
            }
          })
          this.usersFiltered = this.users
        })
      })
      /* for (let user of this.users) {
        if (!user.openpay_id) {
          this.userService.createUserTest({ name: user.name, email: user.email }).then(async (res) => {
            user.openpay_id = res.id
            console.log(user);
            await this.userService.updateUser(user.id, user)
          })
        }
      } */
    });
  }

  search(value) {
    this.usersFiltered = this.users.filter(function (user) {
      var name: string = user.name;
      return (name.toLowerCase().includes(value.toLowerCase()) || user.email.toLowerCase().includes(value.toLowerCase()))
    })
  }

}
