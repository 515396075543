<div class="wrapper">
    <div class="sidebar">
        <!-- <img src="../../../assets/Logos/Gm-navbar-logo.png" alt="" class="sidebar-logo"> -->
        <p class="admin-tab" routerLink="/admin-productos" routerLinkActive="selected-tab">Productos</p>
        <p class="admin-tab" routerLink="/admin-caracteristicas" routerLinkActive="selected-tab">Características</p>
        <p class="admin-tab" routerLink="/admin-pedidos" routerLinkActive="selected-tab">Pedidos</p>
        <p class="admin-tab" routerLink="/admin-perfiles" routerLinkActive="selected-tab">Perfiles</p>
        <p class="admin-tab" routerLink="/admin-cupones" routerLinkActive="selected-tab">Cupones</p>
        <p class="admin-tab" routerLink="/admin-slider" routerLinkActive="selected-tab">Slider</p>
        <p class="admin-tab" routerLink="/admin-campañas" routerLinkActive="selected-tab">Campañas</p>
    </div>
    <div class="content">
        <ng-container *ngIf="!showCreateProduct && !showEditProduct && !showCreateSubproduct && !showEditSubproduct">
            <div class="header">
                <p>Productos</p>
                <div class="filters">
                    <button class="primary-btn" (click)="displayCreateProduct()">Nuevo Producto</button>
                    <div class="search-bar">
                        <input type="text" name="search" id="search" (keyup)="search($event.target.value)">
                        <i class="fa fa-search"></i>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <div class="product" *ngFor="let product of productsFiltered;">
                    <img *ngIf="product.subproducts" [src]="product.subproducts[0].images[0]" alt="">
                    <p>{{product.name}}</p>
                    <p>{{product.id}}</p>
                    <p style="text-transform: capitalize;">{{product.category}}</p>
                    <p class="edit-product-link" (click)="displayEditProduct(product)">Editar</p>
                    <app-toggle text="Visible?" isOn={{product.isVisible}} (click)="changeProductVisibility(product)">
                    </app-toggle>
                </div>
            </div>
        </ng-container>

        <div class="create-product" *ngIf="showCreateProduct">
            <div>
                <div class="header">
                    <p class="front-text">CREAR PRODUCTO</p>
                    <button class="secondary-btn cancel-btn" (click)="hideCreateProduct()">Cancelar</button>
                </div>
                <div style="display:flex;">
                    <div class="create-inputs" [formGroup]="newProductInfo">
                        <label for="name">Nombre del producto</label>
                        <input type="text" id="name" name="name" formControlName="name">
                        <label for="sku">sku</label>
                        <input type="text" id="sku" name="sku" placeholder="Se asigna automáticamente" disabled>
                        <label>Categoría</label>
                        <select formControlName="category">
                            <option value="" selected disabled>Seleccione una categoría</option>
                            <option [value]=category.name *ngFor="let category of categories">{{category.name}}</option>
                        </select>
                        <label for="desc">Descripción</label>
                        <input type="text" id="desc" name="desc" formControlName="description">
                        <div class="title">
                            <p class="small-front-text">Especificaciones</p>
                        </div>
                        <div formGroupName="specs" class="specs">
                            <div formGroupName="spec1" class="spec">
                                <label for="spec1Name">Nombre de Especificación 1</label>
                                <input type="text" id="spec1Name" name="spec1Name" formControlName="name">
                                <label for="spec1Desc">Descripción de Especificación 1</label>
                                <input type="text" id="spec1Desc" name="spec1Desc" formControlName="desc">
                            </div>
                            <div formGroupName="spec2" class="spec">
                                <label for="spec2Name">Nombre de Especificación 2</label>
                                <input type="text" id="spec2Name" name="spec2Name" formControlName="name">
                                <label for="spec2Desc">Descripción de Especificación 2</label>
                                <input type="text" id="spec2Desc" name="spec2Desc" formControlName="desc">
                            </div>
                            <div formGroupName="spec3" class="spec">
                                <label for="spec3Name">Nombre de Especificación 3</label>
                                <input type="text" id="spec3Name" name="spec3Name" formControlName="name">
                                <label for="spec3Desc">Descripción de Especificación 3</label>
                                <input type="text" id="spec3Desc" name="spec3Desc" formControlName="desc">
                            </div>
                            <div formGroupName="spec4" class="spec">
                                <label for="spec4Name">Nombre de Especificación 4</label>
                                <input type="text" id="spec4Name" name="spec4Name" formControlName="name">
                                <label for="spec4Desc">Descripción de Especificación 4</label>
                                <input type="text" id="spec4Desc" name="spec4Desc" formControlName="desc">
                            </div>
                            <div formGroupName="spec5" class="spec">
                                <label for="spec5Name">Nombre de Especificación 5</label>
                                <input type="text" id="spec5Name" name="spec5Name" formControlName="name">
                                <label for="spec5Desc">Descripción de Especificación 5</label>
                                <input type="text" id="spec5Desc" name="spec5Desc" formControlName="desc">
                            </div>
                            <div formGroupName="spec6" class="spec">
                                <label for="spec6Name">Nombre de Especificación 6</label>
                                <input type="text" id="spec6Name" name="spec6Name" formControlName="name">
                                <label for="spec6Desc">Descripción de Especificación 6</label>
                                <input type="text" id="spec6Desc" name="spec6Desc" formControlName="desc">
                            </div>
                        </div>
                        <button class="primary-btn" (click)="createProduct()">Crear</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="edit-product-container" *ngIf="showEditProduct">
            <div class="header">
                <p class="front-text">EDITAR PRODUCTO</p>
                <div class="buttons">
                    <button class="primary-btn" (click)="updateProduct()">Guardar</button>
                    <button class="secondary-btn danger-btn" (click)="removeProduct()">Eliminar</button>
                    <button class="secondary-btn cancel-btn" (click)="hideEditProduct()">Cancelar</button>
                </div>
            </div>
            <div style="display:flex;">
                <div class="create-inputs" [formGroup]="editProductInfo">
                    <label for="name">Nombre del producto</label>
                    <input type="text" id="name" name="name" formControlName="name">
                    <label for="sku">sku</label>
                    <input type="text" id="sku" name="sku" placeholder="Se asigna automáticamente"
                        [value]="productToEdit.id" disabled>
                    <label>Categoría</label>
                    <select formControlName="category">
                        <option value="" selected disabled>Seleccione una categoría</option>
                        <option [value]=category.name *ngFor="let category of categories">{{category.name}}</option>
                    </select>
                    <label for="desc">Descripción</label>
                    <input type="text" id="desc" name="desc" formControlName="description">
                    <div class="title">
                        <p class="small-front-text">Especificaciones</p>
                    </div>
                    <div formGroupName="specs" class="specs">
                        <div formGroupName="spec1" class="spec">
                            <label for="spec1Name">Nombre de Especificación 1</label>
                            <input type="text" id="spec1Name" name="spec1Name" formControlName="name">
                            <label for="spec1Desc">Descripción de Especificación 1</label>
                            <input type="text" id="spec1Desc" name="spec1Desc" formControlName="desc">
                        </div>
                        <div formGroupName="spec2" class="spec">
                            <label for="spec2Name">Nombre de Especificación 2</label>
                            <input type="text" id="spec2Name" name="spec2Name" formControlName="name">
                            <label for="spec2Desc">Descripción de Especificación 2</label>
                            <input type="text" id="spec2Desc" name="spec2Desc" formControlName="desc">
                        </div>
                        <div formGroupName="spec3" class="spec">
                            <label for="spec3Name">Nombre de Especificación 3</label>
                            <input type="text" id="spec3Name" name="spec3Name" formControlName="name">
                            <label for="spec3Desc">Descripción de Especificación 3</label>
                            <input type="text" id="spec3Desc" name="spec3Desc" formControlName="desc">
                        </div>
                        <div formGroupName="spec4" class="spec">
                            <label for="spec4Name">Nombre de Especificación 4</label>
                            <input type="text" id="spec4Name" name="spec4Name" formControlName="name">
                            <label for="spec4Desc">Descripción de Especificación 4</label>
                            <input type="text" id="spec4Desc" name="spec4Desc" formControlName="desc">
                        </div>
                        <div formGroupName="spec5" class="spec">
                            <label for="spec5Name">Nombre de Especificación 5</label>
                            <input type="text" id="spec5Name" name="spec5Name" formControlName="name">
                            <label for="spec5Desc">Descripción de Especificación 5</label>
                            <input type="text" id="spec5Desc" name="spec5Desc" formControlName="desc">
                        </div>
                        <div formGroupName="spec6" class="spec">
                            <label for="spec6Name">Nombre de Especificación 6</label>
                            <input type="text" id="spec6Name" name="spec6Name" formControlName="name">
                            <label for="spec6Desc">Descripción de Especificación 6</label>
                            <input type="text" id="spec6Desc" name="spec6Desc" formControlName="desc">
                        </div>
                    </div>
                </div>
            </div>
            <div class="subproducts">
                <div class="header">
                    <p class="front-text">SUBPRODUCTOS</p>
                    <button class="primary-btn" style="width: 200px;" (click)="displayCreateSubproduct()">Agregar
                        Subproducto</button>
                </div>
                <div class="subproduct-list">
                    <div class="subproduct" *ngFor="let subproduct of productToEdit.subproducts;index as i">
                        <img [src]="subproduct.images[0]">
                        <p>{{subproduct.name}}</p>
                        <div class="subproduct-list-chars">
                            <ng-container *ngFor="let item of subproduct.chars|keyvalue">
                                <p *ngIf="item.key!='Color'">{{item.key}}: {{item.value}}</p>
                                <p *ngIf="item.key=='Color'"><span
                                        [style]='"background-color:"+item.value.code'></span>{{item.key}}:
                                    {{item.value.name}}</p>
                            </ng-container>
                        </div>
                        <p>{{subproduct.price | currency}}</p>
                        <div class="sub-icons">
                            <i class="fa fa-edit" (click)="displayEditSubproduct(i)"></i>
                            <i class="fa fa-times" (click)="removeSubproduct(i)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="create-subproduct" *ngIf="showCreateSubproduct">
            <div class="header">
                <p class="front-text">CREAR SUBPRODUCTO</p>
                <div class="buttons">
                    <button class="primary-btn" (click)="createSubproduct()">Agregar</button>
                    <button class="secondary-btn cancel-btn" (click)="hideCreateSubproduct()">Cancelar</button>
                </div>
            </div>
            <div style="display: flex;">
                <div class="create-inputs" [formGroup]="newSubproductInfo">
                    <label for="name">Nombre del producto</label>
                    <input type="text" id="name" name="name" formControlName="name">
                    <label for="price">Precio Normal</label>
                    <input type="number" id="price" name="price" formControlName="price" placeholder="Precio">
                    <label for="discount">Precio Con Descuento (0 si no tiene descuento)</label>
                    <input type="number" id="discount" name="discount" placeholder="Precio con Descuento"
                        formControlName="discount">
                    <!-- <label for="size">Cantidad en Inventario</label>
                    <input type="number" id="size" name="size" formControlName="size" placeholder="Peso en kilogramos (kg)"> -->
                    <label for="size">Tamaño</label>
                    <input type="number" id="size" name="size" formControlName="size" placeholder="Tamaño del producto">
                    <label for="weight">Peso</label>
                    <input type="number" id="weight" name="weight" formControlName="weight"
                        placeholder="Peso en kilogramos (kg)">
                    <label for="stock">Cantidad en inventario</label>
                    <input type="number" id="stock" name="stock" formControlName="stock"
                        placeholder="Cantidad de unidades">
                    <label for="ProductCode">Clave Catálogo del SAT</label>
                    <input type="text" id="ProductCode" name="ProductCode" formControlName="ProductCode"
                        placeholder="Clave SAT">
                    <div class="tags">
                        <app-toggle text="Lanzamiento?" isOn={{newTag}} (click)="newTag=!newTag"></app-toggle>
                    </div>
                    <label for="char">Características</label>
                    <select formControlName="char" (change)="getCharValues()">
                        <option value="" disabled selected>Selecciona una opción</option>
                        <ng-container *ngFor="let char of chars;let i = index">
                            <option [value]="i">{{char.name}}</option>
                        </ng-container>
                    </select>
                    <select formControlName="charValue">
                        <option value="" disabled selected>Selecciona una opción</option>
                        <ng-container *ngFor="let value of charValues;index as i">
                            <option *ngIf="chars[newSubproductInfo.get('char').value].name!='Color'" [value]="i">
                                {{value}}</option>
                            <option *ngIf="chars[newSubproductInfo.get('char').value].name=='Color'" [value]="i">
                                {{value.name}}</option>
                        </ng-container>
                    </select>
                    <button class="primary-btn" (click)="addChar()">Agregar Característica</button>
                    <div class="subproduct-chars">
                        <ng-container *ngFor="let item of tempProduct|keyvalue">
                            <p *ngIf="item.key!='Color'">{{item.key}}: {{item.value}}</p>
                            <p *ngIf="item.key=='Color'"><span
                                    [style]='"background-color:"+item.value.code'></span>{{item.key}}:
                                {{item.value.name}}</p>
                        </ng-container>
                    </div>
                </div>
                <div class="images">
                    <label class="text-12 bold">Imágenes del producto</label>
                    <div class="add-image-field" (click)="fileInput.click()">
                        <i class="fas fa-plus"></i>
                    </div>
                    <input multiple type="file" style="display: none;" (change)="onFileChanged($event)" #fileInput>
                    <div class="image-list">
                        <div class="image" *ngFor="let image of images; let i = index">
                            <img [src]="image">
                            <i class="fas fa-minus-circle text-12" style="color: red;" (click)="removeImage(i)"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="edit-subproduct" *ngIf="showEditSubproduct">
            <div class="header">
                <p class="front-text">EDITAR SUBPRODUCTO</p>
                <div class="buttons">
                    <button class="primary-btn" (click)="editSubproduct()">Guardar</button>
                    <button class="secondary-btn cancel-btn" (click)="hideEditSubproduct()">Cancelar</button>
                </div>
            </div>
            <div style="display: flex;">
                <div class="create-inputs" [formGroup]="editSubproductInfo">
                    <label for="name">Nombre del producto</label>
                    <input type="text" id="name" name="name" formControlName="name">
                    <label for="price">Precio Normal</label>
                    <input type="number" id="price" name="price" formControlName="price" placeholder="Precio">
                    <label for="discount">Precio Con Descuento (0 si no tiene descuento)</label>
                    <input type="number" id="discount" name="discount" placeholder="Descuento con Descuento"
                        formControlName="discount">
                    <label for="size">Tamaño</label>
                    <input type="number" id="size" name="size" formControlName="size" placeholder="Tamaño del producto">
                    <label for="weight">Peso</label>
                    <input type="number" id="weight" name="weight" formControlName="weight"
                        placeholder="Peso en kilogramos (kg)">
                    <label for="stock">Cantidad en inventario</label>
                    <input type="number" id="stock" name="stock" formControlName="stock"
                        placeholder="Cantidad de unidades">
                    <label for="ProductCode">Clave Catálogo del SAT</label>
                    <input type="text" id="ProductCode" name="ProductCode" formControlName="ProductCode"
                        placeholder="Clave SAT">
                    <div class="tags">
                        <app-toggle text="Lanzamiento?" isOn={{newTag}} (click)="newTag=!newTag"></app-toggle>
                    </div>
                    <label for="char">Características</label>
                    <select formControlName="char" (change)="getEditCharValues()">
                        <option value="" disabled selected>Selecciona una opción</option>
                        <ng-container *ngFor="let char of chars;let i = index">
                            <option [value]="i">{{char.name}}</option>
                        </ng-container>
                    </select>
                    <select formControlName="charValue">
                        <option value="" disabled selected>Selecciona una opción</option>
                        <ng-container *ngFor="let value of charValues;index as i">
                            <option *ngIf="chars[editSubproductInfo.get('char').value].name!='Color'" [value]="i">
                                {{value}}</option>
                            <option *ngIf="chars[editSubproductInfo.get('char').value].name=='Color'" [value]="i">
                                {{value.name}}</option>
                        </ng-container>
                    </select>
                    <button class="primary-btn" (click)="addEditChar()">Agregar Característica</button>
                    <div class="subproduct-chars">
                        <ng-container *ngFor="let item of tempEditProduct|keyvalue">
                            <p *ngIf="item.key!='Color'">{{item.key}}: {{item.value}}</p>
                            <p *ngIf="item.key=='Color'"><span
                                    [style]='"background-color:"+item.value.code'></span>{{item.key}}:
                                {{item.value.name}}</p>
                        </ng-container>
                    </div>
                </div>
                <div class="images">
                    <label class="text-12 bold">Imágenes del producto</label>
                    <div class="add-image-field" (click)="fileInput.click()">
                        <i class="fas fa-plus"></i>
                    </div>
                    <input multiple type="file" style="display: none;" (change)="onFileChanged($event)" #fileInput>
                    <div class="image-list">
                        <div class="image" *ngFor="let image of subproductToEdit.images; let i = index">
                            <img [src]="image">
                            <i class="fas fa-minus-circle text-12" style="color: red;"
                                (click)="removeImageFromOriginal(i)"></i>
                        </div>
                        <div class="image" *ngFor="let image of images; let i = index">
                            <img [src]="image">
                            <i class="fas fa-minus-circle text-12" style="color: red;" (click)="removeImage(i)"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>