<navbar></navbar>
<div class="wishlist-container">
    <div class="items-container">
        <div class="title">
            <p class="front-text">LISTA DE DESEADOS</p>
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <div class="product-list">
            <div class="product" *ngFor="let product of wishlistProducts; index as i">
                <div class="img-container">
                    <img [src]="product.subproducts[0].images[0]" alt="" class="product-img">
                </div>
                <div class="info-container">
                    <p class="product-name">{{product.name}}</p>
                    <p class="product-price grey-text" *ngIf="product.subproducts[0].discount">{{product.subproducts[0].discount | currency}} <span class="product-discounted-price">{{product.subproducts[0].price | currency}}</span></p>
                    <p class="product-price grey-text" *ngIf="!product.subproducts[0].discount">{{product.subproducts[0].price | currency}}</p>
                    <ng-template #t let-fill="fill">
                        <span class="star" [class.full]="fill === 100">
                          <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                        </span>
                      </ng-template>
                      <ngb-rating [(rate)]="product.rating" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
                </div>
                <div class="buttons">
                    <p class="wishlist-add-date grey-text">Se agregó el {{product.date}}</p>
                    <button class="primary-btn cart-btn" (click)="addToCart(product)">Agregar al carrito</button>
                    <img src="../../../assets/Icons/wishlist-off.png" alt="" class="wishlist-icon" (click)="removeFromWishlist(product.id)">
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar">
        <h2>WISHLIST DE GM E-SHOP</h2>
        <p>
            ¡Bienvenido a tu lista de deseos en GM e-shop! Aquí puedes guardar cualquier producto de nuestro catálogo para que lo puedas comprar después.<br><br>
            Recuerda que agregarlo a tu lista de deseos no garantiza que lo vayas a encontrar después ya que no es como si lo apartaras.<br><br>
            Podrás mantener un ojo en tus productos favoritos y saber si alguno llega a estar en oferta o en una rebaja que le pueda beneficiar a tu bolsillo.
        </p>
    </div>
</div>