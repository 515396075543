<navbar></navbar>
<div class="wrapper">
    <div class="hero-img"></div>
    <div class="content">
        <div class="title">
            <p class="front-text">Sobre Nosotros</p>
        </div>
        <p>¿Alguna vez escuchaste sobre Guerrero Mobile? Posiblemente te suene familiar, o tal vez no. Aquí te
            platicaremos. GM e-shop es la marca bajo el nombre de Guerrero Mobile Voz y Datos, una empresa 100%
            mexicana. Contamos con 10 puntos de venta situados estratégicamente en el centro de la Ciudad de México y
            área metropolitana. En GM e-shop contamos con más de 25 años de experiencia en el negocio de los celulares y
            electrónicos, formando un negocio leal y honrado creado a partir de las gotas de sudor, lágrimas, risas y
            mucho esfuerzo por parte de nuestro fundador y colaboradores, logrando que hoy en día seamos uno de los
            distribuidores más grandes de smartphones, wearables y accesorios a nivel nacional. <br><br>
            Actualmente somos distribuidores autorizados de marcas reconocidas mundialmente como lo son Samsung, Vivo,
            Huawei, Motorola, Nokia, Oppo, etc. Pero no todo el camino fue color de rosa. A pesar de la fuerte
            competencia que existe en el mercado de los electrónicos, en GM e-shop siempre hemos buscado diferenciarnos
            por la atención y el servicio que le ofrecemos al cliente, buscando que siempre sea de calidad. <br><br>
            En GM e-shop nuestra misión es hacerle llegar a nuestros clientes el producto que lo acompañará en su día a
            día, hacerlo confiar en las compras en línea y que sepa y entienda que nuestro principal objetivo no es
            generar la venta, sino su satisfacción entera, ofreciéndole información importante para que quede
            enteramente satisfecho.<br><br>
            Queremos ofrecerle a la familia GM contenido de calidad, la mayor información posible y mayores facilidades
            para que elija el producto ideal de acuerdo a su estilo de vida, que se sienta feliz y tranquilo.
        </p>
    </div>
</div>