<navbar></navbar>
<div class="wrapper">
    <p class="header">Términos y Condiciones</p>
    <pre class="content">
Las presentes Condiciones Generales de Contratación por Medios Electrónicos (en adelante, las “Condiciones Generales”) son aplicables al acceso y/o uso del Sitio de internet www.gmeshop.mx por lo que hace a la contratación por medios electrónicos (“eCommerce”) para la compra de productos, (en adelante el “Sitio”), ofrecido por Guerrero Mobile Voz y Datos, S.A. de C.V. (en adelante “Guerrero Mobile”).

Cualquier persona que acceda y/o use el Sitio (en adelante el “Usuario”) en relación con la contratación por medios electrónicos por este hecho acepta, y expresa su consentimiento, de someterse a las presentes Condiciones Generales. Asimismo, por este hecho el Usuario se compromete a revisar de manera periódica las presentes Condiciones Generales que se encuentran disponibles para su consulta dentro del Sitio para conocer de los cambios y/o modificaciones a los mismos y, de así considerarlo conveniente, consentir con los mismos. 

El Sitio es propiedad exclusiva de Guerrero Mobile, por lo cual todo visitante se compromete a no intentar distribuir, alterar o modificar contenido alguno del mismo.

Cualquier información o material que el Usuario comparta a Guerrero Mobile a través del Sitio, es y será tratada como información no confidencial, excepto la información que sea personal en términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. 

Guerrero Mobile no busca con la información contenida en este sitio ofender a persona alguna, sector de la población o entidad gubernamental, ya que toda imagen, información, animación, video, entre otros, es incluida en este sitio de internet únicamente con el fin de dar a conocer los productos comercializados por Guerrero Mobile. 

Guerrero Mobile no discrimina a ninguna persona por cuestiones de religión, orientación sexual, situación económica y social o cualesquiera otras. Por lo tanto, la información contenida en el Sitio no puede ser interpretada como discriminatoria en ningún sentido, y Guerrero Mobile no será responsable por interpretaciones diversas a lo aquí señalado. 

Guerrero Mobile podrá modificar, agregar y/o eliminar el contenido de las presentes Condiciones Generales, sin necesidad de previo aviso, iniciando la vigencia de los cambios desde el momento de su publicación, siendo exclusiva responsabilidad del Usuario asegurarse de tomar conocimiento de tales modificaciones y, de así considerarlo conveniente, consentir con los mismos. El hecho de que el Usuario realice dentro del Sitio cualquier acto relacionado con la contratación por medios electrónicos se entenderá como una aceptación expresa a las modificaciones de que se trate. El contenido del Sitio está dirigido a los residentes de los Estados Unidos Mexicanos, por lo que la publicidad e información presentada en el mismo son válidas únicamente en los Estados Unidos Mexicanos.

1. CAPACIDAD

El contenido y servicios que ofrece el Sitio están reservados y dirigidos únicamente a personas que tengan capacidad legal para contratar, en términos del artículo 1789 del Código Civil Federal. La conducta de los menores de edad y personas con incapacidad legal que ingresen al Sitio queda bajo la estricta responsabilidad de los padres, tutores o mayor de edad a cuyo cargo se encuentran, en términos de lo establecido en los artículos 1919, 1920 y 1921 del Código Civil Federal

2. AUTORIZACIÓN. 

Guerrero Mobile otorga y concede al Usuario el derecho no exclusivo, revocable y no transferible de usar el Sitio para llevar a cabo cualquier acto relacionado con la contratación por medios electrónicos de conformidad con las presentes Condiciones Generales. El usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada en el Sitio exclusivamente para su uso personal, no comercial. La reimpresión, republicación, distribución, asignación, sublicencia, venta, reproducción electrónica o aprovechamiento por cualquier otro medio de cualquier información, documento o gráfico que aparezca en el Sitio, en todo o en parte, para cualquier uso distinto al personal no comercial está expresamente prohibido, a menos que Guerrero Mobile le haya otorgado al Usuario una autorización previamente y por escrito.

3. INSCRIPCIÓN DE USUARIO Y CONTRASEÑA 

Para realizar cualquier acto relacionado con la contratación por medios electrónicos dentro del Sitio es obligatorio completar el formulario de inscripción en todos sus campos. El Usuario deberá completar el formulario con su información personal la cual debe ser exacta, precisa y verdadera (en adelante los “Datos Personales”), y asume la responsabilidad de actualizar los Datos Personales cuando ello sea procedente o cuando Guerrero Mobile lo solicite. Guerrero Mobile NO se responsabiliza por la exactitud, precisión y veracidad de los Datos Personales de sus Usuarios, y actuará de buena fe únicamente con base en ellos, por lo que los Usuarios garantizan y responden, en cualquier caso, de la exactitud, precisión, veracidad y vigencia de sus Datos Personales. 

Asimismo, el Usuario proporcionará un correo electrónico para identificar su cuenta y generará una contraseña. Para concluir el proceso de registro, el Usuario tendrá que leer y aceptar estas Condiciones Generales. Se entiende que el Usuario ha leído, comprendido y aceptado todas las Condiciones Generales al momento de pulsar clic en el recuadro desplegado con la leyenda “Acepto las Condiciones Generales”. Una vez concluido el registro del Usuario se considera que éste ha leído, entendido y aceptado las presentes Condiciones Generales.

El Usuario accederá a su cuenta personal (en adelante la "Cuenta") mediante el ingreso de su nombre de usuario (en adelante el “Nombre de Usuario”) junto a la clave de seguridad personal elegida (en adelante la "Contraseña"). La Contraseña deberá integrarse con base en las especificaciones y/o algoritmos que el sistema del Sitio indique. 

Es responsabilidad exclusiva del Usuario mantener la confidencialidad de su Nombre de Usuario y Contraseña. 

La Cuenta es personal, única e intransferible, y está prohibido que un mismo Usuario inscriba o posea más de una Cuenta dentro del Sitio. En caso de que Guerrero Mobile detecte distintas Cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o inhabilitarlas, pues se considera como una violación a estas Condiciones Generales. 

El Usuario reconoce que su Contraseña sustituye y hace las veces de su firma autógrafa, por lo que las constancias, documentales o técnicas, en donde aparezca, tendrán fuerza, validez y producirá los mismos efectos jurídicos que la firma autógrafa. 

El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido al ingreso y uso de su Clave de Seguridad, cuyo conocimiento es exclusivo del Usuario.

4. PUBLICIDAD E INFORMACIÓN 

El Usuario reconoce y acepta que la información publicada o contenida en el Sitio generada por Guerrero Mobile o por los Proveedores, será claramente identificada de forma tal que se reconozca que la misma proviene de Guerrero Mobile o de los Proveedores. 

Las informaciones, conceptos y opiniones publicadas en el Sitio por terceras personas no necesariamente reflejan la posición de Guerrero Mobile ni de sus empleados, oficiales, directores, accionistas, licenciatarios y concesionarios (en lo sucesivo los "Afiliados"). Por esta razón, Guerrero Mobile no se hace responsable por ninguna de las informaciones, opiniones y conceptos que se emitan en el Sitio, o a través de los foros públicos o de cualquier otro espacio o servicio que se preste a través del Sitio. Asimismo, Guerrero Mobile no se hace responsable de las consecuencias que se puedan derivar del uso de consejos, procedimientos, recomendaciones o sugerencias incluidas en este Sitio, en el entendido de que es bajo el propio riesgo y responsabilidad del Usuario la aplicación y seguimiento a dichos consejos, comentarios, procedimientos, recomendaciones o sugerencias, que se den en los espacios referidos.

Guerrero Mobile no es responsable de la interpretación equivocada de la información aquí contenida, por lo que si ésta resultara incompleta o no fuere totalmente cierta para los intereses del Usuario, lo publicado aquí no podrá considerarse como información falsa o que induzca al error intencionalmente. 

Todo comentario realizado por un tercero en el Sitio que resulte insultante, discriminatorio o de cualquier otra forma ofensivo o contrario a las presentes Condiciones Generales, podrá ser suprimido por Guerrero Mobile, sin que ello sea considerado una violación al derecho de libertad de expresión. 

Por lo tanto, Guerrero Mobile se reserva el derecho de bloquear el acceso o remover en forma parcial o total, toda información, comunicación o material que a su exclusivo juicio pueda resultar: 

I. Abusivo, difamatorio, obsceno, sexual o racial; II. Fraudulento, artificioso o engañoso; III. Violatorio de derechos de autor, marcas o cualquier derecho de propiedad intelectual de un tercero; IV. Ofensivo o V. Cualquier forma que contravenga lo establecido en estas Condiciones Generales. 

Si el Usuario desea obtener mayor información de un tema en específico proveído por Guerrero Mobile o los Proveedores, el Usuario deberá consultarlo directamente con cada uno de ellos, según corresponda y/o con un especialista en la materia. 

Guerrero Mobile no tiene el control editorial sobre el contenido de terceras partes y quedará exento de cualquier responsabilidad por información y/o material generado y/o provisto por terceros. 

Todas las opiniones, consejos, declaraciones, servicios, ofertas u otras informaciones o contenidos expresados o puestos a disposición del público por terceros, pertenecen a su respectivo autor y Guerrero Mobile no asume responsabilidad alguna frente a ello. Guerrero Mobile no garantiza la exactitud, veracidad, amplitud y/o utilidad de cualquier contenido provisto por tales terceros. 

Adicionalmente, Guerrero Mobile no es responsable ni garantiza la exactitud, exhaustividad, veracidad y/o confiabilidad de cualquier opinión, información, consejo o declaración provistos por Guerrero Mobile a través de su sitio. 

En ninguna circunstancia, Guerrero Mobile será responsable de cualquier daño y/o perjuicio, directo o indirecto, causado en virtud de la confianza del Usuario por información obtenida a través de este Sitio. Guerrero Mobile se reserva el derecho de suprimir o modificar el contenido del sitio que, a exclusivo juicio de Guerrero Mobile no cumpla con los estándares de Guerrero Mobile o que pudiera resultar contrario al ordenamiento jurídico vigente. Sin embargo, Guerrero Mobile no será responsable por cualquier falla o tardanza que se genere al eliminar tal material. 

El Usuario reconoce y acepta que Guerrero Mobile es una organización independiente de terceros patrocinadores y anunciantes cuya información, imágenes, anuncios y demás material publicitario o promocional (el "Material Publicitario") pudiera estar publicado en el Sitio. 

El Usuario reconoce y acepta que el Material Publicitario no forma parte del contenido principal que se publica en el Sitio. Asimismo, el Usuario reconoce y acepta en este acto, que el Material Publicitario se encuentra protegido por las leyes que en materia de propiedad intelectual e industrial resulten aplicables.

La publicación de los productos no crea garantía expresa o implícita respecto de los mismos. 

Los precios y las promociones mostrados en el Sitio pueden ser diversos de aquéllos mostrados en las distintas tiendas departamentales de Guerrero Mobile.

5. COMERCIALIZACIÓN DE PRODUCTOS 

Guerrero Mobile publicará en el Sitio los productos y el precio total que el Usuario deberá pagar por ellos. De igual manera, se indicará si tienen alguna promoción y/o descuento, la vigencia de la misma, así como los medios habilitados para liquidar el pago de dichos productos. 

No existirá contrato alguno entre el Usuario y Guerrero Mobile con relación a cualquier artículo hasta que su pedido haya sido confirmado por Guerrero Mobile y el pago del precio correspondiente haya sido acreditado por Guerrero Mobile. 

En caso de que el Usuario opte por la compra del artículo, el número de pedido que se asigne al realizar la transacción en el Sitio servirá para dar seguimiento a la operación correspondiente. Si Guerrero Mobile tuviera algún problema con el pedido del Usuario, lo comunicará por correo electrónico al correo que registró el usuario al darse de alta. 

Guerrero Mobile dentro del Sitio aceptará como formas de pago las siguientes: 

a) Pagos mediante el sistema de procesamiento de pagos vía PayPal, los cuales incluyen:
    1. Tarjetas de crédito y tarjetas de débito previamente autorizadas por los sistemas de seguridad establecidos por la pasarela de pagos. (Paypal) 

b) Pagos mediante el sistema de procesamiento de pagos vía MercadoPago, los cuales incluyen:
    1. Tarjetas de crédito y tarjetas de débito previamente autorizadas por los sistemas de seguridad establecidos por la pasarela de pagos. (MercadoPago)

c) Cualquier forma de pago implementada dentro del sitio web por el procesador de pagos Openpay, tales como:
    1. Tarjeta de débito Visa o Matercard.
    2. Tarjeta de crédito Visa o Matercard.
    3. Tarjetas American Express.
    4. Transferencias electrónicas. (SPEI) 
    5. Sistema para procesamiento de pagos y recaudación en efectivo Paynet por Openpay.

Los precios de los productos anunciados dentro del Sitio es el precio total pues incluyen el IVA, pero se excluyen los gastos de envío, que se añadirán al importe total a pagar. 

Toda operación queda sujeta a la disponibilidad de los productos. Para el caso en que se genere la compra y no haya existencias del artículo de que se trate, el Usuario desde ese momento acepta que Guerrero Mobile realice la devolución del pago efectuado o la cancelación del cargo relativo al precio, según correspondiera el método de pago elegido: 

Previo a su solicitud de devolución deberá consultar las condiciones establecidas por la entidad financiera que le otorgó la tarjeta, relativo al tiempo de devolución del dinero en su cuenta bancaria. 

a) Para su conocimiento, las políticas de reembolso de Guerrero Mobile son las siguientes:
        i.  Tarjeta de crédito o débito Visa, Mastercard y American Express: 5 a 10 días de hábiles.
        ii. Trasferencia electrónicas (SPEI): 5 a 10 días de hábiles.
        iii. Paynet por Openpay: 5 a 10 días de hábiles.
        iv. Paypal: 5 a 10 días de hábiles.
        v. Mercado Pago: 5 a 10 días de hábiles.

Cabe señalar que en lo relativo a los incisos a) y b), los 20 días hábiles es el tiempo aproximado en que Guerrero Mobile se encarga de tramitar la devolución, sin embargo, el Usuario a su vez, deberá atender las políticas de devolución de la institución financiera que se trate, PayPal o American Express, según sea el caso. 

d) Tarjeta de débito. El reembolso se hará por transferencia electrónica dentro de los 10 días hábiles siguientes, contados a partir de que el Usuario proporcione sus datos bancarios. Dicho reembolso solo se podrá emitir al nombre del titular y a la tarjeta con la que se realizó el pago.

Las órdenes de compra son procesadas en 72 horas. Los pedidos recibidos después de las 22:00 horas serán procesados al siguiente día hábil. La entrega de la mercancía se realizará hasta en un período de 20  días hábiles una vez procesada la orden de compra. 

Si el Usuario desea facturar su compra deberá enviar un correo electrónico a: facturacion@gmeshop.com.mx 

6. ENTREGA Y GASTOS DE ENVÍO

La entrega será real, por ésta se entiende el acto en que Guerrero Mobile entrega el pedido al Usuario personalmente, a un tercero señalado por éste, o a la persona que se encuentre en el domicilio señalado por el Usuario como lugar de entrega, lo que se acreditará con la firma de recepción del pedido en la dirección de entrega convenida, misma que sucederá en el tiempo estimado.

En el supuesto de que el Usuario no sea localizado en el domicilio convenido para la entrega del pedido, Guerrero Mobile contactará al Usuario a través del correo electrónico proporcionado por el Usuario. 

Si el Usuario confirma el domicilio originalmente proporcionado o señala un nuevo domicilio para la entrega del pedido, el envío se hará llegar a éste en un plazo de 25 días hábiles. Cabe señalar que para que proceda el cambio de domicilio para la entrega de mercancía, se requiere una autorización por parte de Guerrero Mobile, la cual se procesará dentro de los 04 días hábiles siguientes a la fecha en que el Usuario proporcionó el nuevo domicilio. 

En cualquiera de los supuestos antes señalados, la entrega del pedido estará condicionada al pago del costo adicional por concepto de envío correspondiente que deberá realizar el Usuario. 

En el supuesto de que no se pueda localizar al Usuario en una segunda búsqueda o que el Usuario omita responder el correo electrónico en un plazo de 05 días naturales contados a partir de su fecha de envío, Guerrero Mobile cancelará la compra de que se trate, situación que se le informará al Usuario a través del correo electrónico proporcionado por éste y se procederá al reembolso en la misma forma de pago original, exceptuando los gastos de envío en caso de que la mercancía no haya sido transportada al domicilio del Usuario. 

Guerrero Mobile, enviará el(los) artículo(s) adquiridos a la brevedad posible, sin embargo, en caso de retrasos, perdidas o detrimento de los productos, ocasionados por causas de fuerza mayor o casos fortuitos, Guerrero Mobile no tendrá responsabilidad alguna, ni estará obligado a pagar indemnización alguna al Usuario, quien acepta expresamente que en este caso deberá enderezar su reclamo a la empresa de paquetería que corresponda. 

Asimismo, el Usuario autoriza la utilización de medios electrónicos para acusar de recibo la recepción de la mercancía, ya sea por el propio Usuario o las personas autorizadas por él; en consecuencia autorizan la utilización de FIRMA ELECTRÓNICA, la cual sustituirá, todos los efectos legales a que haya lugar, a la firma autógrafa del Usuario y/o de las Personas autorizadas, produciendo los mismos efectos que las leyes otorgan a la firma autógrafa, incluyendo el valor probatorio de ésta, se considerará como plena aceptación de los mismos y del riesgo que su uso conlleva, aceptando Usuario y/o las Personas Autorizadas por éste. 

Se entenderá como Firma Electrónica una o más de las siguientes opciones: a) Número confidencial numérico o alfanumérico; b) Autenticación a través de un Certificado Digital; c) Utilización de una firma electrónica avanzada; d) La firma realizada en una pantalla táctil; e) tecnología biométrica para la verificación de biometría de huella dactilar y biometría facial, f) La combinación de cualquiera de las anteriores en todo o en parte, g) Cualquier otra que establezca Guerrero Mobile para efectos de identificación y autenticación de entrega de mercancía de El Usuario y/o de las Personas Autorizadas para recibir la mercancía.

7. DEVOLUCIÓN Y GARANTÍA

Los productos adquiridos por el Usuario podrán ser devueltos por las siguientes causas: 

a) Si existe un error en el artículo. Para su devolución deberá conservar la envoltura original, incluyendo celofán (emplaye) o cualquier otra y el artículo no deberá presentar muestras de maltrato (acompañado con la orden de la compra correspondiente que hará las veces de ticket de compra, en compras on line, o el ticket de compra emitido en tienda). 

b) Si el artículo presenta defectos de fabricación. En este caso, el artículo se enviará para dictamen al fabricante, y deberá conservar la envoltura original, incluyendo celofán (emplaye) o cualquier otra y no deberá presentar muestras de maltrato (acompañado con la orden de la compra correspondiente que hará las veces de ticket de compra, en compras on line, o el ticket de compra emitido en tienda). 

c) Si el Usuario hace valer, de manera oportuna, el artículo 56 de la Ley Federal de Protección al Consumidor. Los clientes y destinatarios de regalos pueden intercambiar, un producto comprado en la Plataforma, por un producto de un precio idéntico o menor al del originalmente adquirido de la misma marca si este se encuentra disponible y en existencia. Asimismo, deberá seguir los lineamientos previstos en el presente aplicables a la devolución.

7.1 CONDICIONES GENERALES DE DEVOLUCIÓN. 

Es importante que los usuarios consideren que los productos a devolverse deben de cumplir con las siguientes características: 

1. Conservar su empaque original, etiquetas, adhesivos y envolturas (emplaye). 
2. Conservar la póliza de garantía, manual de uso y manual de armado, en caso de que aplique. 3. No contener signos de uso o manipulación.

7.2 DEVOLUCIONES POR ARTÍCULOS DIFERENTES O DEFECTUOSOS

En caso de que el Usuario desee devolver el producto por alguno de los motivos señalados en el numeral 7, incisos a) y/o b), deberá enviar un correo electrónico a devoluciones@gmeshop.com.mx y/o comunicarse al teléfono (56) 2327 2210, para que se le proporcione un Número de Autorización de Devolución (NAD) a efecto de darle seguimiento al proceso. La devolución podrá hacerse mediante envío vía paquetería. 

7.3 DEVOLUCIONES POR PAQUETERIA

Para devolución por paquetería, el Usuario deberá seguir los siguientes pasos: 

1. Solicitar la guía de devolución vía correo electrónico devoluciones@gmeshop.com.mx  y/o vía teléfono (56) 2327 2210, en el que también se solicitará el Número de Autorización de Devolución. 
2. Enviar el producto con todas sus etiquetas, adhesivos, empaque original, envolturas (emplaye), garantías e instructivos, así como copia del ticket de venta generado por la página web o la tienda, dentro de una caja. El producto debe estar bien empacado para que no sufra daños en el traslado ya que Guerrero Mobile no se hace responsable si el producto es dañado por la empresa de paquetería por una omisión en la protección en el envío.
3. Pegar las etiquetas de la guía en el paquete y sellarlo bien. 
4. Llevar el paquete a cualquier sucursal de la empresa de paquetería de la cual se emitió la guía. 
5. Tomar fotos como evidencia de que se envió correctamente el producto. 
6. El Cliente deberá conservar copia de la guía sellada como comprobante de envío a través de la empresa de paquetería. 
7. Al recibir una devolución, Guerrero Mobile verificará que el producto devuelto cumpla con las Condiciones Generales de Devolución y las Políticas de Devolución y, de ser así, se procederá con el reembolso, cancelación del cargo o cambio correspondiente, en caso contrario, hará la devolución del producto a la dirección del remitente. 

En caso de no cumplirse con los pasos y requisitos previamente señalados, Guerrero Mobile podrá rechazar la solicitud de devolución y no procederá el reembolso o cancelación del cargo del precio pagado ni el cambio correspondiente, retornando el producto a la dirección del remitente. 

Una vez aceptada la devolución, se sustituirá el artículo cuya entrega se hará a la dirección de entrega convenida o, en su defecto, se hará la devolución del pago efectuado o la cancelación del cargo relativo al precio, para lo que se seguirán los lineamientos marcados en el numeral 5 de las presentes Condiciones Generales. 

7.4 DEVOLUCIONES POR ARREPENTIMIENTO

En caso de que el Usuario desee devolver el producto por el motivo señalado en el numeral 7, inciso c), deberá enviar un correo electrónico devoluciones@gmeshop.com.mx  y/o comunicarse al teléfono (56) 2327 2210, realizando la solicitud, para que se le proporcione un Número de Autorización de Devolución (NAD) a efecto de darle seguimiento al proceso. 

Una vez obtenido el número de autorización de devolución, podrá realizarse la devolución con envío vía paquetería 

Para la devolución en este caso, el producto deberá cumplir con los siguientes requisitos: 

    i. El producto deberá ser nuevo y sin uso, conservando el estado original que guardaba cuando fue recibido por el Usuario (incluyendo accesorios, empaques, garantías, manuales, entre otros). 
    ii. El producto no deberá presentar muestras de maltrato. 
    iii. El producto deberá conservar su envoltura original (emplaye). 
    iv. El producto no deberá estar alterado por el Usuario. 

7.5 CATEGORÍAS EXCLUIDAS EN EL PROCESO DE DEVOLUCIÓN

Derivado de sus características particulares, no existen devoluciones en las siguientes categorías de productos: productos de cuidado y aseo personal.

7.6 GARANTÍA DE PRODUCTOS

La garantía respecto de los siguientes productos adquiridos se hará válida directamente con el fabricante del artículo, de conformidad con sus políticas, pólizas de garantía, procedimientos y restricciones. Respecto al resto de los productos, el Usuario entiende y acepta que Guerrero Mobile no ofrece garantías superiores a las señaladas en la Ley Federal de Protección al Consumidor o en las Normas Oficiales Mexicanas aplicables.

8. DERECHOS DE AUTOR Y PROPIEDAD INTELECTUAL 

El contenido de las pantallas relativas a los servicios de Guerrero Mobile, así como programas, bases de datos, redes, archivos que permiten al Usuario acceder y usar su Cuenta, son propiedad de Guerrero Mobile y están protegidas por las Leyes y tratados internaciones de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de Guerrero Mobile. El Sitio puede contener enlaces a otros sitios web lo cual no indica que sean propiedad u operados por Guerrero Mobile. En virtud de que Guerrero Mobile no tiene control sobre tales sitios, NO será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causados directa o indirectamente. La presencia de enlaces a otros Sitios no implica una sociedad, relación, aprobación o respaldo de Guerrero Mobile a dichos sitios y sus contenidos.

9. FALLAS EN EL SISTEMA 

Guerrero Mobile no se responsabiliza por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet. Guerrero Mobile tampoco será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen de su sitio web o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet. Guerrero Mobile no garantiza el acceso y uso continuado o ininterrumpido de su sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a Guerrero Mobile; en tales casos se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. Guerrero Mobile no será responsable por ningún error u omisión contenidos en su sitio web.

10. AVISOS COMERCIALES 

El Usuario entiende y acepta que en virtud de la aceptación de estas Condiciones Generales igualmente acepta recibir información comercial de Guerrero Mobile vía correo electrónico.

De igual manera, el Usuario puede informar a Guerrero Mobile, para el caso de que desee dejar de recibir información de promociones y publicidad de productos y servicios, enviando su solicitud al área de “Privacidad de Datos”, a través de la cuenta de correo electrónico notificaciones@gmeshop.com.mx. 

Guerrero Mobile emplea cookies, web beacons y otras tecnologías de rastreo y seguimiento para monitorear su comportamiento durante la navegación del Usuario en el Sitio, ello con la finalidad de proporcionar un mejor servicio, presentar publicidad y ofertas, personalizar la experiencia del Usuario en el Sitio, así como poder ofrecerle nuevos productos y servicios basados en sus preferencias. 

Los datos personales que obtenemos con estas tecnologías de rastreo consisten en: hábitos de navegación en Internet, horario y tiempo de navegación en el Sitio, secciones consultadas y páginas de Internet que han sido accedidas previamente al Sitio. 

En esta cuestión, el Usuario debe asegurarse que la configuración de su computadora sea acorde a si está dispuesto a recibir cookies o no. Puede configurar su navegador para que le advierta antes de aceptar cookies, o simplemente puede configurarlo para que las rechace. Por otra parte, como las balizas web forman parte del sitio web, no es posible deshabilitarlas, pero puede hacerlas ineficaces al deshabilitar las cookies que establecen. 

11. LEGISLACIÓN APLICABLE Y JURISDICCIÓN 

Las presentes Condiciones Generales estarán regidos en todos sus puntos por las leyes vigentes en la República Mexicana. En caso de controversia, respecto de la interpretación, cumplimiento y ejecución de las presentes Condiciones Generales, las partes expresamente se comprometen en primer lugar a acudir ante la Procuraduría Federal del Consumidor, y en caso de persistir la controversia, a la jurisdicción de los tribunales competentes de la Ciudad de México, renunciando en consecuencia a cualquier fuero que, en razón de su domicilio presente o futuro pudiera corresponderles.

12. DOMICILIO 

Para efectos de las presentes Condiciones Generales, se fija como domicilio de Guerrero Mobile el ubicado en la Calle Coscomate No. 71, Colonia Toriello Guerra, Alcaldía Tlalpan, Ciudad de México, C.P. 14050.
    </pre>
</div>