<div class="navbar-wrapper">
    <div class="left-navigation half-flex">
        <a routerLink="/" routerLinkActive="selected-tab" [routerLinkActiveOptions]="{exact: true}" class="navigation-link">Inicio</a>
        <a routerLink="/tienda" (click)="redirectToStore()" routerLinkActive="selected-tab" [routerLinkActiveOptions]="{ exact: false }" class="navigation-link">Tienda</a>
        <a routerLink="/rastreo" routerLinkActive="selected-tab" class="navigation-link">Rastrear</a>
    </div>
    <div class="right-navigation half-flex">
        <div class="searchbar" [class.searchbar-active]="showSearchbar"><input (keydown.enter)="search($event.target.value)" placeholder="Samsumg Galaxy A51..."/><img class="search-icon" src="/assets/Icons/search.svg" (click)="toggleSearch()"></div>
        <i class="fas fa-bars menu-desktop" (click)="toggleGeneralMenu()"></i>
        <div class="shopping-icon" routerLink="/micarrito"><img src="/assets/Icons/shopping.svg"><div class="product-number" *ngIf="productNumber>0">{{productNumber}}</div></div>
        <div class="user" (click)="toggleUserMenu()" *ngIf="isLoggedIn && currentUser">
            <img src="/assets/Icons/user.svg">
        </div>
        <p routerLink="/login" *ngIf="!isLoggedIn">Iniciar Sesión</p>
    </div>
    <img routerLink="/" src="../../../assets/Logos/GMESHOPW-04.svg" class="gm-logo">
    
    <div class="user-menu" [class.display-user-menu]="showUserMenu">
        <div class="user-option" routerLink="/mi-cuenta" routerLinkActive="selected-user-option">Mi cuenta</div>
        <div class="user-option" routerLink="/wishlist" routerLinkActive="selected-user-option">Mi Wishlist</div>
        <div class="user-option" routerLink="/facturas" routerLinkActive="selected-user-option">Facturación</div>
        <div class="user-option" (click)="logout()">Cerrar Sesión</div>
    </div>

    <div class="user-menu" [class.display-general-menu]="showGeneralMenu">
        <div class="user-option" routerLink="/acerca" routerLinkActive="selected-user-option">sobre nosotros</div>
        <div class="user-option" routerLinkActive="selected-user-option">preguntas frecuentes</div>
        <div class="user-option" [routerLink]="['/']" fragment="marketplaces" (click)="navigateTo('marketplaces')">marketplaces</div>
        <div class="user-option" routerLinkActive="selected-user-option">inicia tu negocio</div>
    </div>
    
    
    <div class="shopping-icon mobile-cart" routerLink="/micarrito"><img src="/assets/Icons/shopping.svg"><div class="product-number" *ngIf="productNumber>0">{{productNumber}}</div></div>
    <i class="fas fa-bars menu-mobile" (click)="toggleMenu()"></i>
    <div class="hamburguer-menu" [class.show-hamburguer]="showMenu" *ngIf="!showAccount && !showMarketplaces">
        <a (click)="toggleMenu()" routerLink="/" class="navigation-link-mobile">Inicio</a>
        <a (click)="toggleMenu()" [routerLink]="['/tienda','todos','todos']" class="navigation-link-mobile">Tienda</a>
        <a (click)="toggleMenu()" routerLink="/rastreo" class="navigation-link-mobile">Rastrear</a>
        <a (click)="toggleAccount()" class="navigation-link-mobile" *ngIf="isLoggedIn && currentUser">Mi cuenta <i class="fas fa-caret-right" style="position: absolute; right: 10px;"></i></a>
        <a (click)="toggleMarketplaces()" class="navigation-link-mobile">Marketplaces <i class="fas fa-caret-right" style="position: absolute; right: 10px;"></i></a>
        <a (click)="toggleMenu()" routerLink="/acerca" class="navigation-link-mobile">Sobre Nosotros</a>
        <a (click)="toggleMenu()" class="navigation-link-mobile">Inicia tu Negocio</a>
        <a (click)="toggleMenu()" class="navigation-link-mobile">Preguntas Frecuentes</a>
        <div class="bottom-text">
            <p *ngIf="!isLoggedIn || !currentUser"><a routerLink="/login">Iniciar Sesión</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a routerLink="/registro">Registrate</a></p>
            <a *ngIf="isLoggedIn && currentUser" (click)="logout()">Cerrar Sesión</a>
        </div>
    </div>
    <div class="hamburguer-menu" [class.show-hamburguer]="showMenu" *ngIf="showMarketplaces">
        <a class="menu-title" (click)="toggleMarketplaces()"><i class="fas fa-caret-left" style="position: absolute; left: 10px;"></i> Marketplaces</a>
        <div class="marketplaces-links">
            <a (click)="toggleMenu()" target="_blank" href="https://www.amazon.com.mx/s?me=A3OM4HANF0P6SL&marketplaceID=A1AM78C64UM0Y8">Amazon</a>
            <a (click)="toggleMenu()" target="_blank" href="https://tienda.mercadolibre.com.mx/gm-eshop">Mercado Libre</a>
            <a (click)="toggleMenu()" target="_blank" href="https://www.walmart.com.mx/celulares/smartphones/celulares-desbloqueados/xiaomi-redmi-9-desbloqueado-ram-dual-sim-32-gb-3-gb-ram-6-53-pulgadas-carbon-grey-xiaomi-redmi-9-carbon-grey_00694105964581">Walmart</a>
            <a (click)="toggleMenu()" target="_blank" href="https://www.claroshop.com/tienda/124751/GM-ESHOP/pagina=1">Claro Shop</a>
            <a (click)="toggleMenu()" target="_blank" href="https://www.liverpool.com.mx/tienda/sp/GM%20e-shop/3075">Liverpool</a>
            <a (click)="toggleMenu()" target="_blank" href="https://www.linio.com.mx/s/guerrero-mobile-voz-y-datos-sa-de-cv">Linio</a>
            <a (click)="toggleMenu()" target="_blank" href="https://www.sears.com.mx/producto/405638/samsung-galaxy-a11-negro-64gb-desbloqueado--earbuds-2/">Sears</a>
        </div>
        
    </div>

    <div class="hamburguer-menu" [class.show-hamburguer]="showMenu" *ngIf="showAccount">
        <a class="menu-title" (click)="toggleAccount()"><i class="fas fa-caret-left" style="position: absolute; left: 10px;"></i> Mi Cuenta</a>
        <div class="account-links">
            <div class="account-option" routerLink="/mi-cuenta">
                <div class="option-img">
                    <img src="../../../assets/Icons/user.svg" alt="">
                </div>
                <p>Mi perfil<br><span style="font-size: 10px;">Revisa tus pedidos, direcciones y métodos de pago</span></p>
            </div>
            <div class="account-option" >
                <div class="option-img">
                    <img src="../../../assets/Icons/payments.svg" alt="">
                </div>
                <p>Mis pagos<br><span style="font-size: 10px;">Revisa tu historial de pagos</span></p>
            </div>
            <div class="account-option" routerLink="/wishlist">
                <div class="option-img">
                    <img src="../../../assets/Icons/wishlist-on.png" alt="">
                </div>
                <p>Wish List<br><span style="font-size: 10px;">Checa los productos que quieres comprar</span></p>
            </div>
            <div class="account-option" routerLink="/facturas">
                <div class="option-img">
                    <img src="../../../assets/Icons/facturas.svg" alt="">
                </div>
                <p>Facturación<br><span style="font-size: 10px;">Factura tus pedidos al momento</span></p>
            </div>
        </div>
        <div class="bottom-text">
            <p *ngIf="!isLoggedIn || !currentUser"><a routerLink="/login">Iniciar Sesión</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a routerLink="/registro">Registrate</a></p>
            <a *ngIf="isLoggedIn && currentUser" (click)="logout()">Cerrar Sesión</a>
        </div>
    </div>

</div>
