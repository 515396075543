import { Component, OnInit } from '@angular/core';
import Splide from '@splidejs/splide';
import { ProductService } from 'src/app/services/product.service';
import { SliderService } from 'src/app/services/slider.service';
import { NgxSpinnerService } from "ngx-spinner";
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-landing-page-new',
  templateUrl: './landing-page-new.component.html',
  styleUrls: ['./landing-page-new.component.scss']
})
export class LandingPageNewComponent implements OnInit {
  splider_main
  splider_sponsors
  splider_new_products
  splider_new_products_2

  products

  smartphones
  computers
  smarthomes
  smartwatches
  headphones

  sliderImages =[]
  sliderTitles = []

  constructor(public productService: ProductService, 
    public sliderService: SliderService,
    public spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    (function (d, s, id) { 
      var js; if (d.getElementById(id)) { return; } js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js); 
    }(document, "script", "EmbedSocialHashtagScript"));
    this.spinner.show()
    this.sliderService.getSliderProperties().pipe(first()).subscribe((res:any) => {
      this.sliderImages = []
      this.sliderImages = res[0].images
      this.sliderTitles = res[1].titles
      setTimeout(() => {
        this.splider_main =  new Splide('#splide_main', {
          type: 'loop',
          perPage: 1,
          classes: {
            prev: 'splide__arrow--prev prev-arrow',
            next: 'splide__arrow--next next-arrow',
            page: 'splide__pagination__page slider-page',
            pagination: 'splide__pagination slider-page-container'
          },
          arrowPath: 'M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
        }).mount();
        this.spinner.hide()
      }, 500);
    })
    this.productService.getProducts().subscribe(res => {
      for (var i = res.length - 1; i >= 0; --i) {
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }
      }
      this.products = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
      setTimeout(() => {
        this.splider_new_products =  new Splide('#splide_new_products', {
          type: 'loop',
          classes: {
            prev: 'splide__arrow--prev prev-arrow-new',
            next: 'splide__arrow--next next-arrow-new',
          },
          perPage: 4,
          drag: false,
          pagination: false,
          breakpoints: {
            1000: {
              perPage: 1,
            }
          }
        }).mount();
      }, 500);
    })
    this.productService.getProductsByCategory('Smartphones').subscribe(res => {
      for (var i = res.length - 1; i >= 0; --i) {
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }
      }
      this.smartphones = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
    })
    this.productService.getProductsByCategory('Computación').subscribe(res => {
      for (var i = res.length - 1; i >= 0; --i) {
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }
      }
      this.computers = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
    })
    this.productService.getProductsByCategory('Smart Home').subscribe(res => {
      for (var i = res.length - 1; i >= 0; --i) {
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }
      }
      this.smarthomes = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
    })
    this.productService.getProductsByCategory('Smartwatch').subscribe(res => {
      for (var i = res.length - 1; i >= 0; --i) {
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }
      }
      this.smartwatches = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
    })
    this.productService.getProductsByCategory('Audífonos').subscribe(res => {
      for (var i = res.length - 1; i >= 0; --i) {
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }
      }
      this.headphones = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
      setTimeout(() => {
        this.splider_new_products_2 =  new Splide('#splide_new_products_2', {
          type: 'loop',
          drag: false,
          pagination: false,
          classes: {
            prev: 'splide__arrow--prev prev-arrow-new',
            next: 'splide__arrow--next next-arrow-new',
          },
          perPage: 4,
          breakpoints: {
            1000: {
              perPage: 1,
            }
          }
        }).mount();
      }, 100);
    })
    this.splider_sponsors =  new Splide('#splide_sponsors', {
      type: 'loop',
      perPage: 4,
      arrows: false,
      pagination: false,
      autoplay: true,
      interval: 1500,
      drag: false,
      breakpoints: {
        800: {
          perPage: 2
        },
        500: {
          perPage: 1
        }
      }
    }).mount();
    
  }

}
