import { Component, OnInit } from '@angular/core';
import { Upload } from 'src/app/shared/upload';
import { SliderService } from '../../services/slider.service'

@Component({
  selector: 'app-admin-slider',
  templateUrl: './admin-slider.component.html',
  styleUrls: ['./admin-slider.component.scss']
})
export class AdminSliderComponent implements OnInit {
  sliderImages
  sliderTitles = []
  constructor(public sliderService: SliderService) { }

  ngOnInit(): void {
    this.sliderService.getSliderProperties().subscribe((res) => {
      this.sliderImages = res[0].images
      this.sliderTitles = res[1].titles
    })
  }

  changeSliderImg(img,index){
    const imageTemp = img.target.files[0];
    let image = new Upload(imageTemp)

    const reader = new FileReader();
    reader.readAsDataURL(imageTemp); 
    reader.onload = (_event) => {
    }
    this.sliderService.changeSliderImages(this.sliderImages,image,index)
  }

  saveTitle(){
    this.sliderService.updateTitles(this.sliderTitles)
    window.alert('Títulos Actualizados')
  }

}
