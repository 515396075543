import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public stringVar = new Subject<any>();
  public stringVar$ = this.stringVar.asObservable() //Has a $

  constructor() {
    this.setTokens({items: []})
  }

  public setTokens(cart: any) {
    this.stringVar.next(cart);
  }

  public getTokens() {
    return this.stringVar$
  }
}
