<div class="wrapper">
    <div class="sidebar">
        <!-- <img src="../../../assets/Logos/Gm-navbar-logo.png" alt="" class="sidebar-logo"> -->
        <p class="admin-tab" routerLink="/admin-productos" routerLinkActive="selected-tab">Productos</p>
        <p class="admin-tab" routerLink="/admin-caracteristicas" routerLinkActive="selected-tab">Características</p>
        <p class="admin-tab" routerLink="/admin-pedidos" routerLinkActive="selected-tab">Pedidos</p>
        <p class="admin-tab" routerLink="/admin-perfiles" routerLinkActive="selected-tab">Perfiles</p>
        <p class="admin-tab" routerLink="/admin-cupones" routerLinkActive="selected-tab">Cupones</p>
        <p class="admin-tab" routerLink="/admin-slider" routerLinkActive="selected-tab">Slider</p>
        <p class="admin-tab" routerLink="/admin-campañas" routerLinkActive="selected-tab">Campañas</p>
    </div>
    <div class="content">
        <div class="header">
            <p style="margin-bottom: 10px;">Slider</p>
        </div>
        <div class="content-body">
            <p>Haz click para cambiar las imágenes.</p>
            <diV class="slider-imgs" *ngIf="sliderImages">
                <div class="slider-img" (click)="fileInput1.click()">
                    <img [src]="sliderImages[0]">
                    <p>Imagen 1</p>
                    <input type="file" style="display: none;" (change)="changeSliderImg($event,0)" #fileInput1>
                </div>
                <div class="slider-img" (click)="fileInput2.click()">
                    <img [src]="sliderImages[1]">
                    <p>Imagen 2</p>
                    <input type="file" style="display: none;" (change)="changeSliderImg($event,1)" #fileInput2>
                </div>
                <div class="slider-img" (click)="fileInput3.click()">
                    <img [src]="sliderImages[2]">
                    <p>Imagen 3</p>
                    <input type="file" style="display: none;" (change)="changeSliderImg($event,2)" #fileInput3>
                </div>
            </diV>
            <div class="header">
                <p style="margin-bottom: 10px;">Títulos</p>
            </div>
            <div class="slider-titles">
                <label>Slider 1</label>
                <input type="text" [(ngModel)]="sliderTitles[0]">
                <!-- <label>Slider 2</label>
                <input type="text" [(ngModel)]="sliderTitles[1]">
                <label>Instagram</label>
                <input type="text" [(ngModel)]="sliderTitles[2]"> -->
                <button class="primary-btn" (click)="saveTitle()">Guardar</button>
            </div>
            
        </div>
    </div>
</div>