<div class="signup-wrapper">
    <div class="signup-image">
        <img src="../../../assets/Logos/GMESHOPW-04.svg" alt="GM eshop logo" class="gm-logo">
        <div class="social-icons">
            <i class="fab fa-instagram"></i>
            <!-- <i class="fab fa-twitter"></i> -->
            <i class="fab fa-facebook"></i>
        </div>
        <p class="hero-text">Preparate para estrenar un nuevo celular</p>
    </div>
    <div class="signup-inputs">
        <div class="top-links">
            <p class="return-link" routerLink="/"><i class="fas fa-chevron-left"></i> Regresar a la página</p>
            <p class="register-link" routerLink="/login">Inicia Sesión aquí</p>
        </div>
        <div class="title">
            <p class="front-text">REGISTRO</p>
            <!-- <p class="bg-text">REGISTRO</p> -->
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <form>
            <label class="primary-label">Nombre</label>
            <input [(ngModel)]="name" name="name" type="text" class="primary-input" placeholder="Juan">
            <label class="primary-label">EMAIL</label>
            <input [(ngModel)]="mail" name="mail" type="text" class="primary-input" placeholder="correo@correo.com">
            <label class="primary-label">CONTRASEÑA</label>
            <input [(ngModel)]="password" name="password" type="password" class="primary-input"
                placeholder="•••••••••••">
            <p class="error-text">{{errorText}}</p>
            <div class="terms-confirmation" (click)="toggleAcceptTerms()">
                <div class="checkbox" [class.filled-checkbox]="termsAccepted"></div>He leido y acepto &nbsp; <a
                    routerLink="/aviso" target="_blank">el Aviso de privacidad</a>&nbsp; y &nbsp; <a
                    routerLink="/terminos" target="_blank">los términos y condiciones</a>
            </div>
            <p class="primary-label" style="margin-top: 10px;">TAMBIÉN PUEDES USAR</p>
            <div class="icons">
                <div class="google-icon" (click)="signupWithGoogle()"><i class="fab fa-google"></i></div>
                <i class="fab fa-facebook" (click)="signupWithFacebook()"></i>
            </div>
            <div class="bottom-buttons">
                <button class="primary-btn" [class.disabled-btn]="!termsAccepted" (click)="createUser()"
                    id="signup_button">Registrarse</button>
                <button class="secondary-btn" routerLink="/signup">Iniciar Sesión</button>
            </div>
        </form>
    </div>
</div>