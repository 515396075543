import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss']
})
export class FacturasComponent implements OnInit {
  userID
  currentUser
  orderList

  selectedOrder = 0;

  orderToDisplay

  factura = {
    NameId: 1,
    Currency: "MXN",
    ExpeditionPlace: "03020",
    CfdiType: "I",
    PaymentForm: "01",
    PaymentMethod: "PUE",
    Receiver: {
      Rfc: "",
      Name: "",
      CfdiUse: "04"
    },
    Items: [

    ],
  }

  user = new FormGroup({
    name: new FormControl('',[Validators.required]),
    mail: new FormControl('',[Validators.required,Validators.email]),
    rfc: new FormControl('',[Validators.required]),
    cfdiUse: new FormControl('G03',[Validators.required]),
    paymentForm: new FormControl('04',[Validators.required])
  })

constructor(public invoiceService: InvoiceService,
  public orderService: OrderService,
  public userService: UserService,
  public firebaseAuthService: AuthService,
  public fireAuth: AngularFireAuth,
  public spinner: NgxSpinnerService
  ) { }

ngOnInit(): void {
  this.spinner.show('loadingSpinner')
  this.firebaseAuthService.getCurrentUser().then((res) => {
    if(res){
      this.userID = res.uid
      this.userService.getUserById(res.uid).subscribe((res) => {
        this.currentUser = res;
      })
    }
    this.orderService.getOrdersByUserId(res.uid).subscribe((res:any) => {
      res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
      for (let order of res) {
        order.date = this.transformDate(order.date)
      }
      this.orderList = res
      if(res[0])this.orderToDisplay = res[0]
      this.spinner.hide('loadingSpinner')
    })
  });
}

transformDate(date) {
  let d;
  if (date != undefined) d = new Date(date.seconds * 1000);
  if (date == undefined) d = new Date();
  let options = { day: "numeric", month: "long", year: "numeric" };
  return d.toLocaleDateString("es-ES", options);
}

selectOrder(index){
  this.selectedOrder = index
  this.orderToDisplay = this.orderList[index]
  console.log(this.orderToDisplay.id)
}

generateInvoice(){
  this.spinner.show('invoiceSpinner')
  this.factura.PaymentForm=this.user.get('paymentForm').value
  this.factura.Receiver.Name=this.user.get('name').value
  this.factura.Receiver.Rfc=this.user.get('rfc').value
  this.factura.Receiver.CfdiUse=this.user.get('cfdiUse').value
  for(let product of this.orderList[this.selectedOrder].products){
    let tempItem = {
      "ProductCode": "",
      "Description": "",
      "UnitCode": "",
      "Unit": "",
      "UnitPrice": 0,
      "Quantity": 0,
      "Subtotal": 0,
      "Taxes": [
        {
          "Total": 0,
          "Name": "IVA",
          "Base": 0,
          "Rate": 0.16,
          "IsRetention": false
        }
      ],
      "Total": 0
    }
    tempItem.ProductCode = product.ProductCode
    tempItem.Description = product.name
    tempItem.UnitCode = product.UnitCode
    tempItem.Unit = product.Unit
    tempItem.UnitPrice = +(Math.round(product.price*100)/116).toFixed(2)
    tempItem.Quantity = product.quantity
    tempItem.Subtotal = tempItem.UnitPrice*tempItem.Quantity
    tempItem.Taxes[0].Total = +(Math.round(tempItem.UnitPrice*.16*tempItem.Quantity*100)/100).toFixed(2)
    tempItem.Taxes[0].Base = +(Math.round(tempItem.UnitPrice*tempItem.Quantity*100)/100).toFixed(2)
    tempItem.Total = tempItem.Subtotal+tempItem.Taxes[0].Total
    this.factura.Items.push(tempItem)
  }
  if(this.orderToDisplay.shippingCost>0 && this.orderToDisplay.discountCode && this.orderToDisplay.discountCode.type!="envios"){
    let envioItem = {
      "ProductCode": "78102200",
        "Description": "Costos de Envío",
        "UnitCode": "E48",
        "Unit": "Pieza",
        "UnitPrice": +(Math.round(this.orderToDisplay.shippingCost*100)/116).toFixed(2),
        "Quantity": 1,
        "Subtotal": +(Math.round(this.orderToDisplay.shippingCost*100)/116).toFixed(2),
        "Taxes": [
          {
            "Total": +(Math.round((this.orderToDisplay.shippingCost*100)/116*.16*100)/100).toFixed(2),
            "Name": "IVA",
            "Base": +(Math.round(this.orderToDisplay.shippingCost*100)/116).toFixed(2),
            "Rate": 0.16,
            "IsRetention": false
          }
        ],
        "Total": this.orderToDisplay.shippingCost
    }
    console.log(envioItem)
    this.factura.Items.push(envioItem)
  }
  try{
    this.invoiceService.generateInvoice(this.factura).subscribe((res:any)=>{
     console.log(res)
     this.invoiceService.mailInvoice({id:res.Id,mail:this.user.get('mail').value}).subscribe((res) => {
       this.orderToDisplay.invoice = true
       this.orderService.updateOrder(this.orderToDisplay.id,{invoice:true})
       this.spinner.hide('invoiceSpinner')
       window.alert('Factura generada con éxito. Se enviará al correo proporcionado.')
       this.factura = {
         NameId: 1,
         Currency: "MXN",
         ExpeditionPlace: "03020",
         CfdiType: "I",
         PaymentForm: "01",
         PaymentMethod: "PUE",
         Receiver: {
           Rfc: "",
           Name: "",
           CfdiUse: "04"
         },
         Items: [
     
         ],
       }
     })
   })
  }catch(error){
    console.log(error)
    window.alert('Error al generar factura, intente más tarde.')
    this.spinner.hide('invoiceSpinner')
  }
}

}
