import { Component, OnInit } from '@angular/core';
import { CharacteristicService } from '../../services/characteristic.service'

@Component({
  selector: 'app-admin-characteristics',
  templateUrl: './admin-characteristics.component.html',
  styleUrls: ['./admin-characteristics.component.scss']
})
export class AdminCharacteristicsComponent implements OnInit {
  chars

  selectedCharIndex = -1
  selectedChar:any = {
    name: ''
  }

  editing=false

  newCharName = ''
  newCharVariable = true

  editCharName = ''
  editCharValue = ''
  editCharVariable = ''

  addColorName = ''
  addColorCode = ''
  

  constructor(public charService: CharacteristicService) { }

  ngOnInit(): void {
    this.charService.getChars().subscribe(res => {
      this.chars = res
    })
  }

  changeChar(){
    if(this.selectedCharIndex==-1)return this.selectedChar={name: ''}
    this.selectedChar = this.chars[this.selectedCharIndex]
    this.editCharName = this.selectedChar.name
    this.editCharVariable = this.selectedChar.variable
  }

  addChar(){
    let tempChar = {
      name: this.newCharName,
      value: [],
      variable: this.newCharVariable == true
    }
    this.charService.addChar(tempChar)
    window.alert('Característica agregada')
    var mobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
      if(mobile)alert('Característica agregada')
    setTimeout(() => {
      this.newCharName = ''
      this.newCharVariable = true
    }, 300);
  }

  editChar(){
    let tempChar = this.selectedChar
    let tempIndex = this.selectedCharIndex
    this.selectedCharIndex = -1
    tempChar.name = this.editCharName
    tempChar.variable = this.editCharVariable == 'true'
    this.charService.updateChar(this.selectedChar.id,tempChar).then(() => {
      setTimeout(() => {
        this.selectedCharIndex = tempIndex 
      }, 200);
    })
  }

  removeChar(){
    this.charService.removeChar(this.selectedChar.id)
    this.selectedCharIndex = -1
  }

  addValue(){
    let tempChar = this.selectedChar
    let tempIndex = this.selectedCharIndex
    this.selectedCharIndex = -1
    tempChar.value.push(this.editCharValue)
    this.charService.updateChar(this.selectedChar.id,tempChar).then(() => {
      setTimeout(() => {
        this.selectedCharIndex = tempIndex 
      }, 200);
    })
  }

  addColor(){
    let tempChar = this.selectedChar
    let tempIndex = this.selectedCharIndex
    this.selectedCharIndex = -1
    tempChar.value.push({name: this.addColorName,code:this.addColorCode})
    this.charService.updateChar(this.selectedChar.id,tempChar).then(() => {
      setTimeout(() => {
        this.selectedCharIndex = tempIndex 
      }, 200);
    })
  }

  removeValue(index){
    let tempChar = this.selectedChar
    let tempIndex = this.selectedCharIndex
    this.selectedCharIndex = -1
    tempChar.value.splice(index,1)
    this.charService.updateChar(this.selectedChar.id,tempChar).then(() => {
      setTimeout(() => {
        this.selectedCharIndex = tempIndex 
      }, 200);
    })
  }

}
