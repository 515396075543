<navbar></navbar>
<div class="shop-wrapper">
    <div class="side-bar">
        <div class="filter">
            <p class="filter-title" (click)="toggleShow(0)">Comprar por categoría <i class="fas fa-chevron-up"
                    [class.rotated]="!showFilter[0]"></i></p>
            <div *ngIf="showFilter[0]">
                <p class="filter-option no-icon" (click)="setCategoryFilter('todos')"
                    [class.selected-category]="categoryForFilter=='todos'">Todos</p>
                <p class="filter-option" (click)="setCategoryFilter('Smartphones')"
                    [class.selected-category]="categoryForFilter=='Smartphones'"><img
                        src="/assets/Icons/phone-outline.svg" alt="" class="category-icon">Smartphones</p>
                <p class="filter-option" (click)="setCategoryFilter('Computación')"
                    [class.selected-category]="categoryForFilter=='Computación'"><img
                        src="/assets/Icons/laptop-outline.svg" alt="" class="category-icon">Computación</p>
                <p class="filter-option" (click)="setCategoryFilter('Smart Home')"
                    [class.selected-category]="categoryForFilter=='Smart Home'"><img
                        src="/assets/Icons/home-outline.svg" alt="" class="category-icon">Smart Home</p>
                <p class="filter-option" (click)="setCategoryFilter('Smartwatch')"
                    [class.selected-category]="categoryForFilter=='Smartwatch'"><img
                        src="/assets/Icons/watch-outline.svg" alt="" class="category-icon">Smartwatch</p>
                <p class="filter-option" (click)="setCategoryFilter('Audífonos')"
                    [class.selected-category]="categoryForFilter=='Audífonos'"><img
                        src="/assets/Icons/earbufs-outline.svg" alt="" class="category-icon">Audífonos</p>
            </div>
        </div>
        <div class="filter custom-slider">
            <p class="filter-title">Comprar por Precio</p>
            <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"
                (valueChange)="setPriceFilters()"></ngx-slider>
        </div>
        <div class="filter" *ngFor="let char of chars;index as i">
            <p class="filter-title" (click)="toggleShow(i+1)">Comprar por {{char.name}} <i class="fas fa-chevron-up"
                    [class.rotated]="!showFilter[i+1]"></i></p>
            <div *ngIf="showFilter[i+1]">
                <p class="filter-option smaller-filter" (click)="setFilter(i+1,'todos')"
                    [class.selected-category]="selectedValues[i+1]=='todos'">Todos</p>
                <p *ngFor="let value of char.value" class="filter-option smaller-filter"
                    (click)="setFilter(i+1,value.name ? value.name: value)"
                    [class.selected-category]="selectedValues[i+1]==(value.name ? value.name: value)">{{value.name ?
                    value.name: value}}</p>
            </div>
        </div>
    </div>
    <div class="shop-display">
        <div class="header">
            <div class="title">
                <p class="front-text">{{categoryForFilter | titlecase}}</p>
                <!-- <p class="bg-text">{{categoryForFilter}}</p> -->
                <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
            </div>
            <div class="filters">
                <input type="text" class="searchbar" placeholder='“Samsung Galaxy A12“'
                    (keyup)="setNameFilter($event.target.value)">
                <i class="fas fa-search"></i>
                <select name="categories" id="categories" class="category-filter"
                    (change)="setProductOrder($event.target.value)">
                    <option value="none" selected disabled>Ordenar Por</option>
                    <option value="priceAsc">Precio Ascendiente</option>
                    <option value="priceDesc">Precio Descendiente</option>
                    <option value="alfaAsc">Alfabético Ascendiente</option>
                    <option value="alfaDesc">Alfabético Descendiente</option>
                </select>
            </div>
        </div>
        <div class="products">
            <ng-container>
                <div class="product" [routerLink]="['/producto',product.id]" *ngFor="let product of productsFiltered">
                    <div class="product-img">
                        <img *ngIf="product.subproducts" [src]="product.subproducts[0].images[0]">
                    </div>
                    <div class="tags">
                        <div class="new" *ngIf="product.subproducts[0].isNew">
                            <img src="assets/Icons/Star.svg" style="height: 50%;">LANZAMIENTO
                        </div>
                        <div class="discount" *ngIf="product.subproducts[0].discount > 0">
                            <img src="assets/Icons/Discount.svg" style="height: 50%;"><span
                                style="color:#4273DF; font-weight: 700;">{{((product.subproducts[0].price -
                                product.subproducts[0].discount)*100)/product.subproducts[0].price | number:
                                '1.1-1'}}%</span> DESCUENTO
                        </div>
                        <div class="sold-out" *ngIf="product.totalStock<=0">
                            <img src="assets/Icons/sold-out.svg" style="height: 50%;">AGOTADO
                        </div>
                    </div>
                    <p class="product-category">{{product.category}}</p>
                    <p class="product-name">{{product.name}}</p>
                    <p class="product-price" *ngIf="product.subproducts[0].discount">{{product.subproducts[0].discount |
                        currency}} <span class="product-discounted-price">{{product.subproducts[0].price |
                            currency}}</span></p>
                    <!--                     <p class="product-price" *ngIf="product.subproducts[0].discount">{{product.subproducts[0].price - product.subproducts[0].price*product.subproducts[0].discount/100 | currency}} <span class="product-discounted-price">{{product.subproducts[0].price | currency}}</span></p>-->
                    <p class="product-price" *ngIf="!product.subproducts[0].discount">{{product.subproducts[0].price |
                        currency}}</p>
                    <img src="../../../assets/Icons/wishlist-on.png"
                        (click)="removeFromWishlist(product.id); $event.stopPropagation();" class="wishlist"
                        *ngIf="currentUser && currentUser.wishlist && userWishlist.includes(product.id)">
                    <img src="../../../assets/Icons/wishlist-off.png"
                        (click)="addToWishlist(product.id); $event.stopPropagation();" class="wishlist"
                        *ngIf="currentUser && (!currentUser.wishlist || !userWishlist.includes(product.id))">
                </div>
            </ng-container>
        </div>
    </div>
</div>
<footer-inverted></footer-inverted>