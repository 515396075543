import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-campaigns',
  templateUrl: './admin-campaigns.component.html',
  styleUrls: ['./admin-campaigns.component.scss']
})
export class AdminCampaignsComponent implements OnInit {

  source = ""
  medium = ""
  campaign = ""
  product = ""

  constructor() { }

  ngOnInit(): void {
  }

}
