import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errorText
  email
  password

  constructor(public fireAuth : AngularFireAuth, private router: Router, public authService: AuthService, public userService: UserService) { }

  ngOnInit(): void {
    this.fireAuth.currentUser.then(user =>{
      if(user){
        this.userService.getUserById(user.uid).subscribe((res:any) => {
          if(res.isAdmin==false){
            this.router.navigate(['/mi-cuenta'])
          }else{
            this.router.navigate(['/admin/'])
          }
        })
      }
    })
  }

  login(){
    this.fireAuth.signInWithEmailAndPassword(this.email,this.password).then(user => {
      var uid = user.user.uid
      this.userService.getUserRecord(uid).then(data => {
        if(data.isAdmin==true){
          this.router.navigate(['/admin'])
        }else {
          this.router.navigate(['/mi-cuenta'])
        }
      })
    }).catch(err => {
      if(err.code == "auth/invalid-email" || err.code == "auth/wrong-password") this.errorText = "El correo o la contraseña ingresados no son válidos."
      if(err.code == "auth/argument-error") this.errorText = "Por favor ingrese un correo y contraseña"
      console.log(err)
    })
  }

  loginWithGoogle(){
    this.authService.googleSignIn().then(exists => {
      if(exists){
        this.router.navigate(['/mi-cuenta'])
      }else{
        alert('Cuenta no encontrada. Favor de registrarse antes de iniciar sesión.')
      }
    })
  }

  loginWithFacebook(){
    this.authService.facebookSignIn().then(exists => {
      if(exists){
        this.router.navigate(['/mi-cuenta'])
      }else{
        alert('Cuenta no encontrada. Favor de registrarse antes de iniciar sesión.')
      }
    })
  }

  resetPassword(){
    this.fireAuth.sendPasswordResetEmail(this.email).then(() => {
      alert('Se ha enviado un correo de restablecimiento a tu cuenta.')
    },err => {
      if(err.code == "auth/invalid-email"){
        this.errorText = "El correo o la contraseña ingresados no son válidos."
      }else if(err.code == "auth/user-not-found"){
        this.errorText = "No se encontró un usuario con ese correo."
      }
    })
  }

}
