<div class="wrapper">
    <div class="sidebar">
        <!-- <img src="../../../assets/Logos/Gm-navbar-logo.png" alt="" class="sidebar-logo"> -->
        <p class="admin-tab" routerLink="/admin-productos" routerLinkActive="selected-tab">Productos</p>
        <p class="admin-tab" routerLink="/admin-caracteristicas" routerLinkActive="selected-tab">Características</p>
        <p class="admin-tab" routerLink="/admin-pedidos" routerLinkActive="selected-tab">Pedidos</p>
        <p class="admin-tab" routerLink="/admin-perfiles" routerLinkActive="selected-tab">Perfiles</p>
        <p class="admin-tab" routerLink="/admin-cupones" routerLinkActive="selected-tab">Cupones</p>
        <p class="admin-tab" routerLink="/admin-slider" routerLinkActive="selected-tab">Slider</p>
        <p class="admin-tab" routerLink="/admin-campañas" routerLinkActive="selected-tab">Campañas</p>
    </div>
    <div class="content">
        <div class="header">
            <p>Características</p>
        </div>
        <div class="content-body">
            <p class="section-title">Añadir una característica</p>
            <label>Selecciona una característica</label>
            <select [(ngModel)]="selectedCharIndex" (change)="changeChar()">
                <option value="-1">Nueva</option>
                <option *ngFor="let char of chars; let i = index" [value]="i">{{char.name}}</option>
            </select>
            <form ngNativeValidate (ngSubmit)="addChar()" class="new-char-inputs" *ngIf="selectedChar.name==''">
                <label>Nombre</label>
                <input type="text" [(ngModel)]="newCharName" name="name" required>
                <label>Es variable</label>
                <select [(ngModel)]="newCharVariable" name="variable" required>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                </select>
                <button class="primary-btn" type="submit">Añadir</button>
            </form>
            <div class="edit-char-inputs" *ngIf="selectedChar.name=='Color'">
                <form ngNativeValidate (ngSubmit)="addColor()">
                    <label>Nombre</label>
                    <input type="text" [(ngModel)]="addColorName" name="name">
                    <label>Código Hex</label>
                    <input type="text" [(ngModel)]="addColorCode" name="code">
                    <button class="primary-btn" type="submit">Agregar Color</button>
                </form>
                <div class="table" *ngIf="selectedChar.value && selectedChar.value.length>0">
                    <div class="color" *ngFor="let value of selectedChar.value; let i = index"><div [style]='"background-color:"+value.code'></div>{{value.name}}<i class="fa fa-times" (click)="removeValue(i)"></i></div>
                </div>
            </div>

            <div class="edit-char-inputs" *ngIf="selectedChar.name!='Color' && selectedChar.name!=''">
                <form ngNativeValidate (ngSubmit)="editChar()">
                    <label>Nombre</label>
                <input type="text" [(ngModel)]="editCharName" name="name">
                <label>Es variable</label>
                <select [(ngModel)]="editCharVariable" name="variable">
                    <option value="true">Si</option>
                    <option value="false">No</option>
                </select>
                <div class="buttons">
                    <button class="danger-btn" (click)="removeChar()">Borrar</button>
                    <button class="secondary-btn" type="submit">Guardar</button>
                </div>
                </form>
                <form ngNativeValidate (ngSubmit)="addValue()">
                    <label>Valor</label>
                    <input type="text" [(ngModel)]="editCharValue" name="value">
                    <button class="primary-btn" type="submit">Agregar Valor</button>
                </form>
                <div class="table" *ngIf="selectedChar.value && selectedChar.value.length>0">
                    <div *ngFor="let value of selectedChar.value;let i = index"><p>{{value}}</p><i class="fa fa-times" (click)="removeValue(i)"></i></div>
                </div>
            </div>
        </div>
    </div>
</div>