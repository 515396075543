import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() isOn;
  @Input() text;
  constructor() { }
  ngOnInit(): void {
    /* console.log(this.isOn) */
  }
}
