import { Component, OnInit } from '@angular/core';
import { ShippingService } from 'src/app/services/shipping.service';
import { NgxSpinnerService } from "ngx-spinner";
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  orderID
  orderData

  constructor(public shippingService: ShippingService,
    public spinner: NgxSpinnerService,
    public fns: AngularFireFunctions) { }

  ngOnInit(): void {
  }

  trackOrder() {
    /* this.fns.useEmulator("localhost", 5001); */
    this.spinner.show()
    let trackDHLOrder = this.fns.httpsCallable('trackOrder')
    trackDHLOrder({ trackingNumber: this.orderID }).subscribe((res) => {
      console.log(res);
      this.orderData = res.shipments[0]
      if(this.orderData.shipmentTimestamp)this.orderData.shipmentTimestamp = this.orderData.shipmentTimestamp.split('T')[0];
      this.orderID = ""
      this.spinner.hide()
    }, error => {
      console.log(error);
      this.spinner.hide()
    })
    /* this.shippingService.trackOrder(this.orderID).subscribe((res: any) => {
      this.spinner.hide()
      this.orderData = res.data
      this.orderID = ""
    }) */
  }

}
