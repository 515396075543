<navbar></navbar>
<div class="wrapper" *ngIf="product">
    <div class="product-details">
        <img [src]="product.subproducts[0].images[0]">
        <p>{{product.name}}</p>
    </div>
    <label>Calificación</label>
    <ng-template #t let-fill="fill">
        <span class="star" [class.full]="fill === 100">
          <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
        </span>
      </ng-template>
      <div class="rating-container">
        <ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="false" [max]="5"></ngb-rating>
      </div>
      
    <label for="orderID">Escribe tu reseña</label>
    <textarea type="text" name="orderID" id="orderID" [(ngModel)]="review"></textarea>
    <button class="primary-btn" (click)="createReview()">Calificar</button>
</div>