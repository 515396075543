<navbar></navbar>
<div class="wrapper">
  <div class="return-bar">
    <p routerLink="/">
      <i class="fa fa-chevron-left"></i> &nbsp;Regresar a la tienda
    </p>
  </div>
  <div class="content" *ngIf="currentUser">
    <p class="logout-link" (click)="logout()">Cerrar Sesión</p>
    <div class="title">
      <p class="front-text">MIS DATOS</p>
    </div>
    <div class="info-section">
      <div class="info-container">
        <p class="info-title">Correo</p>
        <input *ngIf="currentUser" class="info-text" disabled type="text" name="user-email" id="email"
          placeholder="juanitoperez@gmail.com" [value]="currentUser.email" />
        <i class="fa fa-edit"></i>
      </div>
      <div class="info-container">
        <p class="info-title">Nombre</p>
        <input class="info-text" type="text" name="user-name" id="name" placeholder="Juanito" [(ngModel)]="userName" />
        <i class="fa fa-edit"></i>
      </div>
      <div class="info-container">
        <p class="info-title">Fecha de nacimiento</p>
        <input class="info-text" type="date" name="user-name" id="name" placeholder="24/08/2000"
          [(ngModel)]="userBirthdate" />
        <i class="fa fa-edit"></i>
      </div>
      <button class="primary-btn" (click)="saveUserData()">Guardar</button>
    </div>
    <!--     <div class="payment-section" *ngIf="currentUser.paymentMethods">
      <div class="title">
        <p class="front-text">Mis Métodos de Pago</p>
      </div>
      <div class="payment-methods">
        <div
          class="payment-method"
          *ngFor="let card of currentUser.paymentMethods; let i = index"
        >
          <img
            class="card-image"
            *ngIf="card.brand == 'visa'"
            src="/assets/Logos/visa-logo.png"
          />
          <img
            class="card-image"
            *ngIf="card.brand == 'mastercard'"
            src="/assets/Logos/mastercard-logo.png"
          />
          <p>{{ card.brand | uppercase }} {{ card.card_number }}</p>
          <p>{{ card.expiration_month }} / {{ card.expiration_year }}</p>
          <i class="fas fa-trash-alt" (click)="removeCard(i)"></i>
        </div>
      </div>
    </div> -->
    <div class="title">
      <p class="front-text">MIS direcciones</p>
    </div>
    <div class="address-section">
      <p class="link" (click)="displayAddressModal()">
        Agregar nueva dirección de entrega
      </p>
      <div class="address" *ngFor="let address of addressList; let i = index">
        <p class="address-name">
          {{ address.firstName }} {{ address.lastName }}
        </p>
        <p class="address-info">
          Dirección: {{ address.street }} #{{ address.extNumber }}, Colonia
          {{ address.suburb }}, CP {{ address.zipCode }}
        </p>
        <p class="address-info">
          Email: {{ address.email }} Teléfono: {{ address.phone }}
        </p>
        <div class="address-icons">
          <i class="fa fa-edit" (click)="displayEditAddress(address)"></i><i class="fa fa-times"
            (click)="deleteAddress(address.id)"></i>
        </div>
      </div>
    </div>
    <div class="title">
      <p class="front-text">MIS pedidos</p>
    </div>
    <p class="link" routerLink="/rastreo">Rastrea tu pedido</p>
    <div class="orders-section">
      <div class="order" *ngFor="let order of orderList">
        <img [src]="order.products[0].image" alt="product" class="product-img" />
        <div class="order-center">
          <p class="order-grey-text" *ngIf="order.status == 'Pagado'">
            Número de Rastreo: {{ order.tracker }}
          </p>
          <p class="order-grey-text" *ngIf="order.status != 'Pagado'">
            Orden {{ order.status | lowercase }}
          </p>
          <p class="order-text">
            {{ order.products[0].name }}<br />
            {{ order.street }} #{{ order.extNumber }}, Colonia
            {{ order.suburb }}, CP {{ order.zipCode }}
          </p>
        </div>
        <div class="order-right">
          <p class="link" (click)="displayDetail(order)">Ver detalle</p>
          <p class="order-grey-text">Pedido el: {{ order.date }}</p>
          <!-- <p class="order-status">Estado: <span class="status-yellow">En progreso</span></p> -->
          <p class="order-cost">{{ order.totalCost | currency }}</p>
        </div>
      </div>
      <!-- <div class="order">
                <img src="../../../assets/Placeholders/product-placeholder.png" alt="product" class="product-img">
                <div class="order-center">
                    <p class="order-grey-text">Pedido el: 12/09/2020, Entregado el 18/09/2020</p>
                    <p class="order-text">Samsung Galaxy A12, Rojo, 64GB<br>
                        Felix Cuevas #116, int 2, Colonia del valle, CP 03100</p>
                </div>
                <div class="order-right">
                    <p class="link">Califica el producto</p>
                    <p class="order-status">Estado: <span class="status-green">En progreso</span></p>
                    <p class="order-cost">$12,232.00</p>
                </div>
            </div> -->
    </div>
  </div>
</div>

<div class="order-modal" [class.show]="showDetail">
  <div class="modal-background" (click)="hideDetail()"></div>
  <div class="order-modal-content">
    <p class="order-title bold">Orden #{{ orderToShow.id }}</p>
    <i class="fa fa-times" (click)="hideDetail()"></i>
    <p class="status-text" *ngIf="orderData?.status == 'Success'">
      Envío Agendado
    </p>
    <p class="status-text" *ngIf="!orderData">{{ orderToShow.status }}</p>
    <p style="margin-bottom: 15px">
      <span class="bold">Fecha de creación de orden:</span>
      {{ orderToShow.date }}
    </p>
    <p class="order-subtitle">Resumen de la orden</p>
    <p class="order-text">Haz click en un producto para dejar una reseña</p>
    <div class="product" *ngFor="let product of orderToShow.products" style="cursor: pointer"
      [routerLink]="['/calificar', product.parentId]">
      <img [src]="product.image" />
      <div class="product-numbers">
        <p>{{ product.name }}</p>
        <p>x{{ product.quantity }}</p>
        <p class="bold">{{ product.price | currency }}</p>
      </div>
    </div>
    <div class="order-subtotal">
      <p class="bold">Subtotal</p>
      <p style="font-weight: 500">{{ orderToShow.subtotal | currency }}</p>
    </div>
    <div class="order-discount" *ngIf="orderToShow.discount > 0">
      <p class="bold">Descuento</p>
      <p class="bold">{{ discountCode }}</p>
      <p style="font-weight: 500">
        {{ orderToShow.subtotal - orderToShow.totalCost | currency }}
      </p>
    </div>
    <div class="order-discount" *ngIf="orderToShow.shippingCost">
      <p class="bold">Envío</p>
      <p style="font-weight: 500">{{ orderToShow.shippingCost | currency }}</p>
    </div>
    <div class="order-total">
      <p class="bold">Total</p>
      <p style="font-weight: 500">{{ orderToShow.totalCost | currency }}</p>
    </div>
    <p class="order-subtitle">Dirección</p>
    <p class="order-text">
      {{ orderToShow.firstName }} {{ orderToShow.lastName }}
    </p>
    <p class="order-text">
      {{ orderToShow.street }} #{{ orderToShow.extNumber }}, Colonia
      {{ orderToShow.suburb }}, CP {{ orderToShow.zipCode }}
    </p>
    <p class="order-subtitle" *ngIf="orderToShow.pdfRoute && orderToShow.payment_method=='Transferencia Bancaria'">
      Transferencia Bancaria
    </p>
    <p class="order-subtitle" *ngIf="orderToShow.pdfRoute && orderToShow.payment_method=='Pago en efectivo'">
      Pago en Efectivo
    </p>
    <a class="link" target="_blank" [href]="orderToShow.pdfRoute"
      *ngIf="orderToShow.pdfRoute && orderToShow.status=='Esperando Pago'">Consultar Recibo</a>
    <a *ngIf="orderToShow.transaction_id && orderToShow.openpay_id" class="link" target="_blank"
      [href]="'https://dashboard.openpay.mx/terminal/print-payment-conditions/m3bhmq0oerrym5ucu4ly/'+orderToShow.transaction_id+'/'+orderToShow.openpay_id">Consultar
      Pagaré</a>
    <!-- <p class="order-text" *ngIf="orderToShow.clabe"><b>CLABE:</b> {{ orderToShow.clabe }}</p>
    <p class="order-text" *ngIf="orderToShow.bankReference"><b>REFERENCIA:</b> {{ orderToShow.bankReference }}</p>
    <p class="order-text" *ngIf="orderToShow.bank"><b>BANCO:</b> {{ orderToShow.bank }}</p>
    <p class="order-text" *ngIf="orderToShow.clabe"><b>CANTIDAD:</b> {{ orderToShow.totalCost | currency }}</p> -->
  </div>
</div>

<div class="address-modal" [class.show]="showAddressModal">
  <div class="modal-background" (click)="hideAddressModal()"></div>
  <div class="modal-content">
    <p class="front-text">Agregar dirección</p>
    <i class="fa fa-times close-modal" (click)="hideAddressModal()"></i>
    <div class="add-address-inputs" [formGroup]="addressInfo">
      <div class="row">
        <div class="half-width">
          <div class="input">
            <label for="firstName" class="primary-label">Nombres</label>
            <input type="text" name="firstName" id="firstName" formControlName="firstName" minlength="2" maxlength="14"
              [class.error-outline]="
                addressInfo.get('firstName').invalid &&
                addressInfo.get('firstName').touched
              " />
            <p class="error-label" *ngIf="
                addressInfo.get('firstName').invalid &&
                addressInfo.get('firstName').touched
              ">
              Por favor ingresa un nombre válido. Máximo 14 caracteres.
            </p>
          </div>
        </div>
        <div class="half-width">
          <div class="input">
            <label for="lastName" class="primary-label">Apellidos</label>
            <input type="text" name="lastName" id="lastName" formControlName="lastName" minlength="2" maxlength="14"
              [class.error-outline]="
                addressInfo.get('lastName').invalid &&
                addressInfo.get('lastName').touched
              " />
            <p class="error-label" *ngIf="
                addressInfo.get('lastName').invalid &&
                addressInfo.get('lastName').touched
              ">
              Por favor ingresa un apellido válido. Máximo 14 caracteres.
            </p>
          </div>
        </div>
      </div>
      <label for="email" class="primary-label">Email</label>
      <input type="text" name="email" id="email" formControlName="email" [class.error-outline]="
          addressInfo.get('email').invalid && addressInfo.get('email').touched
        " />
      <p class="error-label" *ngIf="
          addressInfo.get('email').invalid && addressInfo.get('email').touched
        ">
        Por favor ingresa un correo válido.
      </p>
      <label for="phone" class="primary-label">Teléfono</label>
      <input type="text" name="phone" id="phone" formControlName="phone" minlength="10" maxlength="10"
        [class.error-outline]="
          addressInfo.get('phone').invalid && addressInfo.get('phone').touched
        " />
      <p class="error-label" *ngIf="
          addressInfo.get('phone').invalid && addressInfo.get('phone').touched
        ">
        Por favor ingresa un teléfono válido. Debe contener 10 caracteres.
      </p>
      <div class="row">
        <div class="half-width">
          <div class="input">
            <label for="street" class="primary-label">Calle</label>
            <input type="text" name="street" id="street" formControlName="street" minlength="2" maxlength="25"
              [class.error-outline]="
                addressInfo.get('street').invalid &&
                addressInfo.get('street').touched
              " />
            <p class="error-label" *ngIf="
                addressInfo.get('street').invalid &&
                addressInfo.get('street').touched
              ">
              Por favor ingresa una calle válida. Máximo 25 caracteres.
            </p>
          </div>
        </div>
        <div class="half-width">
          <div class="input">
            <label for="extNumber" class="primary-label">Número Exterior</label>
            <input type="text" name="extNumber" id="extNumber" formControlName="extNumber" minlength="2" maxlength="5"
              [class.error-outline]="
                addressInfo.get('extNumber').invalid &&
                addressInfo.get('extNumber').touched
              " />
            <p class="error-label" *ngIf="
                addressInfo.get('extNumber').invalid &&
                addressInfo.get('extNumber').touched
              ">
              Por favor ingresa un número exterior válido. Máximo 5 caracteres.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="half-width">
          <div class="input">
            <label for="suburb" class="primary-label">Colonia</label>
            <input type="text" name="suburb" id="suburb" formControlName="suburb" minlength="2" maxlength="30"
              [class.error-outline]="
                addressInfo.get('suburb').invalid &&
                addressInfo.get('suburb').touched
              " />
            <p class="error-label" *ngIf="
                addressInfo.get('suburb').invalid &&
                addressInfo.get('suburb').touched
              ">
              Por favor ingresa una colonia válida. Máximo 30 caracteres.
            </p>
          </div>
        </div>
        <div class="half-width">
          <div class="input">
            <label for="zipCode" class="primary-label">Código Postal</label>
            <input type="text" name="zipCode" id="zipCode" formControlName="zipCode" minlength="5" maxlength="5"
              [class.error-outline]="
                addressInfo.get('zipCode').invalid &&
                addressInfo.get('zipCode').touched
              " />
            <p class="error-label" *ngIf="
                addressInfo.get('zipCode').invalid &&
                addressInfo.get('zipCode').touched
              ">
              Por favor ingresa un código postal válido. Debe contener 5
              caracteres.
            </p>
          </div>
        </div>
      </div>
      <label for="crossStreet" class="primary-label">Entre calles</label>
      <input type="text" name="crossStreet" id="crossStreet" formControlName="crossStreet"
        placeholder="Calle 1 y Calle 2" minlength="2" maxlength="35" [class.error-outline]="
          addressInfo.get('crossStreet').invalid &&
          addressInfo.get('crossStreet').touched
        " />
      <p class="error-label" *ngIf="
          addressInfo.get('crossStreet').invalid &&
          addressInfo.get('crossStreet').touched
        ">
        Por favor ingresa un valor válido. Máximo 35 caracteres.
      </p>
      <label for="reference" class="primary-label">Referencia</label>
      <input type="text" name="reference" id="reference" formControlName="reference"
        placeholder="¿Cómo podemos identificar tu casa? Por favor incluye el número interior si existe" minlength="2"
        maxlength="25" [class.error-outline]="
          addressInfo.get('reference').invalid &&
          addressInfo.get('reference').touched
        " />
      <p class="error-label" *ngIf="
          addressInfo.get('reference').invalid &&
          addressInfo.get('reference').touched
        ">
        Por favor ingresa una referencia válida. Máximo 25 caracteres.
      </p>
      <button class="primary-btn" [class.disabled-btn]="!addressInfo.valid" (click)="saveAddress()">
        Guardar
      </button>
    </div>
  </div>
</div>

<div class="address-modal" [class.show]="showEditAddressModal">
  <div class="modal-background" (click)="hideEditAddressModal()"></div>
  <div class="modal-content">
    <p class="front-text">Editar dirección</p>
    <i class="fa fa-times close-modal" (click)="hideEditAddressModal()"></i>
    <div class="add-address-inputs" [formGroup]="addressEditInfo">
      <div class="row">
        <div class="half-width">
          <div class="input">
            <label for="firstName" class="primary-label">Nombres</label>
            <input type="text" name="firstName" id="firstName" formControlName="firstName" minlength="2" maxlength="14"
              [class.error-outline]="
                addressEditInfo.get('firstName').invalid &&
                addressEditInfo.get('firstName').touched
              " />
            <p class="error-label" *ngIf="
                addressEditInfo.get('firstName').invalid &&
                addressEditInfo.get('firstName').touched
              ">
              Por favor ingresa un nombre válido. Máximo 14 caracteres.
            </p>
          </div>
        </div>
        <div class="half-width">
          <div class="input">
            <label for="lastName" class="primary-label">Apellidos</label>
            <input type="text" name="lastName" id="lastName" formControlName="lastName" minlength="2" maxlength="14"
              [class.error-outline]="
                addressEditInfo.get('lastName').invalid &&
                addressEditInfo.get('lastName').touched
              " />
            <p class="error-label" *ngIf="
                addressEditInfo.get('lastName').invalid &&
                addressEditInfo.get('lastName').touched
              ">
              Por favor ingresa un apellido válido. Máximo 14 caracteres.
            </p>
          </div>
        </div>
      </div>
      <label for="email" class="primary-label">Email</label>
      <input type="text" name="email" id="email" formControlName="email" [class.error-outline]="
          addressEditInfo.get('email').invalid &&
          addressEditInfo.get('email').touched
        " />
      <p class="error-label" *ngIf="
          addressEditInfo.get('email').invalid &&
          addressEditInfo.get('email').touched
        ">
        Por favor ingresa un correo válido.
      </p>
      <label for="phone" class="primary-label">Teléfono</label>
      <input type="text" name="phone" id="phone" formControlName="phone" minlength="10" maxlength="10"
        [class.error-outline]="
          addressEditInfo.get('phone').invalid &&
          addressEditInfo.get('phone').touched
        " />
      <p class="error-label" *ngIf="
          addressEditInfo.get('phone').invalid &&
          addressEditInfo.get('phone').touched
        ">
        Por favor ingresa un teléfono válido. Debe contener 10 caracteres.
      </p>
      <div class="row">
        <div class="half-width">
          <div class="input">
            <label for="street" class="primary-label">Calle</label>
            <input type="text" name="street" id="street" formControlName="street" minlength="2" maxlength="25"
              [class.error-outline]="
                addressEditInfo.get('street').invalid &&
                addressEditInfo.get('street').touched
              " />
            <p class="error-label" *ngIf="
                addressEditInfo.get('street').invalid &&
                addressEditInfo.get('street').touched
              ">
              Por favor ingresa una calle válida. Máximo 25 caracteres.
            </p>
          </div>
        </div>
        <div class="half-width">
          <div class="input">
            <label for="extNumber" class="primary-label">Número Exterior</label>
            <input type="text" name="extNumber" id="extNumber" formControlName="extNumber" minlength="2" maxlength="5"
              [class.error-outline]="
                addressEditInfo.get('extNumber').invalid &&
                addressEditInfo.get('extNumber').touched
              " />
            <p class="error-label" *ngIf="
                addressEditInfo.get('extNumber').invalid &&
                addressEditInfo.get('extNumber').touched
              ">
              Por favor ingresa un número exterior válido. Máximo 5 caracteres.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="half-width">
          <div class="input">
            <label for="suburb" class="primary-label">Colonia</label>
            <input type="text" name="suburb" id="suburb" formControlName="suburb" minlength="2" maxlength="30"
              [class.error-outline]="
                addressEditInfo.get('suburb').invalid &&
                addressEditInfo.get('suburb').touched
              " />
            <p class="error-label" *ngIf="
                addressEditInfo.get('suburb').invalid &&
                addressEditInfo.get('suburb').touched
              ">
              Por favor ingresa una colonia válida. Máximo 30 caracteres.
            </p>
          </div>
        </div>
        <div class="half-width">
          <div class="input">
            <label for="zipCode" class="primary-label">Código Postal</label>
            <input type="text" name="zipCode" id="zipCode" formControlName="zipCode" minlength="5" maxlength="5"
              [class.error-outline]="
                addressEditInfo.get('zipCode').invalid &&
                addressEditInfo.get('zipCode').touched
              " />
            <p class="error-label" *ngIf="
                addressEditInfo.get('zipCode').invalid &&
                addressEditInfo.get('zipCode').touched
              ">
              Por favor ingresa un código postal válido. Debe contener 5
              caracteres.
            </p>
          </div>
        </div>
      </div>
      <label for="crossStreet" class="primary-label">Entre calles</label>
      <input type="text" name="crossStreet" id="crossStreet" formControlName="crossStreet"
        placeholder="Calle 1 y Calle 2" minlength="2" maxlength="35" [class.error-outline]="
          addressEditInfo.get('crossStreet').invalid &&
          addressEditInfo.get('crossStreet').touched
        " />
      <p class="error-label" *ngIf="
          addressEditInfo.get('crossStreet').invalid &&
          addressEditInfo.get('crossStreet').touched
        ">
        Por favor ingresa un valor válido. Máximo 35 caracteres.
      </p>
      <label for="reference" class="primary-label">Referencia</label>
      <input type="text" name="reference" id="reference" formControlName="reference"
        placeholder="¿Cómo podemos identificar tu casa? Por favor incluye el número interior si existe" minlength="2"
        maxlength="25" [class.error-outline]="
          addressEditInfo.get('reference').invalid &&
          addressEditInfo.get('reference').touched
        " />
      <p class="error-label" *ngIf="
          addressEditInfo.get('reference').invalid &&
          addressEditInfo.get('reference').touched
        ">
        Por favor ingresa una referencia válida. Máximo 25 caracteres.
      </p>
      <button class="primary-btn" [class.disabled-btn]="!addressEditInfo.valid" (click)="editAddress()">
        Guardar
      </button>
    </div>
  </div>
</div>

<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
  <p class="loading">Cargando Pedido...</p>
</ngx-spinner>