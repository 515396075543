import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CouponService } from '../../services/coupon.service'

@Component({
  selector: 'app-admin-coupons',
  templateUrl: './admin-coupons.component.html',
  styleUrls: ['./admin-coupons.component.scss']
})
export class AdminCouponsComponent implements OnInit {
  couponList

  couponData = new FormGroup({
    name: new FormControl(''),
    code: new FormControl(''),
    type: new FormControl(),
    amount: new FormControl(0,Validators.min(0))
  })

  constructor(public couponService: CouponService) { }

  ngOnInit(): void {
    this.couponService.getCoupons().subscribe(res => {
      this.couponList = res
    })
  }

  addCoupon(){
    let tempCoupon = {
      name: this.couponData.get('name').value,
      code: this.couponData.get('code').value,
      type: this.couponData.get('type').value,
      amount: this.couponData.get('amount').value,
    }
    if(tempCoupon.type=='envios')delete tempCoupon.amount
    this.couponService.addCoupon(tempCoupon)
    this.couponData.reset()
  }

  removeCoupon(id){
    this.couponService.removeCoupon(id)
  }

}
