import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  currentRate = 2
  products

  currentUser
  userWishlist = []
  uid

  wishlistProducts = []

  cart = {items: []}

  constructor(public userService: UserService,
    public firebaseAuthService: AuthService,
    public cartService:CartService,
    public productService: ProductService) { }

  ngOnInit(): void {
    this.firebaseAuthService.getCurrentUser().then((res) => {
      if (res != undefined) {
        this.uid = res.uid
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
          this.userWishlist = []
          for(let product of res.wishlist){
            this.userWishlist.push(product.id)
          }
          this.productService.getProducts().subscribe(res => {
            res = res.filter(product => product.subproducts?.length && product.isVisible)
            this.products = res
            this.wishlistProducts = []
            for(let wish of this.currentUser.wishlist){
              for(let product of this.products){
                if(product.id == wish.id){
                  let formatDate = this.transformDate(wish.date)
                  product.date = formatDate
                  product.rating = 0
                  if(product.reviews)product.rating = this.calculateRating(product)
                  this.wishlistProducts.push(product)
                }
              }
            }
          })
        })
      }
    });
    this.cartService.getTokens().subscribe((res) => {
      this.cart = res;
    })
    if(localStorage.getItem('cartGM')){
      this.cartService.setTokens(JSON.parse(localStorage.getItem('cartGM')));
    }else{
      localStorage.setItem('cartGM', JSON.stringify({items: []}))
      this.cartService.setTokens({items: []});
    }
  }

  findWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
  }

  transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: "numeric", month: "long", year: "numeric" };
    return d.toLocaleDateString("es-ES", options);
  }

  calculateRating(product){
    let tempSum = 0
    for(let review of product.reviews){
      tempSum+=review.rating
    }
    return (tempSum/product.reviews.length)
  }

  removeFromWishlist(id){
    let wishlist = []
    if(this.currentUser.wishlist){
      wishlist = this.currentUser.wishlist
      let index = this.findWithAttr(wishlist,'id',id)
      if(index != -1){
        wishlist.splice(index,1)
        this.userService.updateWishlist(this.uid,wishlist)
      }
    }
  }

  addToCart(product){
    let tempProduct = {
      parentId: product.id,
      price: product.subproducts[0].price - product.subproducts[0].price*product.subproducts[0].discount/100,
      discount: product.subproducts[0].discount,
      name: product.subproducts[0].name,
      quantity: 1,
      image: product.subproducts[0].images[0],
      chars: product.subproducts[0].chars,
      weight: product.subproducts[0].weight,
      index: 0,
      ProductCode: product.subproducts[0].ProductCode || '',
      UnitCode: product.subproducts[0].UnitCode || '',
      Unit: product.subproducts[0].Unit || ''
    }
    let productFound = false;
    for(let i of this.cart.items){
      if(i.name === tempProduct.name){
        i.quantity++;
        productFound = true;
      }
    }
    if(this.currentUser)this.userService.changeCart(this.currentUser.id,true)
    if(!productFound) this.cart.items.push(tempProduct);
    this.cartService.setTokens(this.cart);
    localStorage.setItem('cartGM', JSON.stringify(this.cart))
    alert('Producto agregado al carrito')
  }

}
