import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  generateInvoice(data){
    return this.http.post('https://api.facturama.mx/2/cfdis',data,{headers:{'Authorization': 'Basic R01lU2hvcDpndWVtb2ZhYzIwMjE='}})
  }

  mailInvoice(data){
    return this.http.post(`https://api.facturama.mx/cfdi?cfdiType=issued&cfdiId=${data.id}&email=${data.mail}`,{},{headers:{'Authorization': 'Basic R01lU2hvcDpndWVtb2ZhYzIwMjE='}})
  }

}
