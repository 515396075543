import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private addressList;
  private addressCollection: AngularFirestoreCollection

  constructor(private firestore: AngularFirestore) {
    this.addressCollection = this.firestore.collection('addresses');
    this.addressList = this.addressCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );  
   }

  addAddress(address){
    return this.addressCollection.add(address)
  }

  updateAddress(id, address){
    return this.addressCollection.doc(id).update(address);
  }

  getAddresses(){
    return this.addressList;
  }

  getAddressById(id){
    return this.addressCollection.doc(id).valueChanges();
  }

  getAddresssByUserId(uid){
    let addressCollection = this.firestore.collection<any>('addresses', ref => ref.where('uid','==',uid));
    let addressList = addressCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )
    return addressList
  }

  removeAddress(id){
    return this.addressCollection.doc(id).delete();
  }
}
