import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SendgridService {
  apiKey = "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"
  endpoint = "https://api.sendgrid.com/v3/"

  constructor(private http: HttpClient) { }

  addContact(email){
    return this.http.put(`${this.endpoint}marketing/contacts`,{contacts:[{email}]},{
      headers: { 'authorization': `Bearer ${this.apiKey}`, 'content-type': 'application/json'}
    })
  }
}
