import { Component, OnInit } from '@angular/core';
import Splide from '@splidejs/splide';
import { ProductService } from 'src/app/services/product.service';
import { SliderService } from 'src/app/services/slider.service';
import { NgxSpinnerService } from "ngx-spinner";
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  splider_main
  splider_categories
  splider_sponsors
  splider_new_products
  splider_new_products_2

  products = []

  smartphones
  computers
  smarthomes
  smartwatches
  headphones

  sliderImages =[]
  sliderTitles = []

  currentUser
  userWishlist = []
  uid

  private fragment: string;

  constructor(public productService: ProductService, 
    public sliderService: SliderService,
    public spinner: NgxSpinnerService,
    public userService: UserService,
    public firebaseAuthService: AuthService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    (function (d, s, id) { 
      var js; if (d.getElementById(id)) { return; } js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js); 
    }(document, "script", "EmbedSocialHashtagScript"));

    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });

    this.splider_categories = new Splide('#categories-slider', {
      type: 'loop',
      perPage: 2,
      pagination: false,
      classes: {
        prev: 'splide__arrow--prev prev-arrow-categories',
        next: 'splide__arrow--next next-arrow-categories',
        arrows: 'splide__arrows arrows-categories'
      }
    }).mount()

    this.firebaseAuthService.getCurrentUser().then((res) => {
      if (res != undefined) {
        this.uid = res.uid
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
          this.userWishlist = []
          for(let product of res.wishlist){
            this.userWishlist.push(product.id)
          }
        })
      }
    });
    this.spinner.show()
    this.sliderService.getSliderProperties().pipe(first()).subscribe((res:any) => {
      this.sliderImages = []
      this.sliderImages = res[0].images
      this.sliderTitles = res[1].titles
      setTimeout(() => {
        this.splider_main =  new Splide('#splide_main', {
          type: 'loop',
          perPage: 1,
          classes: {
            prev: 'splide__arrow--prev prev-arrow',
            next: 'splide__arrow--next next-arrow',
            page: 'splide__pagination__page slider-page',
            pagination: 'splide__pagination slider-page-container'
          },
          arrowPath: 'M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
        }).mount();
        this.spinner.hide()
      }, 500);
    })
    this.productService.getProducts().pipe(first()).subscribe((res : any) => {
      res = res.filter(product => product.subproducts?.length && product.isVisible)
      for (var i = res.length - 1; i >= 0; --i) {
        res[i].totalStock = res[i].subproducts.reduce((previous,current) => previous + current.stock,0)
        let colors = []
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }else{
          for(let product of res[i].subproducts){
            if(!colors.some(color => color.code == product.chars.Color.code)){
              colors.push(product.chars.Color)
            }
          }
        }
        res[i].colors = colors
      }
      this.products = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
      setTimeout(() => {
        try{
          this.splider_new_products =  new Splide('#splide_new_products', {
            type: 'loop',
            classes: {
              prev: 'splide__arrow--prev prev-arrow-new',
              next: 'splide__arrow--next next-arrow-new',
            },
            perPage: 4,
            drag: false,
            pagination: false,
            breakpoints: {
              1000: {
                perPage: 1,
              }
            }
          }).mount();
        }catch(error){
        }
      }, 500);
    })
    this.productService.getProductsByCategory('Smartphones').pipe(first()).subscribe(res => {
      res = res.filter(product => product.subproducts?.length && product.isVisible)
      for (var i = res.length - 1; i >= 0; --i) {
        res[i].totalStock = res[i].subproducts.reduce((previous,current) => previous + current.stock,0)
        let colors = []
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }else{
          for(let product of res[i].subproducts){
            if(!colors.some(color => color.code == product.chars.Color.code)){
              colors.push(product.chars.Color)
            }
          }
        }
        res[i].colors = colors  
      }
      this.smartphones = res.sort((a,b)=>{
        if(a.date>b.date)return 1
        return -1
      })
    })
    this.productService.getProductsByCategory('Computación').pipe(first()).subscribe(res => {
      res = res.filter(product => product.subproducts?.length && product.isVisible)
      for (var i = res.length - 1; i >= 0; --i) {
        res[i].totalStock = res[i].subproducts.reduce((previous,current) => previous + current.stock,0)
        let colors = []
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }else{
          for(let product of res[i].subproducts){
            if(!colors.some(color => color.code == product.chars.Color.code)){
              colors.push(product.chars.Color)
            }
          }
        }
        res[i].colors = colors
      }
      this.computers = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
    })
    this.productService.getProductsByCategory('Smart Home').pipe(first()).subscribe(res => {
      res = res.filter(product => product.subproducts?.length && product.isVisible)
      for (var i = res.length - 1; i >= 0; --i) {
        res[i].totalStock = res[i].subproducts.reduce((previous,current) => previous + current.stock,0)
        let colors = []
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }else{
          for(let product of res[i].subproducts){
            if(!colors.some(color => color.code == product.chars.Color.code)){
              colors.push(product.chars.Color)
            }
          }
        }
        res[i].colors = colors
      }
      this.smarthomes = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
    })
    this.productService.getProductsByCategory('Smartwatch').pipe(first()).subscribe(res => {
      res = res.filter(product => product.subproducts?.length && product.isVisible)
      for (var i = res.length - 1; i >= 0; --i) {
        res[i].totalStock = res[i].subproducts.reduce((previous,current) => previous + current.stock,0)
        let colors = []
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }else{
          for(let product of res[i].subproducts){
            if(!colors.some(color => color.code == product.chars.Color.code)){
              colors.push(product.chars.Color)
            }
          }
        }
        res[i].colors = colors
      }
      this.smartwatches = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
    })
    this.productService.getProductsByCategory('Audífonos').pipe(first()).subscribe(res => {
      res = res.filter(product => product.subproducts?.length && product.isVisible)
      for (var i = res.length - 1; i >= 0; --i) {
        res[i].totalStock = res[i].subproducts.reduce((previous,current) => previous + current.stock,0)
        let colors = []
        if (!res[i].subproducts.length) {
          res.splice(i,1);
        }else{
          for(let product of res[i].subproducts){
            if(!colors.some(color => color.code == product.chars.Color.code)){
              colors.push(product.chars.Color)
            }
          }
        }
        res[i].colors = colors
      }
      this.headphones = res.sort((a,b)=>{
        if(a.date<b.date)return 1
        return -1
      })
      setTimeout(() => {
        try{
          this.splider_new_products_2 =  new Splide('#splide_new_products_2', {
            type: 'loop',
            drag: false,
            pagination: false,
            classes: {
              prev: 'splide__arrow--prev prev-arrow-new',
              next: 'splide__arrow--next next-arrow-new',
            },
            perPage: 4,
            breakpoints: {
              1000: {
                perPage: 1,
              }
            }
          }).mount();
        }catch(error){

        }
        
      }, 500);
    })
    this.splider_sponsors =  new Splide('#splide_sponsors', {
      type: 'loop',
      perPage: 4,
      arrows: false,
      pagination: false,
      autoplay: true,
      interval: 3000,
      speed: 8000,
      drag: false,
      breakpoints: {
        800: {
          perPage: 2
        },
        500: {
          perPage: 1
        }
      }
    }).mount();
    
  }

  ngAfterViewInit(): void {
      setTimeout(() => {
        try {
        document.querySelector('#' + this.fragment).scrollIntoView();
      } catch (e) { }
      }, 500);
  }

  addToWishlist(id){
    let wishlist = []
    if(this.currentUser.wishlist){
      wishlist = this.currentUser.wishlist
    }
    wishlist.push({id:id,date:new Date()})
    this.userService.updateWishlist(this.uid,wishlist)
  }

  removeFromWishlist(id){
    let wishlist = []
    if(this.currentUser.wishlist){
      wishlist = this.currentUser.wishlist
      let index = this.findWithAttr(wishlist,'id',id)
      if(index != -1){
        wishlist.splice(index,1)
        this.userService.updateWishlist(this.uid,wishlist)
      }
    }
  }

  findWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
  }
  
}
