import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShippingService } from 'src/app/services/shipping.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { first } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'

declare let OpenPay;

@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.scss'],
})
export class AdminOrdersComponent implements OnInit {
  orders = [];
  ordersFiltered = [];
  filterBy: string = '0'
  searchWord = ''

  ordersPending = []
  orderData: any = {};

  showDetail = false;
  orderToShow: any = {};

  startDate;
  endDate;

  chosenCarrier;
  createdOrderData;

  tempPdf: SafeUrl = '';

  pickupDate: Date = new Date()
  isPickupScheduled = false

  isShippingModalVisible = false
  shippingBoxes = [
    {
      weight: "5",
      length: "5",
      height: "5",
      width: "5"
    }
  ]
  shippingOrder: any = {}
  shippingPrice = 0

  constructor(
    public orderService: OrderService,
    public spinner: NgxSpinnerService,
    public shippingService: ShippingService,
    private fns: AngularFireFunctions,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    /* OpenPay.setId('m3bhmq0oerrym5ucu4ly');
    OpenPay.setApiKey('pk_0a10d68d69594d3cb85c6660fb93c366');
    OpenPay.setSandboxMode(false); */
    /* this.fns.useEmulator("localhost", 5001); */
    this.endDate = this.getStringFromDate(new Date());
    this.orderService.getOrders().subscribe((res) => {
      res.sort((a, b) => {
        if (a.date < b.date) return 1;
        return -1;
      });
      for (let order of res) {
        order.originalDate = order.date;
        order.date = this.transformDate(order.date);
      }
      this.orders = res;
      this.ordersFiltered = res;
      this.ordersPending = res.filter(order => order.status == 'Esperando Pago' && order.transaction_id && order.openpay_id)
      console.log(this.ordersPending);
      this.ordersPending.forEach(order => {
        this.orderService.getOrderStatus(order.openpay_id, order.transaction_id).then((res: any) => {
          console.log(res);
          if (res.status == 'completed') {
            this.orderService.updateOrder(order.id, { status: 'Esperando envío' })
          } else if (res.status == 'failed') {
            this.orderService.updateOrder(order.id, { status: 'Fallida' })
          }
        })
      })
    });
    this.shippingService.getPickupDate().subscribe((res: any) => {
      this.pickupDate = new Date(res.date.seconds * 1000)
      if (this.pickupDate > new Date()) return this.isPickupScheduled = true
      this.isPickupScheduled = false
    })
  }

  transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: 'numeric', month: 'long', year: 'numeric' };
    return d.toLocaleDateString('es-ES', options);
  }
  filterOrders() {
    this.ordersFiltered = this.orders
    switch (this.filterBy) {
      case '0':
        break
      case '1':
        this.ordersFiltered = this.orders.filter((order) => order.status == 'Esperando envío')
        break
      case '2':
        this.ordersFiltered = this.orders.filter((order) => order.status == 'Esperando Pago')
        break
      case '3':
        this.ordersFiltered = this.orders.filter((order) => order.status == 'Fallida')
        break
      case '4':
        this.ordersFiltered = this.orders.filter((order) => order.status == 'Pagado')
        break
    }

    if (this.searchWord == '') return
    console.log(this.searchWord);
    this.ordersFiltered = this.ordersFiltered.filter((order) =>
      order.firstName.toLowerCase().includes(this.searchWord.toLowerCase()) ||
      order.lastName.toLowerCase().includes(this.searchWord.toLowerCase()) ||
      order.id.toLowerCase().includes(this.searchWord.toLowerCase()) ||
      order.orderId.toString().includes(this.searchWord) ||
      order.email.toLowerCase().includes(this.searchWord.toLowerCase())
    );
  }


  displayDetail(order) {
    this.orderToShow = order;
    this.spinner.show();
    const byteArray = new Uint8Array(
      atob(this.orderToShow.guide)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const guideBlob = new Blob([byteArray], { type: 'application/pdf' });
    var url = window.URL || window.webkitURL;
    const tempLink = url.createObjectURL(guideBlob);
    this.tempPdf = this.sanitizer.bypassSecurityTrustUrl(tempLink)
    if (order.orderId) {
      this.shippingService.trackOrder(order.orderId).subscribe((res: any) => {
        this.showDetail = true;
        this.spinner.hide();
        this.orderData = res.data;
      }, error => {
        this.showDetail = true;
        this.spinner.hide();
        this.orderData = {}
      });
    } else {
      this.showDetail = true;
      this.spinner.hide();
      this.orderData = {};
    }
  }

  hideDetail() {
    this.showDetail = false;
  }

  filterOrdersByDate() {
    let startDate = this.startDate;
    let endDate = this.endDate;
    this.ordersFiltered = this.orders.filter((order) => {
      let orderDate = this.getStringFromDate(order.originalDate.toDate());
      if (!endDate) endDate = this.getStringFromDate(new Date());
      if (startDate) return orderDate >= startDate && orderDate <= endDate;
      return orderDate <= endDate;
    });
  }

  getStringFromDate(date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }

  generateReport() {
    let tempCSV =
      'Fecha,ID de Pedido,Nombre del Cliente,Edad,Modelo,Color,Costo($),Precio,Cantidad (Producto),Descuento (Producto),Empaque($),Comision($),Envio($),Total Depositado (Producto), Descuento (Orden),Utilidad,Lugar de la Compra, # de guia, Courier\r\n';
    let temp;

    for (let order of this.ordersFiltered) {
      for (let product of order.products) {
        temp = '';
        temp = `${order.date},${order.id},${order.firstName} ${order.lastName
          },${order.age || 0},${product.name},${product.chars.Color.name},,${product.price
          },${product.quantity},${product.discount},,,${order.shippingCost},${product.price * product.quantity - product.discount
          },${order.discount},,Mexico ${order.suburb} C.P.${order.zipCode},${order.orderId
          },${order.carrier || 'Por Decidir'}\r\n`;
        tempCSV += temp;
      }
    }

    var blob = new Blob([tempCSV], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = url;
    a.download = 'Reporte de ventas.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  calculateShipping() {
    this.spinner.show('priceSpinner');
    let order = this.shippingOrder
    let tempDate = new Date();
    tempDate.setDate(tempDate.getDate() + 1);
    tempDate.setMonth(tempDate.getMonth() + 1);
    let year = tempDate.getFullYear();
    let month = tempDate.getMonth().toString();
    let day = tempDate.getDate().toString();
    if (parseInt(month) < 10) month = '0' + month;
    if (parseInt(day) < 10) day = '0' + day;
    let tempRequest = {
      shippingDate: `${year}-${month}-${day}`,
      destinationPostalCode: order.zipCode,
      destinationCity: order.city,
      packages: []
    };
    this.shippingBoxes.forEach((box) => {
      tempRequest.packages.push({ dimensions: { height: parseInt(box.height), length: parseInt(box.length), width: parseInt(box.width) }, weight: parseInt(box.weight) })
    })
    let getShippingRates = this.fns.httpsCallable('getShippingRatesMultiple')
    getShippingRates(tempRequest).subscribe((res) => {
      console.log(res.products[0].totalPrice[0].price);
      this.shippingPrice = res.products[0].totalPrice[0].price
      this.spinner.hide('priceSpinner');
    }, error => {
      alert('Error al calcular el precio.')
      this.spinner.hide('priceSpinner');
    })
  }

  createGuide() {
    let order = this.shippingOrder
    this.spinner.show('shippingSpinner');
    let tempDate = new Date();
    tempDate.setDate(tempDate.getDate() + 1);
    tempDate.setMonth(tempDate.getMonth() + 1);
    let year = tempDate.getFullYear();
    let month = tempDate.getMonth().toString();
    let day = tempDate.getDate().toString();
    if (parseInt(month) < 10) month = '0' + month;
    if (parseInt(day) < 10) day = '0' + day;
    let tempRequest = {
      shippingDate: `${year}-${month}-${day}`,
      customerZipCode: order.zipCode,
      customerCity: order.city,
      customerAddress: `${order.street} #${order.extNumber} col. ${order.suburb}`,
      customerEmail: order.email,
      customerPhone: order.phone,
      customerName: `${order.firstName} ${order.lastName}`,
      value: order.totalValue,
      packages: []
    };
    this.shippingBoxes.forEach((box) => {
      tempRequest.packages.push({ dimensions: { height: parseInt(box.height), length: parseInt(box.length), width: parseInt(box.width) }, weight: parseInt(box.weight) })
    })
    this.shippingPrice = 0
    let createShippingDHLRequest = this.fns.httpsCallable('createShipment');
    createShippingDHLRequest(tempRequest).subscribe(
      (res) => {
        if (!res) return this.spinner.hide('shippingSpinner');
        console.log(res);
        this.orderService.updateOrder(order.id, {
          guide: res.documents[0].content,
          tracker: res.shipmentTrackingNumber,
          status: 'Pagado',
        }).then((res) => {
          this.spinner.hide('shippingSpinner');
          this.hideShippingModal()
        });
      },
      (error) => {
        window.alert(
          'Error al calcular envío, por favor intente de nuevo más tarde'
        );
        var mobile =
          /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
            navigator.userAgent.toLowerCase()
          );
        if (mobile)
          alert(
            'Error al calcular envío, por favor intente de nuevo más tarde'
          );
        this.spinner.hide('shippingSpinner');
        this.hideShippingModal()
      }
    );
  }

  async schedulePickup() {
    let order = this.ordersFiltered[0]
    console.log(order);
    let totalHeight = 17,
      totalWidth = 20,
      totalLength = 20;
    if (order.totalSize > 5) {
      totalHeight = 16;
      totalWidth = 16;
      totalLength = 25;
    }
    this.spinner.show('shippingSpinner');
    let tempDate = new Date();
    tempDate.setDate(tempDate.getDate() + 1);
    tempDate.setMonth(tempDate.getMonth() + 1);
    let year = tempDate.getFullYear();
    let month = tempDate.getMonth().toString();
    let day = tempDate.getDate().toString();
    if (parseInt(month) < 10) month = '0' + month;
    if (parseInt(day) < 10) day = '0' + day;
    let tempRequest = {
      shippingDate: `${year}-${month}-${day}`,
      customerZipCode: order.zipCode,
      customerCity: order.city,
      customerAddress: `${order.street} #${order.extNumber} col. ${order.suburb}`,
      customerEmail: order.email,
      customerPhone: order.phone,
      customerName: `${order.firstName} ${order.lastName}`,
      value: order.totalValue,
      weight: order.totalWeight,
      height: totalHeight,
      width: totalWidth,
      length: totalLength,
    };
    let createDHLPickup = this.fns.httpsCallable('createPickup')
    createDHLPickup(tempRequest).subscribe(async (res) => {
      console.log(res)
      let newPickupDate = new Date();
      newPickupDate.setDate(newPickupDate.getDate() + 1);
      newPickupDate.setHours(0, 0, 0);
      await this.shippingService.setPickupDate(newPickupDate)
      alert('Envío agendado exitosamente.')
      this.spinner.hide('shippingSpinner')
    }, error => {
      console.log(error);
      this.spinner.hide('shippingSpinner')
    })
  }

  hideShippingModal() {
    this.isShippingModalVisible = false
    this.shippingOrder = {}
  }

  showShippingModal(order) {
    this.isShippingModalVisible = true
    this.shippingBoxes = [
      {
        weight: "0",
        length: "0",
        height: "0",
        width: "0"
      }]
    this.shippingOrder = order
  }

  addBox() {
    this.shippingBoxes.push({ length: "0", width: "0", height: "0", weight: "0" })
    this.shippingPrice = 0
  }

  deleteOrder(order_id) {
    this.showDetail = false
    this.orderService.deleteOrder(order_id).then((res) => {
      alert('Order borrada.')
      this.orderToShow = {}
    })
  }

}

/* createShippingRequest(order) {
    let totalHeight = 17,
      totalWidth = 20,
      totalLength = 20;
    if (order.totalSize > 5) {
      totalHeight = 16;
      totalWidth = 16;
      totalLength = 25;
    }
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var date = `${yyyy}-${mm}-${dd}`;

    let tempReference = 'Pedido GM';

    const request = {
      idRate: this.chosenCarrier.idRate,
      myShipmentReference: tempReference,
      requestPickup: true,
      pickupDate: date,
      insurance: false,
      thermalLabel: false,
      package: {
        description: 'Shipment',
        contentValue: order.totalValue,
        weight: order.totalWeight,
        height: totalHeight,
        width: totalWidth,
        length: totalLength,
      },
      origin: {
        firstName: 'Leonardo',
        lastName: 'Guerrero',
        email: 'leo.guemtz@gmail.com',
        phone: '5534712452',
        street: 'Coscomate',
        number: '71',
        suburb: 'Toriello Guerra',
        crossStreet: 'NA',
        reference: 'NA',
        zipCode: '14050',
      },
      destination: {
        firstName: order.firstName,
        lastName: order.lastName,
        email: order.email,
        phone: order.phone,
        street: order.street,
        number: order.extNumber,
        suburb: order.suburb,
        crossStreet: order.crossStreet,
        reference: order.reference,
        zipCode: order.zipCode,
      },
    };
    return new Promise((resolve, reject) => {
      this.shippingService.createShippingOrder(request).subscribe((res) => {
        this.createdOrderData = res;
        resolve(res);
      });
    });
  } */
