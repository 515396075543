<div class="wrapper">
    <div class="sidebar">
        <!-- <img src="../../../assets/Logos/Gm-navbar-logo.png" alt="" class="sidebar-logo"> -->
        <p class="admin-tab" routerLink="/admin-productos" routerLinkActive="selected-tab">Productos</p>
        <p class="admin-tab" routerLink="/admin-caracteristicas" routerLinkActive="selected-tab">Características</p>
        <p class="admin-tab" routerLink="/admin-pedidos" routerLinkActive="selected-tab">Pedidos</p>
        <p class="admin-tab" routerLink="/admin-perfiles" routerLinkActive="selected-tab">Perfiles</p>
        <p class="admin-tab" routerLink="/admin-cupones" routerLinkActive="selected-tab">Cupones</p>
        <p class="admin-tab" routerLink="/admin-slider" routerLinkActive="selected-tab">Slider</p>
        <p class="admin-tab" routerLink="/admin-campañas" routerLinkActive="selected-tab">Campañas</p>
    </div>
    <div class="content">
        <div class="header">
            <p>Ordenes y Pedidos</p>
            <div class="filters">
                <div class="input">
                    <!-- <label for="">Filtrar Pedidos</label> -->
                    <select name="" id="" [(ngModel)]="filterBy" (change)="filterOrders()">
                        <option value="0">Todos</option>
                        <option value="1">Esperando Envío</option>
                        <option value="2">Esperando Pago</option>
                        <option value="3">Fallidos</option>
                        <option value="4">Completados</option>
                    </select>
                </div>
                <div class="search-bar">
                    <input type="text" name="search" id="search" [(ngModel)]="searchWord" (keyup)="filterOrders()">
                    <i class="fa fa-search"></i>
                </div>
            </div>
        </div>
        <div class="report-filters">
            <div class="input">
                <label for="">Desde:</label>
                <input type="date" name="" id="" class="date-input" [(ngModel)]="startDate"
                    (change)="filterOrdersByDate()">
            </div>
            <div class="input">
                <label for="">Hasta:</label>
                <input type="date" name="" id="" class="date-input" [(ngModel)]="endDate"
                    (change)="filterOrdersByDate()">
            </div>
            <button class="primary-btn" (click)="generateReport()">Generar Reporte</button>
            <button class="primary-btn" (click)="schedulePickup()" *ngIf="!isPickupScheduled">Agendar Envío
                Mañana</button>
            <button class="primary-btn locked-btn" *ngIf="isPickupScheduled">Envío Agendado</button>
        </div>
        <div class="content-body">
            <!-- <p class="section-title">Buscar un pedido</p>
            <p class="section-subtitle">Consulta un pedido y su estado actual, además de los datos que lo conforman a cada uno.</p>-->
            <div class="orders">
                <div class="order" *ngFor="let order of ordersFiltered" (click)="displayDetail(order)">
                    <p style="font-weight: 600;">Orden {{order.id}}</p>
                    <p>{{order.products.length}} Producto(s)</p>
                    <p>{{order.firstName}} {{order.lastName}}</p>
                    <p style="font-weight: 600;">{{order.totalCost | currency}}</p>
                    <p class="message-text" *ngIf="order.status=='Pagado'">Envío Agendado</p>
                    <button class="primary-btn shipping-btn" *ngIf="order.status=='Esperando envío'"
                        (click)="showShippingModal(order); $event.stopPropagation();">Generar Envío</button>
                    <p class="message-text" *ngIf="order.status=='Esperando Pago'">Esperando pago del usuario</p>
                    <p class="message-text" *ngIf="order.status=='Esperando confirmación'">Pago no confirmado</p>
                    <p class="message-text" *ngIf="order.status=='Fallida'">Fallo en el pago</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="order-modal" [class.show]="showDetail">
    <div class="modal-background" (click)="hideDetail()"></div>
    <div class="order-modal-content">
        <p class="order-title bold">Orden #{{orderToShow.id}}</p>
        <p>Estado de la Orden:</p>
        <p class="status-text" *ngIf="orderData.status">{{orderData.status}}</p>
        <p class="status-text" *ngIf="!orderData.status">{{orderToShow.status}}</p>
        <p style="margin-bottom: 15px;"><span class="bold">Fecha de creación de orden:</span> {{orderToShow.date}}</p>
        <p class="order-subtitle">Resumen de la orden</p>
        <div class="product" *ngFor="let product of orderToShow.products">
            <img [src]="product.image">
            <div class="product-numbers">
                <p>{{product.name}}</p>
                <p>x{{product.quantity}}</p>
                <p class="bold">{{product.price | currency}}</p>
            </div>
        </div>
        <div class="order-subtotal">
            <p class="bold">Subtotal</p>
            <p style="font-weight: 500;">{{orderToShow.subtotal | currency}}</p>
        </div>
        <div class="order-discount" *ngIf="orderToShow.discount > 0">
            <p class="bold">Descuento</p>
            <p class="bold">{{discountCode}}</p>
            <p style="font-weight: 500;">{{orderToShow.subtotal - orderToShow.totalCost | currency}}</p>
        </div>
        <div class="order-discount" *ngIf="orderToShow.shippingCost">
            <p class="bold">Envío</p>
            <p style="font-weight: 500;">{{orderToShow.shippingCost | currency}}</p>
        </div>
        <div class="order-total">
            <p class="bold">Total</p>
            <p style="font-weight: 500;">{{orderToShow.totalCost | currency}}</p>
        </div>
        <p class="order-subtitle">Dirección</p>
        <p class="order-text">{{orderToShow.firstName}} {{orderToShow.lastName}}</p>
        <p class="order-text">{{orderToShow.street}} #{{orderToShow.extNumber}}, Colonia {{orderToShow.suburb}}, CP
            {{orderToShow.zipCode}}<br>{{orderToShow.reference}}</p>

        <a class="link" target="_blank" [href]=tempPdf *ngIf="orderToShow.guide!=''">Guía de Pedido</a>
        <a *ngIf="orderToShow.transaction_id && orderToShow.openpay_id" class="link" target="_blank"
            [href]="'https://dashboard.openpay.mx/terminal/print-payment-conditions/m3bhmq0oerrym5ucu4ly/'+orderToShow.transaction_id+'/'+orderToShow.openpay_id">Consultar
            Pagaré</a>

        <button class="primary-btn danger-btn" style="height:40px;width:250px;margin-top: 20px;"
            (click)="deleteOrder(orderToShow.id)">Borrar Orden</button>
    </div>
</div>

<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading">Cargando Pedido...</p>
</ngx-spinner>

<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium" name="shippingSpinner">
    <p class="loading">Generando Orden de Envío...</p>
</ngx-spinner>

<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium" name="priceSpinner">
    <p class="loading">Calculando Precio...</p>
</ngx-spinner>

<div class="modal" [class.display-modal]="isShippingModalVisible">
    <div class="modal-background" (click)="hideShippingModal()"></div>
    <div class="modal-content">
        <h2>Cajas incluidas en el envío</h2>
        <div class="boxes-form">
            <div class="box-row" *ngFor="let box of shippingBoxes;index as i">
                <div class="input">
                    <label for="">Peso (kg)</label>
                    <input type="text" [(ngModel)]="box.weight">
                </div>
                <div class="input">
                    <label for="">Largo (cm)</label>
                    <input type="text" [(ngModel)]="box.length">
                </div>
                <div class="input">
                    <label for="">Ancho (cm)</label>
                    <input type="text" [(ngModel)]="box.width">
                </div>
                <div class="input">
                    <label for="">Alto (cm)</label>
                    <input type="text" [(ngModel)]="box.height">
                </div>
            </div>
        </div>
        <a (click)="addBox()">Agregar otra caja</a>
        <div class="shipping-buttons">
            <button class="primary-btn" (click)="calculateShipping()">Calcular Costo</button>
            <button class="primary-btn" (click)="createGuide()">Generar Guía</button>
        </div>
        <p class="shipping-price" *ngIf="shippingPrice!=0">Precio: {{shippingPrice | currency}}</p>
    </div>
</div>