import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase'

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  private sliderList;
  private sliderCollection: AngularFirestoreCollection

  constructor(private firestore: AngularFirestore) {
    this.sliderCollection = this.firestore.collection('slider');
    this.sliderList = this.sliderCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  changeSliderImages(images, image, number){
    this.uploadImage(image,number).then((url) => {
      images[number] = url
      return this.sliderCollection.doc('images').update({images:images})
    })
  }

  getSliderProperties(){
    return this.sliderList;
  }

  uploadImage(image,number){
    const storageRef = firebase.default.storage().ref();
    return new Promise((resolve,reject) => {
      let uploadTask = storageRef.child(`slider/${number}`).put(image.file)
      uploadTask.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
        (snapshot) =>{
        },
        (error) => {
          console.log(error)
          reject(error)
        },
        ()=>{
           uploadTask.snapshot.ref.getDownloadURL().then(url =>{
            resolve(url)
        })
      })
    })
  }

  updateTitles(titles){
    this.sliderCollection.doc('titles').update({titles:titles})
  }
  
}
