<div class="cart-wrapper">
    <div class="order-products">
        <p class="return-link"  [routerLink]="['/tienda','todos','todos']"><i class="fas fa-chevron-left"></i> Seguir comprando</p>
        <div>
            <div class="title">
                <p class="front-text">Carrito de compras</p>
                <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
                <!-- <p class="bg-text">Carrito de compras</p> -->
                <p class="product-qty">{{productNumber}} producto(s)</p>
            </div>
        </div>
        <div class="products">
            <div class="products-header">
                <p class="table-column-1">PRODUCTO</p>
                <p class="table-column-2">ESPECIFICACIONES</p>
                <p class="table-column-3">CANTIDAD</p>
                <p class="table-column-4">SUBTOTAL</p>
            </div>
            <div class="product" *ngFor="let item of cart.items; let i = index">
                <div class="table-column-1" style="display: flex; gap: 20px;">
                    <img [src]="item.image" alt="" class="product-img">
                    <p class="product-name" *ngIf="item.discount <= 0">{{item.name}} <br><span class="product-price">{{item.price | currency}}</span></p>
                    <p class="product-name" *ngIf="item.discount > 0">{{item.name}} <br><span class="product-price">{{item.discount | currency}}</span></p>
                </div>
                <div class="subproduct-list-chars table-column-2">
                    <ng-container *ngFor="let item of item.chars|keyvalue">
                        <p *ngIf="item.key!='Color'">{{item.key}}: {{item.value}}</p>
                        <p *ngIf="item.key=='Color'"><span [style]='"background-color:"+item.value.code'></span>{{item.key}}: {{item.value.name}}</p>
                    </ng-container>
                </div>
                <div class="table-column-3">
                    <input class="product-amount" type="number" min="1" [max]="item.stock" name="quantity" id="quantity" [value]="item.quantity" #quantity (keypress)="numberOnly($event)" (change)="updateCartAmount(i,quantity.value,$event)">
                </div>
                <p class="product-total" class="table-column-4" *ngIf="item.discount <= 0">{{item.price * item.quantity | currency}}</p>
                <p class="product-total" class="table-column-4" *ngIf="item.discount > 0">{{item.discount * item.quantity | currency}}</p>
                <i class="fa fa-times" (click)="removeFromCart(i)"></i>
            </div>
        </div>
        <div class="shipping">
            <i class="fas fa-truck"></i>
            <p>Si tu compra es mayor a $1,999.00 MXN, tu envio se enviara mediante Envioclick de manera gratuita.</p>
        </div>
    </div>
    <div class="order-summary">
        <p class="summary-title">Resumen de compra</p>
        <p class="checkout-cost">Subtotal: <span style="float: right; font-weight: 700;">{{totalCost | currency}}</span></p>
        <p class="checkout-cost">Envio: <span style="float: right; font-weight: 700;">Por calcular</span></p>
        <div class="checkout-section">
            <p class="checkout-cost">Total: <span style="float: right; font-weight: 700;">{{totalCost-discount+shippingCost | currency}}</span></p>
            <button class="primary-btn" routerLink="/pago" [class.btn-disabled]="cart.items.length==0">IR AL CHECKOUT</button>
            <p class="error-label" style="text-align: center;" *ngIf="cart.items.length==0">Agrega productos a tu carrito para continuar</p>
        </div>
    </div>
</div>
