<div class="footer-wrapper">
    <div class="links">
        <div class="drop-menu">
            <h2>AYUDA</h2>
            <a routerLink="">FAQ</a>
            <a routerLink="">Política de devoluciones</a>
            <a routerLink="">Facturación</a>
        </div>
        <div class="drop-menu">
            <h2>¿Quiénes somos?</h2>
            <a routerLink="/acerca">Nosotros</a>
            <a [routerLink]="['/']" fragment="marketplaces" (click)="navigateTo('marketplaces')">Marketplaces</a>
            <a [routerLink]="['/']" fragment="contacto" (click)="navigateTo('contact')">Contacto</a>
        </div>
        <div class="drop-menu">
            <h2>Mi cuenta</h2>
            <a routerLink="/login" *ngIf="!isLoggedIn || !currentUser">Iniciar sesión</a>
            <a routerLink="/signup" *ngIf="!isLoggedIn || !currentUser">Registrarse</a>
            <a routerLink="/mi-cuenta" *ngIf="isLoggedIn && currentUser">Mi Perfil</a>
            <a routerLink="/wishlist" *ngIf="isLoggedIn && currentUser">Mi Wishlist</a>
            <a routerLink="/facturas" *ngIf="isLoggedIn && currentUser">Facturación</a>
        </div>
    </div>
    <div class="icons">
        <div class="payment-icons">
            <div class="logo-container"><img src="../../../assets/Logos/paypal-alt.svg" class="secondary-logo"></div>
            <div class="logo-container"><img src="../../../assets/Logos/mercadopago.png" class="secondary-logo"></div>
            <div class="logo-container"><img src="../../../assets/Logos/credit-cards.svg" class="cards"></div>
        </div>
        <div class="social-icons">
            <a href="https://www.facebook.com/gmeshop.mx" target="_blank">
                <div class="social-media">
                    <div class="facebook"></div>
                </div>
            </a>
            <a href="" target="_blank">
                <div class="social-media">
                    <div class="youtube"></div>
                </div>
            </a>
            <a href="https://www.instagram.com/gmeshop.mx/" target="_blank">
                <div class="social-media">
                    <div class=" instagram"></div>
                </div>
            </a>
        </div>
    </div>
</div>
<div class="footer-bottom">
    <p class="rights">2021 | GM e-shop. | Todos los derechos reservados.</p>
    <p class="terms"><a routerLink="/terminos">Términos y Condiciones</a> | <a routerLink="/aviso">Aviso de privacidad</a></p>
</div>
