<navbar></navbar>
<div class="facturas-container">
    <div class="left-side">
        <div class="orders-container">
            <ng-container  *ngFor="let order of orderList; index as i">
                <div class="order" [class.selected-order]="selectedOrder==i" *ngIf="order.status=='Pagado'" (click)="selectOrder(i)">
                    <img [src]="order.products[0].image" alt="product" class="product-img">
                    <div class="order-center">
                        <p class="order-text">{{order.products[0].name}}</p>
                    </div>
                    <div class="order-right">
                        <p class="order-grey-text">Pedido el: {{order.date}}</p>
                        <p class="order-cost">{{order.totalCost | currency}}</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <form class="client-data" [formGroup]="user" *ngIf="orderToDisplay && !orderToDisplay.invoice">
            <div class="input-field width-70">
                <label for="name">Nombre Completo*</label>
                <input type="text" name="name" id="name" placeholder="Nombre completo" formControlName="name" [class.error-outline]="user.get('name').invalid && user.get('name').touched">
                <p class="error-label" *ngIf="user.get('name').invalid && user.get('name').touched">Por favor ingresa un nombre.</p>
            </div>
            <div class="input-field width-30">
                <label for="rfc">RFC*</label>
                <input type="text" name="rfc" id="rfc" placeholder="RFC" formControlName="rfc" mask="AAAAAAAAAAAAA" [class.error-outline]="user.get('rfc').invalid && user.get('rfc').touched">
                <p class="error-label" *ngIf="user.get('rfc').invalid && user.get('rfc').touched">Por favor ingresa rfc válido.</p>
            </div>
            <div class="input-field">
                <label for="correo">Correo*</label>
                <input type="text" name="mail" id="mail" placeholder="Correo al que se mandará la factura" formControlName="mail" [class.error-outline]="user.get('mail').invalid && user.get('mail').touched">
                <p class="error-label" *ngIf="user.get('mail').invalid && user.get('mail').touched">Por favor ingresa correo válido.</p>
            </div>
            <div class="input-field width-50">
                <label for="cfdiUse">Uso del CFDI*</label>
                <select name="cfdiUse" id="cfdiUse" formControlName="cfdiUse">
                    <option value="G01">Adquisición de Mercancias</option>
                    <option value="G03">Gastos en general</option>
                    <option value="I01">Construcciones</option>
                    <option value="I02">Mobiliario y equipo de oficina por inversiones</option>
                    <option value="I03">Equipo de transporte</option>
                    <option value="I04">Equipo de cómputo y accesorios</option>
                    <option value="I05">Dados, troqueles, moldes, matrices y herramental</option>
                    <option value="I06">Comunicaciones telefónicas</option>
                    <option value="I07">Comunicaciones satelitales</option>
                    <option value="I08">Otra maquinaria y equipo</option>
                    <option value="P01">Por definir</option>
                </select>
            </div>
            <div class="input-field width-50">
                <label for="paymentForm">Forma de Pago*</label>
                <select name="paymentForm" id="paymentForm" formControlName="paymentForm">
                    <option value="03">Transferencia electrónica de fondos</option>
                    <option value="04">Tarjeta de crédito</option>
                    <option value="06">Dinero Electrónico</option>
                    <option value="28">Tarjeta de Débito</option>
                </select>
            </div>
            <button class="primary-btn generate-btn" (click)="generateInvoice()" [class.btn-disabled]="user.invalid || !orderToDisplay || orderToDisplay.status!='Pagado' || orderToDisplay.invoice">Generar Factura</button>
        </form>
        <div class="invoice-generated" *ngIf="orderToDisplay.invoice">
            <p>Esta orden ya ha sido facturada.</p>
        </div>
    </div>
    <div class="right-side" *ngIf="orderToDisplay && !orderToDisplay.invoice && orderToDisplay.status=='Pagado'">
        <div class="product-detail" *ngFor="let product of orderToDisplay.products">
            <img [src]="product.image" alt="product" class="product-img">
            <div class="order-center">
                <p class="order-text">{{product.name}} x {{product.quantity}}</p>
            </div>
            <div class="order-right">
                <p class="order-cost">{{product.price | currency}}</p>
            </div>
        </div>
        <div class="product-detail" *ngIf="this.orderToDisplay.shippingCost>0 && this.orderToDisplay.discountCode && this.orderToDisplay.discountCode.type!='envios'">
            <div class="order-center">
                <p class="order-text">Envío</p>
            </div>
            <div class="order-right">
                <p class="order-cost">{{orderToDisplay.shippingCost | currency}}</p>
            </div>
        </div>
    </div>
    <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium" name="loadingSpinner">
        <p class="loading">Cargando Pedidos...</p>
    </ngx-spinner>
    <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium" name="invoiceSpinner">
        <p class="loading">Generando Factura...</p>
    </ngx-spinner>
</div>
