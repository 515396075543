import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-ty-page',
  templateUrl: './ty-page.component.html',
  styleUrls: ['./ty-page.component.scss']
})
export class TyPageComponent implements OnInit {
  order_id = ''


  constructor(private route: ActivatedRoute, private orderService: OrderService) { }

  ngOnInit(): void {
    /* this.route.queryParams.subscribe((params) => {
      if (params['order']) {
        this.order_id = params['order_id']
        this.orderService.updateOrder(this.order_id, { status: 'Esperando envío' })
      }
    }) */
  }

}
