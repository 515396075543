import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase'

@Injectable({
  providedIn: 'root'
})
export class CharacteristicService {
  private characteristicCollection: AngularFirestoreCollection
  private characteristicList

  constructor(private firestore: AngularFirestore) {
    this.characteristicCollection = this.firestore.collection('characteristics');
    this.characteristicList = this.characteristicCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  addChar(char){
    return this.characteristicCollection.add(char)
  }

  getChars(){
    return this.characteristicList
  }

  getVariableChars(){
    let characteristicCollection: AngularFirestoreCollection = this.firestore.collection('characteristics', (ref) => ref.where("variable", "==", true));
    let characteristicList = characteristicCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return characteristicList
  }

  getNonVariableChars(){
    let characteristicCollection: AngularFirestoreCollection = this.firestore.collection('characteristics', (ref) => ref.where("variable", "==", false));
    let characteristicList = characteristicCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return characteristicList
  }

  updateChar(id, char){
    return this.characteristicCollection.doc(id).update(char)
  }

  removeChar(id){
    this.characteristicCollection.doc(id).delete().then(()=>{
      window.alert('Característica borrada')
      var mobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
    if(mobile)alert('Característica borrada')
      location.reload()
    })
  }

}
