<div class="wrapper">
    <div class="sidebar">
        <!-- <img src="../../../assets/Logos/Gm-navbar-logo.png" alt="" class="sidebar-logo"> -->
        <p class="admin-tab" routerLink="/admin-productos" routerLinkActive="selected-tab">Productos</p>
        <p class="admin-tab" routerLink="/admin-caracteristicas" routerLinkActive="selected-tab">Características</p>
        <p class="admin-tab" routerLink="/admin-pedidos" routerLinkActive="selected-tab">Pedidos</p>
        <p class="admin-tab" routerLink="/admin-perfiles" routerLinkActive="selected-tab">Perfiles</p>
        <p class="admin-tab" routerLink="/admin-cupones" routerLinkActive="selected-tab">Cupones</p>
        <p class="admin-tab" routerLink="/admin-slider" routerLinkActive="selected-tab">Slider</p>
        <p class="admin-tab" routerLink="/admin-campañas" routerLinkActive="selected-tab">Campañas</p>
    </div>
    <div class="content">
        <div class="header">
            <p>Categorías</p>
        </div>
        <div class="content-body">
            <p class="section-title">Añadir una categoría</p>
            <p class="section-subtitle">Todos los productos deben tener una categoría</p>
            <div class="input" style="margin-top: 16px; width: 60%; left: 20%;">
                <label for="category">Añadir nueva categoría</label>
                <input type="text" name="category" id="category" [(ngModel)]="categoryName">
                <i class="fas fa-plus" (click)="addCategory()"></i>
            </div>
            <div class="categories">
                <div class="category" *ngFor="let category of categories;">
                    <p style="text-transform: capitalize;">{{category.name}}</p>
                    <i class="fa fa-edit" (click)="displayEditCategory(category)"></i>
                    <i class="fas fa-minus-circle" (click)="removeCategory(category.id)"></i>
                </div>
            </div>
        </div>
        <div class="categories-edit" *ngIf="showEditCategory">
            <div class="category-fields">
                <div class="inputs" [formGroup]="categoryValues">
                    <label for="category">Categoría</label>
                    <div><input formControlName="categoryName" type="text" name="category" id="category"><p class="change-btn" (click)="changeCategoryName()">Cambiar</p></div>
                    
                    <label for="characteristic">Añadir nueva característica</label>
                    <div><input formControlName="characteristicName" type="text" name="characteristic" id="characteristic"><i class="fas fa-plus" (click)="addCharToCategory()"></i></div>

                    <label for="characteristicEdit" *ngIf="showEditCharacteristic">Editar característica</label>
                    <div *ngIf="showEditCharacteristic"><input formControlName="characteristicEditName" type="text" name="characteristicEdit" id="characteristicEdit" [value]="charToEdit.name"><p class="change-btn" (click)="changeCharName()">Cambiar</p></div>

                    <label for="charValue" *ngIf="showEditCharacteristic">Añadir Valor</label>
                    <div *ngIf="showEditCharacteristic"><input formControlName="characteristicValue" type="text" name="charValue" id="charValue"><i class="fas fa-plus" (click)="addValueToChar()"></i></div>

                    <label for="spec">Añadir Nueva Especificación</label>
                    <div><input formControlName="spec" type="text" name="spec" id="spec"><i class="fas fa-plus" (click)="addSpecToCategory()"></i></div>
                </div>
                <div class="characteristics">
                    <p>{{categoryToEdit.name}}</p>
                    <ng-container *ngFor="let characteristic of categoryToEdit.characteristics; let i = index">
                        <div><p class="characteristic">{{characteristic.name}}</p><i class="fa fa-edit" (click)="displayEditChar(characteristic,i)"></i><i class="fas fa-minus-circle" (click)="removeCharFromCategory(i)"></i></div>
                        <div *ngFor="let value of characteristic.values; let iVal = index"><p class="characteristic-value" >{{value}}</p><i class="fas fa-minus-circle" (click)="removeValueFromChar(i,iVal)"></i></div>
                    </ng-container>
                </div>
            </div>
            <div class="spec-list">
                <div *ngFor="let spec of categoryToEdit.specs;let i = index"><p>{{spec.name}}</p><i class="fa fa-edit" (click)="displayEditSpec(i)"></i><i class="fas fa-minus-circle" (click)="removeSpecFromCategory(i)"></i></div>
            </div>
        </div>
    </div>
</div>

<div class="modal" *ngIf="showSpecModal">
    <div class="modal-background" (click)="hideEditSpec()"></div>
    <div class="spec-modal">
        <label for="specEdit">Editar especificación</label>
        <input [(ngModel)]="specEdit" type="text" name="specEdit" id="specEdit">
        <label for="specImage">Imagen de la especificación</label>
        <div class="spec-image-edit">
            <button class="primary-btn" (click)="fileInput.click()">Seleccionar Imagen</button>
            <img [src]="specImage" *ngIf="specImage">
            <input type="file" style="display: none;" (change)="onFileChanged($event)" name="specImage" id="specImage" #fileInput>
        </div>
        <button class="primary-btn" (click)="updateSpec()">Guardar Cambios</button>
    </div>
</div>