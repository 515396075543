// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDphVMZLn_HFtHTIl0zAAgV50qq4cCdAJs",
    authDomain: "gm-eshop.firebaseapp.com",
    projectId: "gm-eshop",
    storageBucket: "gm-eshop.appspot.com",
    messagingSenderId: "526332634755",
    appId: "1:526332634755:web:8f6eb17f5ab99a46525db6",
    measurementId: "G-HYE6Q6QZZT"
  },
  /* endpoint: 'https://api.envioclickpro.com/api/v1',
  Authorization: 'Bearer 55f68891-71f8-4078-86f3-5c288918be1f', */
  endpoint: 'https://express.api.dhl.com/mydhlapi/test',
  authorization: 'Basic Z3VlcnJlcm9tb2JNWDpGQDRmWCM2aEhAM3BYQDdh',
  accountNumber: '980434675'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
