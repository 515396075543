import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { CategoriesService } from 'src/app/services/categories.service';
import { CharacteristicService } from 'src/app/services/characteristic.service';
import { ProductService } from 'src/app/services/product.service';
import { Upload } from 'src/app/shared/upload';

@Component({
  selector: 'app-admin-products',
  templateUrl: './admin-products.component.html',
  styleUrls: ['./admin-products.component.scss']
})
export class AdminProductsComponent implements OnInit {
  showCreateProduct = false
  showEditProduct = false
  showCreateSubproduct = false
  showEditSubproduct = false

  products
  productsFiltered
  categories

  chars
  charValues
  charsToAdd = []

  productToEdit
  subproductToEdit
  subproductIndex = 0

  newTag = false
  outOfStock = false

  tempProduct = {}

  tempEditProduct = {}

  imageFiles = []
  images = []
  originalImages = []

  newProductInfo = new FormGroup({
    category: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    specs: new FormGroup({
      spec1: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec2: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec3: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec4: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec5: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec6: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      })
    })
  })

  editProductInfo = new FormGroup({
    category: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    specs: new FormGroup({
      spec1: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec2: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec3: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec4: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec5: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      }),
      spec6: new FormGroup({
        name: new FormControl(''),
        desc: new FormControl('')
      })
    })
  })

  newSubproductInfo = new FormGroup({
    name: new FormControl('Prueba',Validators.required),
    price: new FormControl(50,Validators.required),
    discount: new FormControl(0),
    stock: new FormControl(1),
    char: new FormControl(''),
    weight: new FormControl(1,Validators.required),
    charValue: new FormControl(''),
    size: new FormControl(1,Validators.required),
    ProductCode: new FormControl('',Validators.required)
  })

  editSubproductInfo = new FormGroup({
    name: new FormControl('',Validators.required),
    price: new FormControl(0,Validators.required),
    discount: new FormControl(0),
    stock: new FormControl(1),
    weight: new FormControl(1,Validators.required),
    char: new FormControl(''),
    charValue: new FormControl(''),
    size: new FormControl(1,Validators.required),
    ProductCode: new FormControl('',Validators.required)
  })

  constructor(public productService: ProductService,
    public categoryService: CategoriesService,
    public charService: CharacteristicService) { }

  ngOnInit(): void {
    this.productService.getProducts().pipe(first()).subscribe(res => {
      this.products = res
      this.productsFiltered = res
    })
    this.categoryService.getCategories().subscribe(res => {
      this.categories = res
    })
    this.charService.getChars().subscribe(res => {
      this.chars = res
    })
  }

  search(value){
    this.productsFiltered = this.products.filter(function (product) {
      var name:string = product.name;
      return (name.toLowerCase().includes(value.toLowerCase()) || product.category.toLowerCase().includes(value.toLowerCase()))
    })
  }

  displayCreateProduct() {
    this.showCreateProduct = true
    setTimeout(() => {
      this.scrollTop()
    }, 2);
  }

  hideCreateProduct() {
    this.showCreateProduct = false
  }

  createProduct(){
    let tempProduct = this.newProductInfo.getRawValue()
    tempProduct.type = "parent"
    tempProduct.date = new Date()
    tempProduct.isVisible = true
    this.productService.createProduct(tempProduct).then((res:any) => {
      this.productToEdit = tempProduct
      this.productToEdit.id = res
      this.newProductInfo.reset()
      this.showCreateProduct = false
      this.showCreateSubproduct = true
    })
  }

  displayEditProduct(product) {
    this.showEditProduct = true
    this.productToEdit = product
    this.editProductInfo.controls.name.setValue(product.name)
    this.editProductInfo.controls.category.setValue(product.category)
    this.editProductInfo.controls.description.setValue(product.description)
    this.editProductInfo.controls.specs.setValue(product.specs)
    setTimeout(() => {
      this.scrollTop()
    }, 2);
  }

  hideEditProduct() {
    this.showEditProduct = false
  }

  updateProduct(){
    let tempProduct = this.editProductInfo.getRawValue()
    this.productService.updateProduct(this.productToEdit.id,tempProduct).then(res => {
      window.alert('Los cambios han sido guardados')
      var mobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
      if(mobile)alert('Los cambios han sido guardados')
    })
  }

  removeProduct(){
    this.productService.removeProduct(this.productToEdit.id)
    this.showEditProduct = false
  }

  displayCreateSubproduct(){
    this.showCreateSubproduct = true
    this.showEditProduct = false
    setTimeout(() => {
      this.scrollTop()
    }, 2);
  }

  hideCreateSubproduct(){
    this.showCreateSubproduct = false
    this.showEditProduct = true
  }

  getCharValues(){
    let charIndex = this.newSubproductInfo.get('char').value
    this.charValues = this.chars[charIndex].value
    this.newSubproductInfo.get('charValue').setValue(0)
  }

  addChar(){
    let tempChar = {
      name: this.chars[this.newSubproductInfo.get('char').value].name,
      value: this.chars[this.newSubproductInfo.get('char').value].value[this.newSubproductInfo.get('charValue').value]
    }
    this.tempProduct[tempChar?.name]=tempChar?.value
  }

  async createSubproduct(){
    let imageUploads = []
    let tempImage
    for(const image of this.imageFiles){
      tempImage = new Upload(image)
      imageUploads.push(tempImage)
    }
    let isNew = this.newTag
    let images = imageUploads
    let subproduct = this.newSubproductInfo.getRawValue()
    if(!subproduct.discount) subproduct.discount = 0
    let tempSubproduct = {...subproduct,chars: {...this.tempProduct},isNew,images,UnitCode:'H87',Unit:'Pieza'}
    delete tempSubproduct.char
    delete tempSubproduct.charValue
    await this.productService.addSubproduct(this.productToEdit,tempSubproduct)
    
  }

  onFileChanged(event) {
    const imageTemp = event.target.files;
    for(let file of imageTemp){
      this.imageFiles.push(file)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => { 
        this.images.push(reader.result);
      }
    }
    
  }

  removeImage(index){
    this.images.splice(index,1)
    if(index>this.originalImages.length-1){
      this.imageFiles.splice(index-this.originalImages.length-1,1)
    }
    if(index<this.originalImages.length){
      /* this.productService.deleteImageFromFirestore(this.originalImages[index]).then(ref => {
        this.originalImages.splice(index,1)
      },(error) => this.originalImages.splice(index,1)) */
    }
  }

  removeImageFromOriginal(i){
    this.subproductToEdit.images.splice(i,1)
  }


  removeSubproduct(index){
    this.productToEdit.subproducts.splice(index,1)
    this.productService.updateProduct(this.productToEdit.id,this.productToEdit)
  }

  displayEditSubproduct(index){
    this.showEditSubproduct = true
    this.showEditProduct = false
    this.subproductToEdit = this.productToEdit.subproducts[index]
    this.editSubproductInfo.get('name').setValue(this.subproductToEdit.name)
    this.editSubproductInfo.get('price').setValue(this.subproductToEdit.price)
    this.editSubproductInfo.get('discount').setValue(this.subproductToEdit.discount || 0)
    this.editSubproductInfo.get('size').setValue(this.subproductToEdit.size)
    this.editSubproductInfo.get('weight').setValue(this.subproductToEdit.weight)
    this.editSubproductInfo.get('stock').setValue(this.subproductToEdit.stock || 0)
    this.editSubproductInfo.get('ProductCode').setValue(this.subproductToEdit.ProductCode || '')
    this.newTag = this.subproductToEdit.isNew
    this.tempEditProduct = this.subproductToEdit.chars
    this.subproductIndex = index
    setTimeout(() => {
      this.scrollTop()
    }, 2);
  }

  scrollTop(){
    window.scrollTo({
      top: 0
    });
  }

  hideEditSubproduct(){
    this.showEditSubproduct = false
    this.showEditProduct = true
  }

  getEditCharValues(){
    let charIndex = this.editSubproductInfo.get('char').value
    this.charValues = this.chars[charIndex].value
    this.editSubproductInfo.get('charValue').setValue(0)
  }

  addEditChar(){
    let tempChar = {
      name: this.chars[this.editSubproductInfo.get('char').value].name,
      value: this.chars[this.editSubproductInfo.get('char').value].value[this.editSubproductInfo.get('charValue').value]
    }
    this.tempEditProduct[tempChar?.name]=tempChar?.value
  }

  editSubproduct(){
    let imageUploads = []
    let tempImage
    for(const image of this.imageFiles){
      tempImage = new Upload(image)
      imageUploads.push(tempImage)
    } 
    let isNew = this.newTag
    let images = imageUploads
    let subproduct = this.editSubproductInfo.getRawValue()
    if(!subproduct.discount) subproduct.discount = 0
    let tempSubproduct = {...subproduct,chars: {...this.tempEditProduct},isNew,images,oldImages:this.subproductToEdit.images,UnitCode:'H87',Unit:'Pieza'}
    delete tempSubproduct.char
    delete tempSubproduct.charValue

    this.productToEdit.subproducts[this.subproductIndex] = tempSubproduct
    this.productService.updateProductWithSubproduct(this.productToEdit,tempSubproduct,this.subproductIndex)

    this.imageFiles = []
    this.images = []

  }

  changeProductVisibility(product){
    product.isVisible = !product.isVisible || false
    this.productService.updateProduct(product.id,product)
  }

}
