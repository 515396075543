import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categories.service';
import { CharacteristicService } from 'src/app/services/characteristic.service';
import { ProductService } from 'src/app/services/product.service'
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  currentUser
  userWishlist = []
  uid
  hasLoaded = false
  minValue: number = 0;
  maxValue: number = 50000;
  options: Options = {
    floor: 0,
    ceil: 30000,
    step: 2000,
    showTicks: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Mínimo:</b> $' + value;
        case LabelType.High:
          return '<b>Máximo:</b> $' + value;
        default:
          return '$' + value;
      }
    }
  };

  productsList
  productsFiltered

  chars = []

  categoryForFilter: string
  nameForFilter: string = ''
  brandForFilter: string = 'todos'
  memoryForFilter: string = 'todos'
  priceMin = -1
  priceMax

  showFilter = [true]

  selectedValues = []

  constructor(public productService: ProductService, 
    public activeRoute: ActivatedRoute, 
    public router: Router, 
    public charservice: CharacteristicService,
    public userService: UserService,
    public firebaseAuthService: AuthService,) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
  }

  ngOnInit(): void {
    this.firebaseAuthService.getCurrentUser().then((res) => {
      if (res != undefined) {
        this.uid = res.uid
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
          this.userWishlist = []
          for(let product of res.wishlist){
            this.userWishlist.push(product.id)
          }
        })
      }
    });
    this.activeRoute.params.subscribe((params) => {
      this.categoryForFilter = params['category']
      this.nameForFilter = params['name']
      this.selectedValues.push(this.categoryForFilter)
      if (this.categoryForFilter != 'todos') {
        this.productService.getProductsByCategory(this.categoryForFilter).subscribe(res => {
          res = res.filter(product => product.subproducts?.length && product.isVisible)
          for (var i = res.length - 1; i >= 0; --i) {
            res[i].totalStock = res[i].subproducts.reduce((previous,current) => previous + current.stock,0)
            if (!res[i].subproducts || !res[i].subproducts.length) {
              res.splice(i, 1);
            }
          }
          this.productsList = res
          this.productsFiltered = res
          this.filterProducts()
        })
      } else {
        this.productService.getProducts().subscribe(res => {
          res = res.filter(product => product.subproducts?.length && product.isVisible)
          for (var i = res.length - 1; i >= 0; --i) {
            res[i].totalStock = res[i].subproducts.reduce((previous,current) => previous + current.stock,0)
            if (!res[i].subproducts || !res[i].subproducts.length) {
              res.splice(i, 1);
            }
          }
          this.productsList = res.sort((a, b) => {
            if (a.date < b.date) return 1
            return -1
          })
          this.productsFiltered = res.sort((a, b) => {
            if (a.date < b.date) return 1
            return -1
          })
          this.filterProducts()
        })
      }
    })
    this.charservice.getChars().subscribe(res => {
      this.chars = res
      for (let char of this.chars) {
        this.showFilter.push(false)
        this.selectedValues.push('todos')
      }
    })
  }

  toggleShow(index) {
    this.showFilter[index] = !this.showFilter[index]
  }

  setCategoryFilter(category) {
    this.router.navigate(['/tienda', category, 'todos'])
  }

  setFilter(index, value) {
    this.selectedValues[index] = value
    if (value.name) this.selectedValues[index] = value.name
    console.log(this.selectedValues)
    this.filterProducts()
  }

  setPriceFilters() {
    this.filterProducts()
  }

  setNameFilter(name) {
    this.nameForFilter = name
    this.filterProducts()
  }

  filterProducts() {
    this.productsFiltered = []
    if (this.productsList) {
      for (let product of this.productsList) {
        for (let subproduct of product.subproducts) {
          let willAdd = true
          let i = 1
          if (this.nameForFilter != 'todos' && !(product.name.toLowerCase().includes(this.nameForFilter.toLowerCase()) || (product.category.toLowerCase().includes(this.nameForFilter.toLowerCase())))) {
            willAdd = false;
            break
          }
          if (subproduct.price < this.minValue || subproduct.price > this.maxValue) {
            willAdd = false
            break
          }
          for (let char of this.chars) {
            if (this.selectedValues[i] != 'todos') {
              if (char.name == 'Color') {
                if (subproduct.chars[char.name].name != this.selectedValues[i]) {
                  willAdd = false
                  break
                }
              } else {
                if (subproduct.chars[char.name] != this.selectedValues[i]) {
                  willAdd = false
                  break
                }
              }
            }
            i++
          }
          if (willAdd) {
            this.productsFiltered.push(product)
            break
          }
        }
      }
    }


  }

  setProductOrder(type) {
    if (type == "priceAsc") return this.productsFiltered.sort((a, b) => {
      if (a.subproducts[0].price > b.subproducts[0].price) return 1
      return -1
    })
    if (type == "priceDesc") return this.productsFiltered.sort((a, b) => {
      if (a.subproducts[0].price < b.subproducts[0].price) return 1
      return -1
    })
    if (type == "alfaAsc") return this.productsFiltered.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      return -1
    })
    if (type == "alfaDesc") return this.productsFiltered.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return 1
      return -1
    })
  }

  addToWishlist(id){
    console.log('click')
    let wishlist = []
    if(this.currentUser.wishlist){
      wishlist = this.currentUser.wishlist
    }
    wishlist.push({id:id,date:new Date()})
    this.userService.updateWishlist(this.uid,wishlist)
  }

  removeFromWishlist(id){
    let wishlist = []
    if(this.currentUser.wishlist){
      wishlist = this.currentUser.wishlist
      let index = this.findWithAttr(wishlist,'id',id)
      if(index != -1){
        wishlist.splice(index,1)
        this.userService.updateWishlist(this.uid,wishlist)
      }
    }
  }

  findWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
  }

}
