import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase'

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  private couponCollection: AngularFirestoreCollection
  private couponList

  constructor(private firestore: AngularFirestore) {
    this.couponCollection = this.firestore.collection('coupons');
    this.couponList = this.couponCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
   }

  addCoupon(coupon){
    this.couponCollection.add(coupon)
  }

  getCoupons(){
    return this.couponList
  }

  removeCoupon(id){
    this.couponCollection.doc(id).delete()
  }
}
