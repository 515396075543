import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ShopComponent } from './components/shop/shop.component';
import { ProductComponent } from './components/product/product.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { AccountComponent } from './components/account/account.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AdminProductsComponent } from './components/admin-products/admin-products.component';
import { AdminCategoriasComponent } from './components/admin-categorias/admin-categorias.component';
import { AdminOrdersComponent } from './components/admin-orders/admin-orders.component';
import { AdminProfilesComponent } from './components/admin-profiles/admin-profiles.component';
import { AdminCouponsComponent } from './components/admin-coupons/admin-coupons.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { AdminCharacteristicsComponent } from './components/admin-characteristics/admin-characteristics.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TrackingComponent } from './components/tracking/tracking.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ReviewComponent } from './components/review/review.component';
import { AdminSliderComponent } from './components/admin-slider/admin-slider.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { LandingPageNewComponent } from './components/landing-page-new/landing-page-new.component';
import { EmbedSocialComponent } from './components/embed-social/embed-social.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { FooterInvertedComponent } from './components/footer-inverted/footer-inverted.component';
import { FacturasComponent } from './components/facturas/facturas.component';

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AdminCampaignsComponent } from './components/admin-campaigns/admin-campaigns.component';
import { TyPageComponent } from './components/ty-page/ty-page.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};



@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    NavbarComponent,
    FooterComponent,
    ShopComponent,
    ProductComponent,
    LoginComponent,
    SignupComponent,
    CartComponent,
    CheckoutComponent,
    AccountComponent,
    AdminProductsComponent,
    AdminCategoriasComponent,
    AdminOrdersComponent,
    AdminProfilesComponent,
    AdminCouponsComponent,
    AboutusComponent,
    PlaceholderComponent,
    AdminCharacteristicsComponent,
    TrackingComponent,
    ToggleComponent,
    ReviewComponent,
    AdminSliderComponent,
    TermsComponent,
    PrivacyComponent,
    LandingPageNewComponent,
    EmbedSocialComponent,
    WishlistComponent,
    FooterInvertedComponent,
    FacturasComponent,
    AdminCampaignsComponent,
    TyPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
	  AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    NgxSliderModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
