import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embed-social',
  templateUrl: './embed-social.component.html',
  styleUrls: ['./embed-social.component.scss']
})
export class EmbedSocialComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
    (function(d, s, id){
      var js; 
      if (d.getElementById(id)) {
        return;
      } 
      js = d.createElement(s);
      js.id = id; 
      js.src = "https://embedsocial.com/embedscript/in.js";
      d.getElementsByTagName("head")[0].appendChild(js);
    }(document, "script", "EmbedSocialInstagramScript"));
  }

}
