<navbar></navbar>
<div class="wrapper">
    <img src="../../../assets/top-ring.svg" class="top" alt="">
    <img src="../../../assets/right-ring.svg" class="right" alt="">
    <img src="../../../assets/bottom-ring.svg" class="bottom" alt="">
    <img src="../../../assets/left-ring.svg" class="left" alt="">
    <div class="blur-container">
        <h2>¡Gracias por tu compra!</h2>
        <p>Se ha generado tu orden de compra. En breve recibirás un correo con todos los detalles de tu compra.</p>
        <img src="../../../assets/bag.svg" alt="">
        <p>Tu paquete llegará en un plazo estimado de 5 días hábiles. Puedes ratrear tu pedido de DHL <a [routerLink]="['/rastreo']" >aquí</a> una vez que esté disponible tu <a [routerLink]="['/mi-cuenta']" >número de guía.</a></p>
        <button class="primary-btn" [routerLink]="['/']">SEGUIR COMPRANDO</button>
    </div>
</div>