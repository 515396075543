import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase'

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private productList
  private productCollection: AngularFirestoreCollection

  newImages = []

  constructor(private firestore: AngularFirestore) {
    this.productCollection = this.firestore.collection('products');
    this.productList = this.productCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  createProduct(product){
    return new Promise((resolve,reject) => {
      this.productCollection.add(product).then((res)=>{
        return resolve(res.id)
      })
    })
  }

  getProducts(){
    this.productCollection = this.firestore.collection('products');
    this.productList = this.productCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.productList;
  }

  getProductById(id){
    return this.productCollection.doc(id).valueChanges();
  }

  getProductsByCategory(category){
    this.productCollection = this.firestore.collection('products', (ref) => ref.where("category", "==", category));
    this.productList = this.productCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return this.productList
  }

  removeProduct(id){
    return this.productCollection.doc(id).delete();
  }

  updateProduct(id,product){
    return this.productCollection.doc(id).update(product)
  }

  updateProductWithSubproduct(product,subproduct,index){
    if(!subproduct.images){
      product.subproducts[index]=subproduct
      return this.productCollection.doc(product.id).update(product)
    }
    let number = subproduct.oldImages.length+1
    Promise.all(
      subproduct.images.map(async image => {
        number++
        return await this.uploadImage(subproduct,image,number)
      })
    ).then(() => {
      subproduct.images = []
      if(subproduct.oldImages && subproduct.oldImages.length > 0)subproduct.images = subproduct.oldImages
      subproduct.images = subproduct.images.concat(this.newImages)
      delete subproduct.oldImages
      this.newImages = []
      if(!product.subproducts)product.subproducts=[]
      product.subproducts[index]=subproduct
      console.log(product)
      return this.productCollection.doc(product.id).update(product).then((res) => {
        window.alert('Subproducto Editado')
        location.reload()
        return res
      })
    })
    /* return this.productCollection.doc(id).update(product) */
  }

  addSubproduct(product,subproduct){
    if(!subproduct.images)return this.productCollection.doc(product.id).update(product)
    let number = 0
    Promise.all(
      subproduct.images.map(async image => {
        number++
        return await this.uploadImage(subproduct,image,number)
      })
    ).then(() => {
      subproduct.images = this.newImages
      this.newImages = []
      if(!product.subproducts)product.subproducts=[]
      product.subproducts.push(subproduct)
      return this.uploadProduct(product).then((res)=>{
        window.alert('Subproducto Agregado')
        location.reload()
        return res
      })
    })
  }

  uploadProduct(product){
    return new Promise((resolve,reject) => {
      resolve(this.productCollection.doc(product.id).update(product))
    })
  }

  uploadImage(subproduct,image,number){
    const storageRef = firebase.default.storage().ref();
    return new Promise((resolve,reject) => {
      let uploadTask = storageRef.child(`products/${subproduct.name}-${number}`).put(image.file)
      uploadTask.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
        (snapshot) =>{
          subproduct.progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
        },
        (error) => {
          console.log(error)
          reject(error)
        },
        ()=>{
           uploadTask.snapshot.ref.getDownloadURL().then(url =>{
            this.newImages.push(url)
            resolve(url)
        })
      })
    })
  }

}
