import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentUser;
  isLoggedIn = false;

  constructor(public firebaseAuthService: AuthService, public userService: UserService) { }

  ngOnInit(): void {
    this.firebaseAuthService.getCurrentUser().then((res) => {
      if (res != undefined) {
        this.isLoggedIn = true;
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
        })
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  navigateTo(section){
    try{
      document.querySelector('#' + section).scrollIntoView();
      if(section=='contact'){
        window.scrollBy(0,85)
      }
    }catch(e){

    }
  }

}
