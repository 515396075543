import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  id
  product
  currentUser;
  userID

  rating = 5
  review = ''

  constructor(private route: ActivatedRoute,
    public router: Router,
    public productService: ProductService,
    public userService: UserService,
    public firebaseAuthService: AuthService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.firebaseAuthService.getCurrentUser().then((res) => {
      if(res){
        this.userID = res.uid
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
        })
      }
    })

    this.productService.getProductById(this.id).subscribe(res => {
      this.product = res
    })
    
  }

  createReview(){
    let tempReviews
    let date = new Date()
    tempReviews = this.product.reviews
    if(!this.product.reviews)tempReviews=[]
    tempReviews.push({name:this.currentUser.name,rating:this.rating,review:this.review,date})
    this.product.reviews = tempReviews
    this.productService.updateProduct(this.product.id,this.product)
    window.alert('Gracias por tu reseña!')
    var mobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
    if(mobile)alert('Gracias por tu reseña!')
    this.router.navigate(['/mi-cuenta'])
  }

}
