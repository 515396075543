import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase'

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private categoriesCollection: AngularFirestoreCollection
  private categoriesList

  constructor(private firestore: AngularFirestore) {
    this.categoriesCollection = this.firestore.collection('categories');
    this.categoriesList = this.categoriesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
   }

  addCategory(category){
    return this.categoriesCollection.add(category)
  }

  updateCategory(id, category){
    return this.categoriesCollection.doc(id).update(category);
  }

  getCategories(){
    return this.categoriesList;
  }

  removeCategory(id){
    return this.categoriesCollection.doc(id).delete();
  }

  //Método para subir una imagen a firebase
  uploadImage(spec,image,categoryName){
    const storageRef = firebase.default.storage().ref();
    return new Promise((resolve,reject) => {
      let uploadTask = storageRef.child(`specs/${categoryName}-${spec.name}`).put(image.file)
      uploadTask.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
        (snapshot) =>{
          spec.progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100
        },
        (error) => {
          console.log(error)
          reject(error)
        },
        ()=>{
           uploadTask.snapshot.ref.getDownloadURL().then(url =>{
            resolve(url)
        })
      })
    })
  }

  editSpecInCategory(id,category,spec,image,index){
    if(!image){
      if(spec.hasImage)category.specs[index] = {name: spec.name,image:spec.image}
      if(!spec.hasImage)category.specs[index] = {name: spec.name,image:""}
      return this.categoriesCollection.doc(id).update(category);
    }

    console.log(spec.name)

    this.uploadImage(spec,image,category.name).then((url) => {
      let tempSpec = {
        name: spec.name,
        image: url
      }
      category.specs[index] = tempSpec
      return this.categoriesCollection.doc(id).update(category);
    })
  }

}
