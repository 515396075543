import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { AccountComponent } from './components/account/account.component';
import { AdminCharacteristicsComponent } from './components/admin-characteristics/admin-characteristics.component';
import { AdminCouponsComponent } from './components/admin-coupons/admin-coupons.component';
import { AdminOrdersComponent } from './components/admin-orders/admin-orders.component';
import { AdminProductsComponent } from './components/admin-products/admin-products.component';
import { AdminProfilesComponent } from './components/admin-profiles/admin-profiles.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginComponent } from './components/login/login.component';
import { ProductComponent } from './components/product/product.component';
import { ShopComponent } from './components/shop/shop.component';
import { SignupComponent } from './components/signup/signup.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import { ReviewComponent } from './components/review/review.component';
import { AdminGuard } from './guards/admin.guard';
import { AdminSliderComponent } from './components/admin-slider/admin-slider.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { LandingPageNewComponent } from './components/landing-page-new/landing-page-new.component';
import { WishlistComponent } from './components/wishlist/wishlist.component';
import { FacturasComponent } from './components/facturas/facturas.component';
import { AdminCampaignsComponent } from './components/admin-campaigns/admin-campaigns.component';
import { TyPageComponent } from './components/ty-page/ty-page.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'acerca', component: AboutusComponent },
  { path: 'aviso', component: PrivacyComponent },
  { path: 'terminos', component: TermsComponent },
  { path: 'tienda/:category/:name', component: ShopComponent },
  { path: 'producto/:id', component: ProductComponent },
  { path: 'calificar/:id', component: ReviewComponent },
  { path: 'login', component: LoginComponent },
  { path: 'registro', component: SignupComponent },
  { path: 'micarrito', component: CartComponent },
  { path: 'pago', component: CheckoutComponent },
  { path: 'gracias', component: TyPageComponent },
  { path: 'mi-cuenta', component: AccountComponent },
  { path: 'wishlist', component: WishlistComponent },
  { path: 'rastreo', component: TrackingComponent },
  { path: 'facturas', component: FacturasComponent },
  { path: 'admin-caracteristicas', component: AdminCharacteristicsComponent, canActivate: [AdminGuard] },
  { path: 'admin-campañas', component: AdminCampaignsComponent, canActivate: [AdminGuard] },
  { path: 'admin-productos', component: AdminProductsComponent, canActivate: [AdminGuard] },
  { path: 'admin-pedidos', component: AdminOrdersComponent, canActivate: [AdminGuard] },
  { path: 'admin-cupones', component: AdminCouponsComponent, canActivate: [AdminGuard] },
  { path: 'admin-perfiles', component: AdminProfilesComponent, canActivate: [AdminGuard] },
  { path: 'admin-slider', component: AdminSliderComponent, canActivate: [AdminGuard] },
  { path: 'admin', redirectTo: '/admin-productos', pathMatch: 'full' },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
