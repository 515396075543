import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { AddressService } from 'src/app/services/address.service';
import { OrderService } from 'src/app/services/order.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ShippingService } from 'src/app/services/shipping.service';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  userID
  currentUser
  userName = ""
  userBirthdate

  addressList

  orderList
  orderData: any = {}

  addressToEdit = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    street: "",
    extNumber: "",
    suburb: "",
    crossStreet: "",
    reference: "",
    zipCode: "",
    id: ""
  }

  showDetail = false

  orderToShow: any = {}

  showAddressModal = false
  showEditAddressModal = false

  addressInfo = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(14)]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(14)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+$"), Validators.minLength(10), Validators.maxLength(10)]),
    street: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
    extNumber: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(5)]),
    suburb: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
    crossStreet: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(35)]),
    reference: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
    zipCode: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)])
  })

  addressEditInfo = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(14)]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(14)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+$"), Validators.minLength(10), Validators.maxLength(10)]),
    street: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
    extNumber: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(5)]),
    suburb: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
    crossStreet: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(35)]),
    reference: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
    zipCode: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
    id: new FormControl('')
  })

  constructor(public firebaseAuthService: AuthService,
    public fireAuth: AngularFireAuth,
    private router: Router,
    public userService: UserService,
    public addressService: AddressService,
    public orderService: OrderService,
    public spinner: NgxSpinnerService,
    public shippingService: ShippingService,
    public fns: AngularFireFunctions) { }

  ngOnInit(): void {
    /* this.userService.tokenizeOpenPayCard() */
    this.firebaseAuthService.getCurrentUser().then((res) => {
      if (res) {
        this.userID = res.uid
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
          this.userName = res.name
          this.userBirthdate = res.birthdate
        })
      }
      this.addressService.getAddresssByUserId(res.uid).subscribe((res) => {
        this.addressList = res;
      })
      this.orderService.getOrdersByUserId(res.uid).subscribe((res: any) => {
        res.sort((a, b) => {
          if (a.date < b.date) return 1
          return -1
        })
        for (let order of res) {
          order.date = this.transformDate(order.date)
        }
        this.orderList = res
      })
    });
  }

  transformDate(date) {
    let d;
    if (date != undefined) d = new Date(date.seconds * 1000);
    if (date == undefined) d = new Date();
    let options = { day: "numeric", month: "long", year: "numeric" };
    return d.toLocaleDateString("es-ES", options);
  }

  saveUserData() {
    this.userService.changeName(this.currentUser.id, this.userName)
    this.userService.changeBirthdate(this.currentUser.id, this.userBirthdate)
    window.alert('Cambios Guardados')
    var mobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
    if (mobile) alert('Cambios Guardados')
  }

  displayAddressModal() {
    this.showAddressModal = true
  }

  hideAddressModal() {
    this.showAddressModal = false
  }

  saveAddress() {
    let tempAddress = this.addressInfo.getRawValue()
    tempAddress.uid = this.userID
    this.addressService.addAddress(tempAddress)
    this.showAddressModal = false
  }

  editAddress() {
    let tempAddress = this.addressEditInfo.getRawValue()
    tempAddress.uid = this.userID
    this.addressService.updateAddress(tempAddress.id, tempAddress)
    this.showEditAddressModal = false
  }

  deleteAddress(id) {
    this.addressService.removeAddress(id)
  }

  displayEditAddress(address) {
    this.addressEditInfo.patchValue(address)
    this.showEditAddressModal = true
  }

  hideEditAddressModal() {
    this.showEditAddressModal = false
  }

  removeCard(index) {
    this.currentUser.paymentMethods.splice(index, 1)
    this.userService.updatePaymentMethods(this.userID, this.currentUser.paymentMethods)
  }

  displayDetail(order) {
    this.orderToShow = order
    this.spinner.show()
    try {
      let trackDHLOrder = this.fns.httpsCallable('trackOrder')
      trackDHLOrder({ trackingNumber: order.tracker }).subscribe((res) => {
        console.log(res);
        this.spinner.hide()
        this.orderData = res.shipments[0]
        this.showDetail = true
      }, error => {
        /* console.log(error); */
        this.spinner.hide()
        this.showDetail = true
      })
      /* this.shippingService.trackOrder(order.tracker).subscribe((res:any) => {
        this.showDetail = true
        this.spinner.hide()
        this.orderData = res.data
      }) */
    } catch (err) {
      console.log(err)
      alert('Error al recuperar los datos. Por favor intenta más tarde.')
      this.spinner.hide()
    }
  }

  hideDetail() {
    this.showDetail = false
    this.orderToShow = {}
    this.orderData = null
  }

  logout() {
    this.fireAuth.signOut().then(() => {
      this.router.navigate(['/login'])
    })
  }

}