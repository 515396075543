import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import * as firebase from 'firebase'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireFunctions } from '@angular/fire/functions';

export interface user {
  id?: string,
  name: string,
  email: string,
  role: string,
  addresses: any[],
  paymentMethods: any[],
  birthdate: Date,
  wishlist: any[],
  hasCart: boolean
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private users: Observable<user[]>;
  private user;
  private userList;
  private usersCollection: AngularFirestoreCollection<user>;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': 'Basic bGVvLmd1ZW10ekBnbWFpbC5jb206R3VlTW8jMjk3MEBKZGk='
    })
  }

  constructor(private firestore: AngularFirestore, private http: HttpClient, private functions: AngularFireFunctions) {
    /* this.functions.useEmulator("localhost", 5001); */
    this.usersCollection = this.firestore.collection<user>('users');
    this.userList = this.usersCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  createUserTest(data) {
    let createClient = this.functions.httpsCallable('createOpenpayClient')
    return createClient({ name: data.name, email: data.email }).toPromise()
  }

  async createUserRecord(data) {
    let createClient = this.functions.httpsCallable('createOpenpayClient')
    createClient({ name: data.name, email: data.email }).pipe(first()).subscribe((res: any) => {
      let openpay_id = res.id
      console.log(res);
      return new Promise<any>((resolve, reject) => {
        this.firestore
          .collection("users")
          .doc(data.id)
          .set({
            name: data.name,
            email: data.email,
            role: 'user',
            acceptedTerms: true,
            paymentMethods: [],
            wishlist: [],
            openpay_id
          }).then(res => { resolve(res) }, err => reject(err));
      });
    })
  }

  updateUser(user_id, user) {
    return this.firestore.collection('users').doc(user_id).update(user)
  }

  createOpenPayCard(card, customer_id) {
    let createCard = this.functions.httpsCallable('createOpenpayCard')
    return createCard({ card, customer_id }).toPromise()
  }

  createOpenpayCardFromToken(data) {
    let createCard = this.functions.httpsCallable('createOpenpayCardFromToken')
    return createCard({ ...data }).toPromise()
  }

  createOpenPayCharge(charge, customer_id) {

    console.log(charge)
    console.log(customer_id)

    let createCharge = this.functions.httpsCallable('createOpenpayCharge')
    return createCharge({ charge, customer_id })
  }

  changeName(id, name) {
    this.usersCollection.doc(id).update({ name: name })
  }

  changeBirthdate(id, date) {
    this.usersCollection.doc(id).update({ birthdate: date })
  }

  createUserRecordAdmin(data) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("users")
        .doc(data.id)
        .set({
          name: data.name,
          email: data.email,
          role: 'admin'
        }).then(res => { resolve(res) }, err => reject(err));
    });
  }

  getUserRecord(id) {
    return new Promise<any>((resolve, reject) => {
      this.usersCollection
        .doc(id)
        .ref
        .get()
        .then(res => { resolve(res.data()) }, err => reject(err));
    });
  }

  getUserById(id: string): Observable<user> {
    return this.usersCollection.doc<user>(id).valueChanges().pipe(
      map(user => {
        user.id = id;
        return user
      })
    )
  }

  async tokenizeOpenPayCard(card) {
    return await this.http.post(`https://api.openpay.mx/v1/m3bhmq0oerrym5ucu4ly/tokens`, card, this.httpOptions).toPromise().catch(err => {
      console.log(err);
      return err
    })
  }

  saveUserCard(cards, id) {
    return this.usersCollection.doc(id).update(cards);
  }

  saveUserChanges(user, image, id) {
    if (image) {
      let storageRef = firebase.default.storage().ref()
      let uploadTask = storageRef.child(`uploads/${user.name + image.file.name}`).put(image.file);
      uploadTask.on(firebase.default.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          image.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error) => {
          console.log(error)
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(url => {
            image.url = url
            image.name = image.file.name
            return this.usersCollection.doc(id).update({ name: user.name, email: user.email });
          })
        })
    }

    return this.usersCollection.doc(id).update({ name: user.name, email: user.email });
  }

  getUserWEmail(user) {
    let usersCollection: AngularFirestoreCollection<user> = this.firestore.collection("users", (ref) => ref.where("email", "==", user));
    this.userList = usersCollection.snapshotChanges().pipe(map((actions) => {
      return actions.map((a) => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      });
    }));
    return this.userList;
  }

  updatePaymentMethods(id, paymentMethodsList) {
    this.usersCollection.doc(id).update({
      paymentMethods: paymentMethodsList
    })
  }

  getUsers() {
    return this.userList;
  }

  updateWishlist(id, products) {
    this.usersCollection.doc(id).update({
      wishlist: products
    })
  }

  changeCart(id, value) {
    return this.usersCollection.doc(id).update({
      hasCart: value
    })
  }

}
