<div class="login-wrapper">
    <div class="login-image">
        <img src="../../../assets/Logos/GMESHOPW-04.svg" alt="GM eshop logo" class="gm-logo">
        <div class="social-icons">
            <i class="fab fa-instagram"></i>
            <!-- <i class="fab fa-twitter"></i> -->
            <i class="fab fa-facebook"></i>
        </div>
        <p class="hero-text">Preparate para estrenar un nuevo celular</p>
    </div>
    <div class="login-inputs">
        <div class="top-links">
            <p class="return-link"  routerLink="/"><i class="fas fa-chevron-left"></i> Regresar a la página</p>
            <p class="register-link"  routerLink="/registro">Registrate aquí</p>
        </div>
        <div class="title">
            <p class="front-text">INICIAR SESIÓN</p>
            <!-- <p class="bg-text">INICIAR SESIÓN</p> -->
            <img class="title-line" src="../../../assets/Icons/Title-line.png" alt="">
        </div>
        <form>
            <label class="primary-label">EMAIL</label>
            <input [(ngModel)]="email" name="email" type="text" class="primary-input" placeholder="correo@correo.com">
            <label class="primary-label">CONTRASEÑA</label>
            <input [(ngModel)]="password" name="password" type="password" class="primary-input" placeholder="•••••••••••">
            <p class="restore-password-link" (click)="resetPassword()">¿Olvidaste tu contraseña?</p>
            <p class="primary-label" style="margin-top: 0px;">TAMBIÉN PUEDES USAR</p>
            <div class="icons">
                <div class="google-icon" (click)="loginWithGoogle()"><i class="fab fa-google"></i></div>
                <i class="fab fa-facebook" (click)="loginWithFacebook()"></i>
            </div>
            <div class="bottom-buttons">
                <button class="primary-btn" (click)="login()">Iniciar Sesión</button>
                <button class="secondary-btn"  routerLink="/registro">Registrarse</button>
                <p class="error-text">{{errorText}}</p>
            </div>
            <p class="terms-text">GM. Todos los derechos reservados. 2020 <span class="link-text"><a routerLink="/terminos">Terminos y Condiciones</a> | <a routerLink="/aviso">Aviso de privacidad</a></span></p>
        </form>
    </div>
</div>
