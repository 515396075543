import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { CartService } from 'src/app/services/cart.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentUser;
  isLoggedIn = false;
  productNumber = 0;
  cart={items:[]};
  showMenu;
  showSearchbar = false
  showUserMenu = false;
  showGeneralMenu = false;

  showAccount = false
  showMarketplaces = false

  constructor(public firebaseAuthService: AuthService, 
    public router: Router, 
    public cartService: CartService, 
    public userService: UserService,
    public fireAuth: AngularFireAuth,
    private fns: AngularFireFunctions,) { }

  ngOnInit(): void {
    this.firebaseAuthService.getCurrentUser().then((res) => {
      if (res != undefined) {
        this.isLoggedIn = true;
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
        })
      } else {
        this.isLoggedIn = false;
      }
    });
    this.cartService.getTokens().subscribe((res) => {
      this.productNumber = 0
      this.cart = res;
      for (let i of res.items) {
        this.productNumber += i.quantity;
      }
    })
    if (localStorage.getItem('cartGM')) {
      this.cartService.setTokens(JSON.parse(localStorage.getItem('cartGM')));
    } else {
      localStorage.setItem('cartGM', JSON.stringify({ items: [] }))
      this.cartService.setTokens({ items: [] });
    }
  }

  toggleMenu(){
    this.showMenu = !this.showMenu;
    this.showAccount = false
    this.showMarketplaces = false
  }

  toggleSearch(){
    this.showSearchbar = !this.showSearchbar
  }

  toggleUserMenu(){
    this.showUserMenu = !this.showUserMenu
    this.showGeneralMenu = false
  }

  toggleGeneralMenu(){
    this.showGeneralMenu = !this.showGeneralMenu
    this.showUserMenu = false
  }

  search(event){
    this.router.navigate(['tienda','todos',event])
  }

  redirectToStore(){
    this.router.navigate(['tienda','todos','todos'])
  }

  toggleAccount(){
    this.showAccount = !this.showAccount
  }

  toggleMarketplaces(){
    this.showMarketplaces = !this.showMarketplaces
  }

  logout() {
    this.fireAuth.signOut().then(() => {
      this.router.navigate(['/login'])
    })
  }

  navigateTo(section){
    try{
      document.querySelector('#' + section).scrollIntoView();
    }catch(e){

    }
    if(this.showGeneralMenu)this.showGeneralMenu = false
    
  }

}
