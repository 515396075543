<navbar></navbar>
<div class="wrapper">
    <div class="title">
        <p class="front-text">Rastreo de pedido</p>
    </div>
    <label for="orderID">Ingresa el número de orden</label>
    <input type="text" name="orderID" id="orderID" [(ngModel)]="orderID">
    <button class="primary-btn" (click)="trackOrder()">Buscar</button>
    <div class="order-data" *ngIf="orderData">
        <p style="font-weight: 700;">Detalles del Pedido</p>
        <p *ngIf="orderData.status=='Success'"><span style="font-weight: 700;">Estado:</span> Envío Agendado</p>
        <p *ngIf="orderData?.shipmentTimestamp"><span style="font-weight: 700;">Fecha de llegada estimada:</span> {{orderData.shipmentTimestamp}}</p>
        <!-- <p><span style="font-weight: 700;">Detalle:</span> {{orderData.statusDetail}}</p> -->
        <!-- <p *ngIf="orderData?.realPickupDate"><span style="font-weight: 700;">Recogido de la fábrica:</span>{{orderData.realPickupDate}}</p>
        <p *ngIf="orderData?.realDeliveryDate"><span style="font-weight: 700;">Llegada:</span>{{orderData.realDeliveryDate}}</p>
        <p *ngIf="orderData?.receivedBy"><span style="font-weight: 700;">Recibido por:</span>{{orderData.receivedBy}}</p> -->
    </div>
</div>

<ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading">Buscando Pedido...</p>
</ngx-spinner>