<div class="wrapper">
    <div class="sidebar">
        <!-- <img src="../../../assets/Logos/Gm-navbar-logo.png" alt="" class="sidebar-logo"> -->
        <p class="admin-tab" routerLink="/admin-productos" routerLinkActive="selected-tab">Productos</p>
        <p class="admin-tab" routerLink="/admin-caracteristicas" routerLinkActive="selected-tab">Características</p>
        <p class="admin-tab" routerLink="/admin-pedidos" routerLinkActive="selected-tab">Pedidos</p>
        <p class="admin-tab" routerLink="/admin-perfiles" routerLinkActive="selected-tab">Perfiles</p>
        <p class="admin-tab" routerLink="/admin-cupones" routerLinkActive="selected-tab">Cupones</p>
        <p class="admin-tab" routerLink="/admin-slider" routerLinkActive="selected-tab">Slider</p>
        <p class="admin-tab" routerLink="/admin-campañas" routerLinkActive="selected-tab">Campañas</p>
    </div>
    <div class="content">
        <div class="header">
            <p>Cupones</p>
        </div>
        <div class="content-body">
            <p class="section-title">Cupón</p>
            <p class="section-subtitle">Llena los campos para poder añadir un cupón a la tienda.</p>
            <div [formGroup]="couponData">
                <div class="row">
                    <div class="input" style="width: 50%; margin-right: 30px;">
                        <label for="coupon">Nombre del cupón</label>
                        <input name="coupon" type="text" id="coupon" formControlName="name">
                    </div>
                    <div class="input">
                        <label for="code">Código</label>
                        <input name="code" type="text" id="code" formControlName="code">
                    </div>
                </div>
                <div class="row">
                    <div class="input" style="width: 50%; margin-right: 30px;">
                        <label for="coupon">Tipo de descuento</label>
                        <select formControlName="type">
                            <option value="" selected disabled>Selecciona una opción</option>
                            <option value="porcentaje">Porcentaje</option>
                            <option value="fijo">Fijo</option>
                            <option value="envios">Envios Gratis</option>
                        </select>
                    </div>
                    <div class="input" *ngIf="couponData.get('type').value!='envios'">
                        <label for="amount">Descuento</label>
                        <input name="amount" type="text" id="amount" formControlName="amount">
                    </div>
                </div>
                <button class="primary-btn" (click)="addCoupon()">Añadir</button>
            </div>
            <div class="coupons">
                <div class="coupon" *ngFor="let coupon of couponList; let i = index">
                    <p>{{coupon.name}}</p>
                    <p style="text-transform:capitalize;">{{coupon.type}}</p>
                    <p *ngIf="coupon.amount">{{coupon.amount}}</p>
                    <p *ngIf="!coupon.amount">Gratis</p>
                    <p>{{coupon.code}}</p>
                    <i class="fas fa-minus-circle" (click)="removeCoupon(coupon.id)"></i>
                </div>
            </div>
        </div>
    </div>
</div>
