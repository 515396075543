import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CategoriesService } from '../../services/categories.service'
import { Upload } from '../../shared/upload'

@Component({
  selector: 'app-admin-categorias',
  templateUrl: './admin-categorias.component.html',
  styleUrls: ['./admin-categorias.component.scss']
})
export class AdminCategoriasComponent implements OnInit {
  categoryName
  categories

  showEditCategory = false;
  showEditCharacteristic = false;
  showEditValue = false;
  showSpecModal = false

  categoryToEdit = {
    id: "",
    name: "",
    characteristics: [],
    specs: []
  }

  charToEdit = {
    name: "",
    values: []
  }
  charIndex = 0

  specToEdit = {
    name: "",
    image: ""
  }
  specEdit = ""
  specImage
  specImageFile
  specIndex = 0

  categoryValues = new FormGroup({
    categoryName: new FormControl(''),
    characteristicName: new FormControl(''),
    characteristicEditName: new FormControl(''),
    characteristicValue: new FormControl(''),
    spec: new FormControl('')
  })

  constructor(public categoryService: CategoriesService) { }

  ngOnInit(): void {
    this.categoryService.getCategories().subscribe(res => {
      this.categories = res
    })
  }

  addCategory(){
    this.categoryService.addCategory({name:this.categoryName,characteristics:[],specs:[]})
    this.categoryName = ""
  }

  removeCategory(id){
    this.categoryService.removeCategory(id)
  }

  //Mostrar la sección de editar categorías
  displayEditCategory(category){
    this.categoryToEdit = category
    this.showEditCategory = true
    this.categoryValues.patchValue({categoryName:category.name,characteristicValue: "",characteristicName: ""})
    this.charToEdit = {
      name: "",
      values: []
    }
  }

  //Mostrar el campo de edición de característica y de agregado de valor
  displayEditChar(char,index){
    this.charToEdit = char
    this.charIndex = index
    this.categoryValues.patchValue({characteristicEditName: char.name})
    this.showEditCharacteristic = true
  }

  //Cambiar el nombre de una categoría
  changeCategoryName(){
    let name = this.categoryValues.get('categoryName').value
    this.categoryToEdit.name = name
    this.categoryService.updateCategory(this.categoryToEdit.id,this.categoryToEdit)
  }

  //Agregar una característica a una categoría
  addCharToCategory(){
    let name = this.categoryValues.get('characteristicName').value
    this.categoryToEdit.characteristics.push({name:name,values:[]})
    this.categoryService.updateCategory(this.categoryToEdit.id,this.categoryToEdit)
  }

  //Eliminar una característica de una categoría
  removeCharFromCategory(index){
    this.categoryToEdit.characteristics.splice(index,1)
    this.categoryService.updateCategory(this.categoryToEdit.id,this.categoryToEdit)
  }

  //Cambiar el nombre de una categoría
  changeCharName(){
    let name = this.categoryValues.get('characteristicEditName').value
    this.categoryToEdit.characteristics[this.charIndex].name = name
    this.categoryService.updateCategory(this.categoryToEdit.id,this.categoryToEdit)
  }

  //Agregar valor a una característica
  addValueToChar(){
    let value = this.categoryValues.get('characteristicValue').value
    this.categoryToEdit.characteristics[this.charIndex].values.push(value)
    this.categoryService.updateCategory(this.categoryToEdit.id,this.categoryToEdit)
    setTimeout(()=>this.categoryValues.patchValue({characteristicValue: ""}),500)
  }

  //Eliminar valor de una característica
  removeValueFromChar(charIndex,valueIndex){
    this.categoryToEdit.characteristics[charIndex].values.splice(valueIndex,1)
    this.categoryService.updateCategory(this.categoryToEdit.id,this.categoryToEdit)
  }

  //Agregar una especificación a una categoría
  addSpecToCategory(){
    let name = this.categoryValues.get('spec').value
    if(name!=''){
      this.categoryToEdit.specs.push({name:name,image:""})
      this.categoryService.updateCategory(this.categoryToEdit.id,this.categoryToEdit)
      this.categoryValues.get('spec').reset()
    }else{
      window.alert('Por favor ingrese un nombre para agregar una especificación')
      var mobile = (/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase()));
      if(mobile)alert('Por favor ingrese un nombre para agregar una especificación')
    }
  }

  //Mostrar y esconder modal de especificaciones
  displayEditSpec(index){
    this.specIndex = index
    this.specToEdit = this.categoryToEdit.specs[index]
    this.specEdit = this.specToEdit.name
    if(this.specToEdit.image)this.specImage = this.specToEdit.image
    this.showSpecModal = true
  }

  hideEditSpec(){
    this.specIndex = -1
    this.showSpecModal = false
    this.specImage = ""
  }

  //Eliminar una especificación
  removeSpecFromCategory(index){
    this.categoryToEdit.specs.splice(index,1)
    this.categoryService.updateCategory(this.categoryToEdit.id,this.categoryToEdit)
  }

  //Atrapar cuando el usuario cambia la imagen de una especificación
  onFileChanged(event){
    const imageTemp = event.target.files[0];
    this.specImageFile = event.target.files[0]

    const reader = new FileReader();
    reader.readAsDataURL(imageTemp); 
    reader.onload = (_event) => {
      this.specImage = reader.result;
    }
  }

  //Actualizar una especificación
  updateSpec(){
    let tempImage
    if(this.specImageFile){
      tempImage = new Upload(this.specImageFile)
    }
    const tempSpec = {
      name: this.specEdit,
      image: this.specToEdit.image,
      hasImage: this.specToEdit.image != ""
    }
    this.categoryService.editSpecInCategory(this.categoryToEdit.id,this.categoryToEdit,tempSpec,tempImage,this.specIndex)
    this.hideEditSpec()
  }

}
