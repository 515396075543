import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { SendgridService } from 'src/app/services/sendgrid.service';

declare var gtag;
declare var window;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  errorText
  name = ""
  mail
  password
  termsAccepted = false

  utm_source
  utm_medium
  utm_campaign

  constructor(public fireAuth: AngularFireAuth,
    private router: Router,
    public authService: AuthService,
    public userService: UserService,
    private route: ActivatedRoute,
    private sendgridService: SendgridService) { }

  ngOnInit(): void {
    this.utm_source = this.route.snapshot.queryParams['utm_source'];
    this.utm_medium = this.route.snapshot.queryParams['utm_medium'];
    this.utm_campaign = this.route.snapshot.queryParams['utm_campaign'];
    gtag('event', 'registro_visto', {
      'utm_source': this.utm_source,
      'utm_medium': this.utm_medium,
      'utm_campaign': this.utm_campaign
    })
  }

  /* createUserTest() {
    this.userService.createUserTest({ name: this.name, email: this.mail }).subscribe((res) => {
      console.log(res);
    })
  } */

  createUser() {
    if (this.name != "") {
      this.fireAuth.createUserWithEmailAndPassword(this.mail, this.password).then(user => {
        var uid = user.user.uid
        this.userService.createUserRecord({ id: uid, name: this.name, email: this.mail }).then(() => {
          try {
            this.sendgridService.addContact(this.mail).subscribe((res) => {
              console.log(res)
            })
          } catch (error) { }
          this.completedSignupEvent()
          this.router.navigate(['/mi-cuenta'])
        })
      }
      ).catch(err => {
        if (err.code == "auth/invalid-email" || err.code == "auth/wrong-password") {
          this.errorText = "El correo o la contraseña ingresados no son válidos."
        } else if (err.code == "auth/email-already-in-use") {
          this.errorText = "Este correo ya se encuentra en uso."
        } else if (err.code == "auth/weak-password") {
          this.errorText = "La contraseña debe contener un mínimo de 6 caracteres"
        }
        console.log(err)
      })
    } else {
      this.errorText = "Por favor ingresa un nombre."
    }
  }

  signupWithGoogle() {
    this.authService.googleSignUp(this.name).then((success: any) => {
      console.log(success)
      if (!success.status) {
        alert('Ya existe una cuenta asociada a este correo')
      } else {
        try {
          this.sendgridService.addContact(success.email).subscribe(res => {
            console.log(res)
            this.completedSignupEvent()
            this.router.navigate(['/mi-cuenta'])
          })
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  toggleAcceptTerms() {
    this.termsAccepted = !this.termsAccepted
  }

  signupWithFacebook() {
    this.authService.facebookSignUp(this.name).then(success => {
      console.log(success)
      if (!success.status) {
        alert('Ya existe una cuenta asociada a este correo')
      } else {
        try {
          this.sendgridService.addContact(success.email).subscribe(res => {
            console.log(res)
            this.completedSignupEvent()
            this.router.navigate(['/mi-cuenta'])
          })
        } catch (error) { }
      }
    })
  }

  completedSignupEvent() {
    gtag('event', 'registro_completado', {
      'utm_source': this.utm_source,
      'utm_medium': this.utm_medium,
      'utm_campaign': this.utm_campaign
    })
  }

}