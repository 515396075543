<div class="wrapper">
    <div class="sidebar">
        <!-- <img src="../../../assets/Logos/Gm-navbar-logo.png" alt="" class="sidebar-logo"> -->
        <p class="admin-tab" routerLink="/admin-productos" routerLinkActive="selected-tab">Productos</p>
        <p class="admin-tab" routerLink="/admin-caracteristicas" routerLinkActive="selected-tab">Características</p>
        <p class="admin-tab" routerLink="/admin-pedidos" routerLinkActive="selected-tab">Pedidos</p>
        <p class="admin-tab" routerLink="/admin-perfiles" routerLinkActive="selected-tab">Perfiles</p>
        <p class="admin-tab" routerLink="/admin-cupones" routerLinkActive="selected-tab">Cupones</p>
        <p class="admin-tab" routerLink="/admin-slider" routerLinkActive="selected-tab">Slider</p>
        <p class="admin-tab" routerLink="/admin-campañas" routerLinkActive="selected-tab">Campañas</p>
    </div>
    <div class="content">
        <div class="header">
            <p>Perfiles</p>
            <div class="filters">
                <div class="search-bar">
                    <input type="text" name="search" id="search" (keyup)="search($event.target.value)">
                    <i class="fa fa-search"></i>
                </div>
            </div>
        </div>
        <div class="content-body">
            <div class="profiles">
                <div class="profile" *ngFor="let user of usersFiltered">
                    <p style="font-weight: 600;">{{user.name}}</p>
                    <p>{{user.email}}</p>
                    <p *ngIf="user.birthdate">{{user.birthdate}}</p>
                    <p *ngIf="!user.birthdate">Sin Fecha</p>
                    <p *ngIf="user.orders && user.orders>0">{{user.orders}} pedido(s)</p>
                    <p *ngIf="user.orders==0">Sin pedidos</p>
                    <!-- <p class="edit-product-link">Ver</p> -->
                </div>
            </div>
        </div>
    </div>
</div>