import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  productNumber = 0;
  totalCost = 0;
  discount = 0;
  shippingCost = 0;
  cart: {items: any};

  products

  currentUser
  userID

  constructor(public cartService: CartService, public productService: ProductService, public firebaseAuthService: AuthService, public userService: UserService) { }

  ngOnInit(): void {

    this.firebaseAuthService.getCurrentUser().then((res) => {
      if (res) {
        this.userID = res.uid
        this.userService.getUserById(res.uid).subscribe((res) => {
          this.currentUser = res;
        })
      }
    }).catch(res => { });
    
    this.cartService.getTokens().subscribe((res) => {
      this.productNumber = 0
      this.totalCost = 0
      this.cart = res;
      for (let i of res.items) {
        this.productNumber += i.quantity;
        if(i.discount <= 0)this.totalCost += i.price * i.quantity;
        if(i.discount > 0)this.totalCost += i.discount * i.quantity;
      }
    })
    if (localStorage.getItem('cartGM')) {
      this.cartService.setTokens(JSON.parse(localStorage.getItem('cartGM')));
    } else {
      localStorage.setItem('cartGM', JSON.stringify({items: [] }))
      this.cartService.setTokens({items: [] });
    }
    this.productService.getProducts().subscribe(res => {
      this.products = res
      for(let item of this.cart.items){
        for(let product of this.products){
          if(product.id == item.parentId){
            item.stock = product.subproducts[item.index].stock
            if(item.quantity>item.stock){
              item.quantity = item.stock
            }
          }
        }
      }
      this.cartService.setTokens(this.cart);
      localStorage.setItem('cartGM', JSON.stringify(this.cart))
      this.productNumber = 0
      this.totalCost = 0
      for (let i of this.cart.items) {
        this.productNumber += i.quantity;
        if(i.discount <= 0)this.totalCost += i.price * i.quantity;
        if(i.discount > 0)this.totalCost += i.discount * i.quantity;
      }
    })
  }

  removeFromCart(index) {
    this.cart.items.splice(index, 1);
    this.cartService.setTokens(this.cart);
    localStorage.setItem('cartGM', JSON.stringify(this.cart))
    if (JSON.parse(localStorage.getItem('cartGM')).items.length == 0 && this.userID)this.userService.changeCart(this.userID, false)
  }

  updateCartAmount(index, value,event) {
      if(parseInt(value)>this.cart.items[index].stock){
        value=this.cart.items[index].stock
        event.target.value = this.cart.items[index].stock
      }
      this.cart.items[index].quantity = parseInt(value)
      this.cartService.setTokens(this.cart);
      localStorage.setItem('cartGM', JSON.stringify(this.cart))
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) return true
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
