import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';
import { OrderService } from 'src/app/services/order.service';
import { ShippingService } from 'src/app/services/shipping.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressService } from 'src/app/services/address.service';
import { CouponService } from 'src/app/services/coupon.service';
import { ProductService } from 'src/app/services/product.service';
import * as moment from 'moment';
import * as firebase from 'firebase';
import 'firebase/functions';
import { first } from 'rxjs/operators'
import * as openpay from 'openpay-js';

declare let MercadoPago;

declare let paypal;

declare let gtag;

declare let Stripe;

declare let OpenPay;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  isLoggedIn = true;
  currentUser;
  userID;
  isGuest = false;
  guestMail = '';
  totalCost = 0;
  discount = 0;
  shippingCostOriginal = 0;
  shippingCost = 0;
  productNumber;
  cart;

  showAddressForm = true;
  showPaymentForm = false;
  showShippingRates = false;
  showOxxoPay = false;

  showBankTransfer = false;
  showCashPayment = false;

  shippingRate;
  selectedCarrierId = '';
  selectedCarrierName = 'Por decidir';

  orderData;

  addressList;
  selectedAddress;

  cartTemp;

  couponCode = '';
  couponList;
  couponText = '';
  couponError = '';
  couponValidCode = {
    code: '',
    id: '',
    name: '',
    type: '',
  };

  hasFreeShipping = false;
  freeShippingCoupon = false;

  products;

  hasPayed = false;

  utm_source;
  utm_campaign;
  utm_medium;
  utm_cost;

  totalWeight = 0;
  totalValue = 0;
  totalSize = 0;

  age = 0;

  deviceSessionId = '';

  bankTransfer = {
    name: '',
    clabe: '',
    bank: '',
    agreement: '',
    amount: 0,
    id: '',
    url: ''
  };

  storePayment = {
    url: '',
    id: '',
    reference: '',
    barcode_url: '',
    amount: 0
  }

  card_transaction_id = ''

  mailForm = new FormGroup({
    mail: new FormControl('', [Validators.email, Validators.required]),
  });

  addressInfo = new FormGroup({
    selectedAddress: new FormControl(''),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(14),
    ]),
    city: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(14),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]+$'),
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    street: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(25),
    ]),
    extNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(5),
    ]),
    suburb: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30),
    ]),
    crossStreet: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(35),
    ]),
    reference: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(25),
    ]),
    zipCode: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
    ]),
  });

  paymentInfo = new FormGroup({
    selectedCard: new FormControl(''),
    name: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    month: new FormControl('', Validators.required),
    year: new FormControl('', Validators.required),
    cvc: new FormControl('', Validators.required),
  });

  constructor(
    public firebaseAuthService: AuthService,
    public cartService: CartService,
    public userService: UserService,
    private fns: AngularFireFunctions,
    public spinner: NgxSpinnerService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public orderService: OrderService,
    public shippingService: ShippingService,
    public addressService: AddressService,
    public couponService: CouponService,
    public productService: ProductService,
    private route: ActivatedRoute
  ) { }

  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;
  ngOnInit(): void {
    /* this.fns.useEmulator('localhost', 5001); */
    OpenPay.setId('m3bhmq0oerrym5ucu4ly');
    OpenPay.setApiKey('pk_0a10d68d69594d3cb85c6660fb93c366');
    OpenPay.setSandboxMode(false);
    this.deviceSessionId = OpenPay.deviceData.setup(
      'payment-form',
      'deviceIdHiddenFieldName'
    );
    let utms = JSON.parse(localStorage.getItem('utms'));
    if (utms.campaign) this.utm_campaign = utms.campaign;
    if (utms.source) this.utm_source = utms.source;
    if (utms.medium) this.utm_medium = utms.medium;
    this.cartService.getTokens().subscribe((res) => {
      this.productNumber = 0;
      this.totalCost = 0;
      this.cart = res;
      for (let i of res.items) {
        this.productNumber += i.quantity;
        if (i.discount <= 0) this.totalCost += i.price * i.quantity;
        if (i.discount > 0) this.totalCost += i.discount * i.quantity;
      }
      if (this.totalCost > 1999) this.hasFreeShipping = true;
    });
    if (localStorage.getItem('cartGM')) {
      this.cartService.setTokens(JSON.parse(localStorage.getItem('cartGM')));
    } else {
      localStorage.setItem('cartGM', JSON.stringify({ items: [] }));
      this.cartService.setTokens({ items: [] });
    }
    this.isLoggedIn = false;
    this.firebaseAuthService
      .getCurrentUser()
      .then((res) => {
        if (res) {
          this.userID = res.uid;
          this.userService.getUserById(res.uid).subscribe((res: any) => {
            this.currentUser = res;
            if (res.birthdate) this.age = this.getAge(res.birthdate);
            this.addressService
              .getAddresssByUserId(this.userID)
              .subscribe((res) => {
                this.addressList = res;
                this.activeRoute.queryParams.subscribe((params) => {
                  if (params.address) console.log(JSON.parse(params.address));
                  if (params.status)
                    return this.handlePay(params.status, 'Mercado Pago');
                });
              });
          });
        } else {
          this.activeRoute.queryParams.subscribe((params) => {
            if (params.address) console.log(JSON.parse(params.address));
            if (params.status)
              return this.handlePay(params.status, 'Mercado Pago');
          });
        }
        if (res != undefined) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      })
      .catch((res) => { });
    this.cartService.getTokens().subscribe((res) => {
      this.totalCost = 0;
      for (let i of res.items) {
        if (i.discount <= 0) this.totalCost += i.price * i.quantity;
        if (i.discount > 0) this.totalCost += i.discount * i.quantity;
      }
    });
    this.productService.getProducts().subscribe((res) => {
      this.products = res;
      for (let item of this.cart.items) {
        for (let product of this.products) {
          if (product.id == item.parentId) {
            item.stock = product.subproducts[item.index].stock;
            if (item.quantity > item.stock) {
              item.quantity = item.stock;
            }
          }
        }
      }
      this.productNumber = 0;
      this.totalCost = 0;
      for (let i of this.cart.items) {
        this.productNumber += i.quantity;
        if (i.discount <= 0) this.totalCost += i.price * i.quantity;
        if (i.discount > 0) this.totalCost += i.discount * i.quantity;
      }
      this.utm_cost = this.totalCost;
    });
    if (localStorage.getItem('cartGM')) {
      this.cartService.setTokens(JSON.parse(localStorage.getItem('cartGM')));
    } else {
      localStorage.setItem('cartGM', JSON.stringify({ items: [] }));
      this.cartService.setTokens({ items: [] });
    }
    this.couponService.getCoupons().subscribe((res) => {
      this.couponList = res;
    });

    this.showAddress();

    paypal
      .Buttons({
        style: {
          layout: 'horizontal',
          shape: 'pill',
          label: 'paypal',
          tagline: 'false',
        },
        createOrder: (data, actions) => {
          // This function sets up the details of the transaction, including the amount and line item details.
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: this.totalCost + this.shippingCost - this.discount,
                  currency_code: 'MXN',
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          if (this.addressInfo.get('selectedAddress').value == '') {
            if (!this.isGuest) this.saveAddress();
            this.createOrderWithNewAddress();
          } else {
            this.createOrderWithExistingAddress();
          }
          return actions.order.capture().then((details) => {
            /* this.clearCart() */
            alert(
              'El pago se ha realizado con éxito. Te llegará un correo a la brevedad.'
            );
            this.router.navigate(['/gracias']);
          });
        },
      })
      .render(this.paypalElement.nativeElement);
  }

  getAge(age: string) {
    var year = parseInt(age.substring(0, 4));
    var month = parseInt(age.substring(5, 7));
    var day = parseInt(age.substring(8, 10));
    var today = new Date();
    var userAge = today.getFullYear() - year;
    if (today.getMonth() + 1 < month) {
      userAge--;
    } else if (today.getMonth() + 1 == month) {
      if (today.getDate() < day) {
        userAge--;
      }
    }
    return userAge;
  }

  initOxxo(order_id, firstName, lastName) {
    var stripe = Stripe(
      'pk_live_51IKAceA7TTmHl5UU3Matyt3VTuXr0VuNGTaK2kzHItQ2TWpUopMCNoiKMQQs8Z0bRLhlA8FOIanSn9qA6981uGlp00oK3tP7po'
    );
    this.spinner.show('OxxoLoader');
    let tryToPay = this.fns.httpsCallable('payWithOXXO');
    let data = {
      price: this.utm_cost * 100,
      stripeid: this.currentUser.stripe_id,
      course: {
        name: 'Pedido de GM',
        price: this.utm_cost,
        id: order_id,
      },
    };
    console.log({ ...data });
    tryToPay({ ...data }).subscribe((res) => {
      console.log(res);
      stripe
        .confirmOxxoPayment(res.client_secret, {
          payment_method: {
            billing_details: {
              name: `${firstName} ${lastName}`,
              email: this.currentUser.email,
            },
          },
        })
        .then((result) => {
          this.spinner.hide('OxxoLoader');
          if (result.error) {
            window.alert('Error al pagar.');
            this.spinner.hide('OxxoLoader');
          }
          this.router.navigate(['/mi-cuenta']);
        });
    }),
      (error) => this.spinner.hide('OxxoLoader'); // CALLABLE
  }

  async handlePay(status, type) {
    if (status == 'approved' && !this.hasPayed) {
      this.addressInfo.setValue(JSON.parse(localStorage.getItem('address')));
      this.selectedCarrierId = localStorage.getItem('carrierID');
      this.cartTemp = JSON.parse(localStorage.getItem('cartPay'));
      this.totalWeight = JSON.parse(localStorage.getItem('totalWeight'));
      this.totalValue = JSON.parse(localStorage.getItem('totalValue'));
      this.totalSize = JSON.parse(localStorage.getItem('totalSize'));
      if (this.addressInfo.get('selectedAddress').value == '') {
        if (!this.isGuest) this.saveAddress();
        this.createOrderWithNewAddress();
      } else {
        this.createOrderWithExistingAddress();
      }
      this.hasPayed = true;
      /* this.clearCart() */
      alert(
        'El pago se ha realizado con éxito. Te llegará un correo a la brevedad.'
      );
      this.router.navigate(['/gracias']);
    } else if (this.hasPayed) {
      this.router.navigate(['/gracias']);
    }
  }

  continueAsGuest() {
    this.guestMail = this.mailForm.get('mail').value;
    this.isGuest = true;
  }

  showAddress() {
    this.showAddressForm = true;
    this.showPaymentForm = false;
  }

  showPayment() {
    let totalWeight = 0,
      totalValue = 0,
      totalSize = 0;
    this.cart.items.forEach((item) => {
      totalWeight += item.weight * item.quantity;
      if (item.discount <= 0) totalValue += item.price * item.quantity;
      if (item.discount > 0) totalValue += item.discount * item.quantity;
      totalSize += item.size * item.quantity || 0;
    });
    this.totalWeight = totalWeight;
    this.totalValue = totalValue;
    this.totalSize = totalSize;
    this.calculateShipping();
  }

  clearCart() {
    for (let item of this.cart.items) {
      for (let product of this.products) {
        if (product.id == item.parentId) {
          product.subproducts[item.index].stock -= item.quantity;
          this.productService.updateProduct(product.id, product);
        }
      }
    }
    localStorage.setItem('cartGM', JSON.stringify({ items: [] }));
    this.cartService.setTokens({ items: [] });
    if (this.userID) this.userService.changeCart(this.userID, false);
  }

  //Guardar la dirección de un cliente
  saveAddress() {
    let tempAddress = this.addressInfo.getRawValue();
    tempAddress.uid = this.userID;
    this.addressService.addAddress(tempAddress);
  }

  async createToken() {
    let token
    token = await new Promise((resolve, reject) => {
      OpenPay.token.extractFormAndCreate('payment-form', (res) => {
        resolve(res)
      }, (error) => {
        console.log(error);
        reject(error)
      })
      /* console.log(res);
      token = res
      let cost = Math.round(
        (this.totalCost +
          this.shippingCost -
          this.discount +
          Number.EPSILON) *
        100
      ) / 100
      console.log(token.data.id)
      let tempCharge = {
        source_id: token.data.id,
        method: 'card',
        amount:
          cost,
        currency: 'MXN',
        description: 'Cargo de GM e-shop',
        use_3d_secure: true,
        redirect_url: `https://gmeshop.mx/#/gracias`,
        device_session_id: this.deviceSessionId,
      };
      this.userService
        .createOpenPayCharge(tempCharge, this.currentUser.openpay_id)
        .pipe(first()).subscribe(async (res: any) => {
          console.log(res);
          this.spinner.hide('paymentSpinner');
          document.location.href = res.payment_method.url
        }, err => {
          console.log(err);
          this.spinner.hide('paymentSpinner');
        }) */

    })

    console.log(token);


    /* let tempCard = {
      holder_name: this.paymentInfo.get('name').value,
      card_number: this.paymentInfo.get('number').value,
      cvv2: this.paymentInfo.get('cvc').value,
      expiration_month: this.paymentInfo.get('month').value,
      expiration_year: this.paymentInfo.get('year').value,
    };
    let token = this.userService.createOpenpayToken({ ...tempCard })
    console.log(token); */
  }

  /* let tempCard = {
    holder_name: this.paymentInfo.get('name').value,
    card_number: this.paymentInfo.get('number').value,
    cvv2: this.paymentInfo.get('cvc').value,
    expiration_month: this.paymentInfo.get('month').value,
    expiration_year: this.paymentInfo.get('year').value,
  }; */
  async payWithOpenPay() {
    this.spinner.show('paymentSpinner');
    let card: any = {};
    let token: any = {}
    if (this.paymentInfo.get('selectedCard').value == '') {
      token = await new Promise((resolve, reject) => {
        OpenPay.token.extractFormAndCreate('payment-form', (res) => {
          resolve(res)
        }, (error) => {
          console.log(error);
          reject(error)
        })
      })

      try {
        card = await this.userService.createOpenpayCardFromToken({ user_id: this.currentUser.openpay_id, token_id: token.data.id, device_session_id: this.deviceSessionId });
        console.log(card);
      } catch (err) {
        console.log(err);
        this.spinner.hide('paymentSpinner');
        return alert('Error con la tarjeta. Favor de verificar los datos.')
      }
      if (card.error) {
        this.spinner.hide('paymentSpinner');
        console.log(card);
        if (card.error.description == 'The card was reported as stolen' || card.error.description == 'Card declined (k)') card.error.description = 'Tarjeta Declinada.'
        if (card.error.description == 'The card was declined by the bank') card.error.description = 'Tarjeta Rechazada por el Banco.'
        if (card.error.description == 'The card has expired') card.error.description = 'La Tarjeta ha Expirado.'
        if (card.error.code == 3003) card.error.description = 'Tarjeta Declinada.'
        return alert(card.error.description)
      }
      if (!this.currentUser.paymentMethods) this.currentUser.paymentMethods = []
      this.currentUser.paymentMethods.push(card);
      this.userService.updatePaymentMethods(
        this.userID,
        this.currentUser.paymentMethods
      );
    } else {
      card =
        this.currentUser.paymentMethods[
        this.paymentInfo.get('selectedCard').value
        ];
    }
    let cost = Math.round(
      (this.totalCost +
        this.shippingCost -
        this.discount +
        Number.EPSILON) *
      100
    ) / 100
    let tempCharge: any = {
      source_id: card.id,
      method: 'card',
      amount:
        cost,
      currency: 'MXN',
      description: 'Cargo de GM e-shop',
      use_3d_secure: true,
      redirect_url: `https://gmeshop.mx/#/gracias`,
      device_session_id: this.deviceSessionId,
    };
    if (this.paymentInfo.get('selectedCard').value != '') tempCharge.cvv2 = this.paymentInfo.get('cvc').value,
      console.log(tempCharge);
    this.userService
      .createOpenPayCharge(tempCharge, this.currentUser.openpay_id)
      .pipe(first()).subscribe(async (res: any) => {
        this.card_transaction_id = res.id
        let order_id = await this.createOrderFor3DSecure()
        console.log(res);
        this.spinner.hide('paymentSpinner');
        document.location.href = res.payment_method.url
      }, err => {
        console.log(err);
        this.spinner.hide('paymentSpinner');
      })
  }

  async payWithOpenPayCash() {
    this.spinner.show('paymentSpinner');
    let dueDate = new Date()
    dueDate.setDate(dueDate.getDate() + 3);
    dueDate.setMonth(dueDate.getMonth() + 1);
    let year = dueDate.getFullYear();
    let month = dueDate.getMonth().toString();
    let day = dueDate.getDate().toString();
    let tempCharge = {
      method: 'store',
      due_date: `${year}-${month}-${day}T23:59:59Z`,
      amount:
        Math.round(
          (this.totalCost +
            this.shippingCost -
            this.discount +
            Number.EPSILON) *
          100
        ) / 100,
      currency: 'MXN',
      description: 'Pago a GM e-shop en banco',
      device_session_id: this.deviceSessionId,
    };
    console.log(tempCharge);
    this.userService
      .createOpenPayCharge(tempCharge, this.currentUser.openpay_id)
      .pipe(first()).subscribe((res: any) => {
        console.log(res);
        this.spinner.hide('paymentSpinner');
        if (res.status == 'in_progress') {
          this.showCashPayment = true;
          this.showPaymentForm = false;
          this.storePayment.url = `https://dashboard.openpay.mx/paynet-pdf/m3bhmq0oerrym5ucu4ly/transaction/${res.id}`,
            this.storePayment.id = res.id,
            this.storePayment.reference = res.payment_method.reference,
            this.storePayment.barcode_url = res.payment_method.barcode_url,
            this.storePayment.amount =
            Math.round(
              (this.totalCost +
                this.shippingCost -
                this.discount +
                Number.EPSILON) *
              100
            ) / 100;
        } else {
          alert('Error al generar recibo de transferencia. Por favor intenta más tarde.')
        }
      }, error => {
        console.log(error)
        alert('Error al generar recibo de transferencia. Por favor intenta más tarde.')
        this.spinner.hide('paymentSpinner');
      })

  }

  async payWithOpenPayBank() {
    this.spinner.show('paymentSpinner');
    let dueDate = new Date()
    dueDate.setDate(dueDate.getDate() + 3);
    dueDate.setMonth(dueDate.getMonth() + 1);
    let year = dueDate.getFullYear();
    let month = dueDate.getMonth().toString();
    let day = dueDate.getDate().toString();
    let tempCharge = {
      due_date: `${year}-${month}-${day}T23:59:59Z`,
      method: 'bank_account',
      amount:
        Math.round(
          (this.totalCost +
            this.shippingCost -
            this.discount +
            Number.EPSILON) *
          100
        ) / 100,
      currency: 'MXN',
      description: 'Pago a GM e-shop en banco',
      device_session_id: this.deviceSessionId,
    };
    this.userService
      .createOpenPayCharge(tempCharge, this.currentUser.openpay_id).pipe(first()).subscribe((res: any) => {
        console.log(res);
        this.spinner.hide('paymentSpinner');
        if (res.status == 'in_progress') {
          this.showBankTransfer = true;
          this.showPaymentForm = false;
          this.bankTransfer.id = res.id
          this.bankTransfer.name = res.payment_method.name;
          this.bankTransfer.clabe = res.payment_method.clabe;
          this.bankTransfer.bank = res.payment_method.bank;
          this.bankTransfer.agreement = res.payment_method.agreement
          this.bankTransfer.amount =
            Math.round(
              (this.totalCost +
                this.shippingCost -
                this.discount +
                Number.EPSILON) *
              100
            ) / 100;
          this.bankTransfer.url = `https://dashboard.openpay.mx/spei-pdf/m3bhmq0oerrym5ucu4ly/${res.id}`
        } else {
          alert('Error al generar recibo de transferencia. Por favor intenta más tarde.')
        }
      }, error => {
        console.log(error)
        alert('Error al generar recibo de transferencia. Por favor intenta más tarde.')
        this.spinner.hide('paymentSpinner');

      })
  }

  async createOrderWithNewAddress() {
    let userID;
    let userName = 'Invitado';
    if (this.cartTemp) this.cart = this.cartTemp;
    console.log(this.cart);
    if (!this.isGuest) {
      userID = this.userID;
      userName = this.currentUser.name;
    }
    if (this.isGuest) userID = '';
    console.log(this.orderData);
    this.orderService
      .addOrder(
        {
          age: this.age,
          uid: userID,
          firstName: this.addressInfo.get('firstName').value,
          lastName: this.addressInfo.get('lastName').value,
          email: this.addressInfo.get('email').value,
          phone: this.addressInfo.get('phone').value,
          street: this.addressInfo.get('street').value,
          extNumber: this.addressInfo.get('extNumber').value,
          suburb: this.addressInfo.get('suburb').value,
          crossStreet: this.addressInfo.get('crossStreet').value,
          reference: this.addressInfo.get('reference').value,
          zipCode: this.addressInfo.get('zipCode').value,
          city: this.addressInfo.get('city').value,
          products: this.cart.items,
          shippingCost: this.shippingCost,
          subtotal: this.totalCost,
          discount: this.discount,
          discountCode: this.couponValidCode,
          status: 'Esperando envío',
          username: userName,
          orderId: '',
          guide: '',
          tracker: '',
          totalCost: this.totalCost + this.shippingCost - this.discount,
          date: new Date(),
          totalSize: this.totalSize,
          totalWeight: this.totalWeight,
          totalValue: this.totalValue,
        },
        this.cart,
        this.products,
        this.userID
      )
      .then((res) => {
        gtag('event', 'producto_completado', {
          utm_source: this.utm_source,
          utm_medium: this.utm_medium,
          utm_campaign: this.utm_campaign,
          pago_total: this.utm_cost,
        });
      });
  }

  async createOrderWithExistingAddress() {
    if (this.cartTemp) this.cart = this.cartTemp;
    console.log(this.cart);
    let address =
      this.addressList[this.addressInfo.get('selectedAddress').value];
    const userName = this.currentUser.name;
    this.orderService
      .addOrder(
        {
          age: this.age,
          uid: this.userID,
          firstName: address.firstName,
          lastName: address.lastName,
          email: address.email,
          phone: address.phone,
          street: address.street,
          extNumber: address.extNumber,
          suburb: address.suburb,
          crossStreet: address.crossStreet,
          reference: address.reference,
          zipCode: address.zipCode,
          city: address.city || '',
          products: this.cart.items,
          shippingCost: this.shippingCost,
          subtotal: this.totalCost,
          discount: this.discount,
          discountCode: this.couponValidCode,
          status: 'Esperando envío',
          username: userName,
          orderId: '',
          guide: '',
          tracker: '',
          totalCost: this.totalCost + this.shippingCost - this.discount,
          date: new Date(),
          totalSize: this.totalSize,
          totalWeight: this.totalWeight,
          totalValue: this.totalValue,
        },
        this.cart,
        this.products,
        this.userID
      )
      .then((res) => {
        gtag('event', 'producto_completado', {
          utm_source: this.utm_source,
          utm_medium: this.utm_medium,
          utm_campaign: this.utm_campaign,
          pago_total: this.utm_cost,
        });
      });
  }

  createOrderFor3DSecure() {
    let tempOrder: any = {}
    if (this.addressInfo.get('selectedAddress').value == '') {
      tempOrder.firstName = this.addressInfo.get('firstName').value
      tempOrder.lastName = this.addressInfo.get('lastName').value
      tempOrder.email = this.addressInfo.get('email').value
      tempOrder.phone = this.addressInfo.get('phone').value
      tempOrder.street = this.addressInfo.get('street').value
      tempOrder.extNumber = this.addressInfo.get('extNumber').value
      tempOrder.suburb = this.addressInfo.get('suburb').value
      tempOrder.crossStreet = this.addressInfo.get('crossStreet').value
      tempOrder.reference = this.addressInfo.get('reference').value
      tempOrder.zipCode = this.addressInfo.get('zipCode').value
      tempOrder.city = this.addressInfo.get('city').value
      this.saveAddress()
    } else {
      let address = this.addressList[this.addressInfo.get('selectedAddress').value];
      tempOrder.firstName = address.firstName
      tempOrder.lastName = address.lastName
      tempOrder.email = address.email
      tempOrder.phone = address.phone
      tempOrder.street = address.street
      tempOrder.extNumber = address.extNumber
      tempOrder.suburb = address.suburb
      tempOrder.crossStreet = address.crossStreet
      tempOrder.reference = address.reference
      tempOrder.zipCode = address.zipCode
      tempOrder.city = address.city
    }
    tempOrder.age = this.age || 0
    tempOrder.uid = this.userID || ''
    tempOrder.shippingCost = this.shippingCost
    tempOrder.subtotal = this.totalCost
    tempOrder.discount = this.discount
    tempOrder.discountCode = this.couponValidCode
    tempOrder.status = 'Esperando Pago'
    tempOrder.username = this.currentUser.name
    tempOrder.orderId = ''
    tempOrder.guide = ''
    tempOrder.tracker = ''
    tempOrder.products = this.cart.items
    tempOrder.totalCost = this.totalCost + this.shippingCost - this.discount
    tempOrder.date = new Date()
    tempOrder.totalSize = this.totalSize
    tempOrder.totalWeight = this.totalWeight
    tempOrder.totalValue = this.totalValue
    tempOrder.openpay_id = this.currentUser.openpay_id || ''
    tempOrder.transaction_id = this.card_transaction_id
    tempOrder.payment_method = 'Tarjeta'
    return this.orderService.addOrder(tempOrder, this.cart, this.products, this.userID).then(async (res) => {
      gtag('event', 'producto_completado', {
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        pago_total: this.utm_cost,
      });
      return await res
    })
  }

  async createOrderWithBankTransfer() {
    this.spinner.show('showSpinner');
    if (this.addressInfo.get('selectedAddress').value == '') {
      this.saveAddress();
      let userID;
      let userName = 'Invitado';
      if (this.cartTemp) this.cart = this.cartTemp;
      console.log(this.cart);
      if (!this.isGuest) {
        userID = this.userID;
        userName = this.currentUser.name;
      }
      if (this.isGuest) userID = '';
      console.log(this.orderData);
      await this.orderService.addOrder(
        {
          age: this.age,
          uid: userID,
          firstName: this.addressInfo.get('firstName').value,
          lastName: this.addressInfo.get('lastName').value,
          email: this.addressInfo.get('email').value,
          phone: this.addressInfo.get('phone').value,
          street: this.addressInfo.get('street').value,
          extNumber: this.addressInfo.get('extNumber').value,
          suburb: this.addressInfo.get('suburb').value,
          crossStreet: this.addressInfo.get('crossStreet').value,
          reference: this.addressInfo.get('reference').value,
          zipCode: this.addressInfo.get('zipCode').value,
          city: this.addressInfo.get('city').value,
          products: this.cart.items,
          shippingCost: this.shippingCost,
          subtotal: this.totalCost,
          discount: this.discount,
          discountCode: this.couponValidCode,
          status: 'Esperando Pago',
          username: userName,
          orderId: '',
          guide: '',
          tracker: '',
          date: new Date(),
          totalSize: this.totalSize,
          totalWeight: this.totalWeight,
          totalValue: this.totalValue,
          totalCost:
            Math.round(
              (this.totalCost +
                this.shippingCost -
                this.discount +
                Number.EPSILON) *
              100
            ) / 100,
          pdfRoute: this.bankTransfer.url,
          clabe: this.bankTransfer.clabe,
          bankReference: this.bankTransfer.agreement,
          bankConcept: this.bankTransfer.name,
          bank: this.bankTransfer.bank,
          transaction_id: this.bankTransfer.id,
          openpay_id: this.currentUser.openpay_id || '',
          payment_method: 'Transferencia Bancaria'
        },
        this.cart,
        this.products,
        this.userID
      );
      gtag('event', 'producto_completado', {
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        pago_total: this.utm_cost,
      });
    } else {
      if (this.cartTemp) this.cart = this.cartTemp;
      console.log(this.cart);
      let address =
        this.addressList[this.addressInfo.get('selectedAddress').value];
      const userName = this.currentUser.name;
      await this.orderService.addOrder(
        {
          age: this.age,
          uid: this.userID,
          firstName: address.firstName,
          lastName: address.lastName,
          email: address.email,
          phone: address.phone,
          street: address.street,
          extNumber: address.extNumber,
          suburb: address.suburb,
          crossStreet: address.crossStreet,
          reference: address.reference,
          zipCode: address.zipCode,
          city: address.city || '',
          products: this.cart.items,
          shippingCost: this.shippingCost,
          subtotal: this.totalCost,
          discount: this.discount,
          discountCode: this.couponValidCode,
          status: 'Esperando Pago',
          username: userName,
          orderId: '',
          guide: '',
          tracker: '',
          date: new Date(),
          totalSize: this.totalSize,
          totalWeight: this.totalWeight,
          totalValue: this.totalValue,
          totalCost:
            Math.round(
              (this.totalCost +
                this.shippingCost -
                this.discount +
                Number.EPSILON) *
              100
            ) / 100,
          pdfRoute: this.bankTransfer.url,
          clabe: this.bankTransfer.clabe,
          bankReference: this.bankTransfer.agreement,
          bankConcept: this.bankTransfer.name,
          bank: this.bankTransfer.bank,
          transaction_id: this.bankTransfer.id,
          openpay_id: this.currentUser.openpay_id || '',
          payment_method: 'Transferencia Bancaria'
        },
        this.cart,
        this.products,
        this.userID
      );
      gtag('event', 'producto_completado', {
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        pago_total: this.utm_cost,
      });
    }
    this.spinner.hide('genericSpinner');
    /* this.clearCart() */
    this.router.navigate(["/gracias"])
  }

  async createOrderWithCashPayment() {
    this.spinner.show('showSpinner');
    if (this.addressInfo.get('selectedAddress').value == '') {
      this.saveAddress();
      let userID;
      let userName = 'Invitado';
      if (this.cartTemp) this.cart = this.cartTemp;
      console.log(this.cart);
      if (!this.isGuest) {
        userID = this.userID;
        userName = this.currentUser.name;
      }
      if (this.isGuest) userID = '';
      console.log(this.orderData);
      await this.orderService.addOrder(
        {
          age: this.age,
          uid: userID,
          firstName: this.addressInfo.get('firstName').value,
          lastName: this.addressInfo.get('lastName').value,
          email: this.addressInfo.get('email').value,
          phone: this.addressInfo.get('phone').value,
          street: this.addressInfo.get('street').value,
          extNumber: this.addressInfo.get('extNumber').value,
          suburb: this.addressInfo.get('suburb').value,
          crossStreet: this.addressInfo.get('crossStreet').value,
          reference: this.addressInfo.get('reference').value,
          zipCode: this.addressInfo.get('zipCode').value,
          city: this.addressInfo.get('city').value,
          products: this.cart.items,
          shippingCost: this.shippingCost,
          subtotal: this.totalCost,
          discount: this.discount,
          discountCode: this.couponValidCode,
          status: 'Esperando Pago',
          username: userName,
          orderId: '',
          guide: '',
          tracker: '',
          date: new Date(),
          totalSize: this.totalSize,
          totalWeight: this.totalWeight,
          totalValue: this.totalValue,
          totalCost:
            Math.round(
              (this.totalCost +
                this.shippingCost -
                this.discount +
                Number.EPSILON) *
              100
            ) / 100,
          pdfRoute: this.storePayment.url,
          transaction_id: this.storePayment.id,
          openpay_id: this.currentUser.openpay_id || '',
          paymentReference: this.storePayment.reference,
          barcode_url: this.storePayment.barcode_url,
          payment_method: 'Pago en efectivo'
        },
        this.cart,
        this.products,
        this.userID
      );
      gtag('event', 'producto_completado', {
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        pago_total: this.utm_cost,
      });
    } else {
      if (this.cartTemp) this.cart = this.cartTemp;
      console.log(this.cart);
      let address =
        this.addressList[this.addressInfo.get('selectedAddress').value];
      const userName = this.currentUser.name;
      await this.orderService.addOrder(
        {
          age: this.age,
          uid: this.userID,
          firstName: address.firstName,
          lastName: address.lastName,
          email: address.email,
          phone: address.phone,
          street: address.street,
          extNumber: address.extNumber,
          suburb: address.suburb,
          crossStreet: address.crossStreet,
          reference: address.reference,
          zipCode: address.zipCode,
          city: address.city || '',
          products: this.cart.items,
          shippingCost: this.shippingCost,
          subtotal: this.totalCost,
          discount: this.discount,
          discountCode: this.couponValidCode,
          status: 'Esperando Pago',
          username: userName,
          orderId: '',
          guide: '',
          tracker: '',
          date: new Date(),
          totalSize: this.totalSize,
          totalWeight: this.totalWeight,
          totalValue: this.totalValue,
          totalCost:
            Math.round(
              (this.totalCost +
                this.shippingCost -
                this.discount +
                Number.EPSILON) *
              100
            ) / 100,
          pdfRoute: this.storePayment.url,
          transaction_id: this.storePayment.id,
          openpay_id: this.currentUser.openpay_id || '',
          paymentReference: this.storePayment.reference,
          barcode_url: this.storePayment.barcode_url,
          payment_method: 'Pago en efectivo'
        },
        this.cart,
        this.products,
        this.userID
      );
      gtag('event', 'producto_completado', {
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_campaign: this.utm_campaign,
        pago_total: this.utm_cost,
      });
    }
    this.spinner.hide('genericSpinner');
    /* this.clearCart() */
    this.router.navigate(["/gracias"])
  }

  selectCarrier(id, cost, hasFreeShipping, name) {
    this.selectedCarrierId = id;
    this.shippingCost = cost;
    this.shippingCostOriginal = cost;
    this.selectedCarrierName = name;
    if (hasFreeShipping || this.freeShippingCoupon)
      this.discount = this.shippingCost;
  }

  initMercadoPago() {
    const tryToPay = this.fns.httpsCallable('payWithMercadoPago');
    let tempAddress;
    if (this.addressInfo.get('selectedAddress').value != '') {
      tempAddress =
        this.addressList[this.addressInfo.get('selectedAddress').value];
    } else {
      tempAddress = this.addressInfo.getRawValue();
    }
    delete tempAddress.id;
    delete tempAddress.uid;
    if (!tempAddress.selectedAddress)
      tempAddress.selectedAddress =
        this.addressInfo.get('selectedAddress').value;
    localStorage.setItem('address', JSON.stringify(tempAddress));
    localStorage.setItem('carrierID', JSON.stringify(this.selectedCarrierId));
    localStorage.setItem('cartPay', JSON.stringify(this.cart));
    let totalWeight = 0,
      totalValue = 0,
      totalSize = 0;
    this.cart.items.forEach((item) => {
      totalWeight += item.weight * item.quantity;
      if (item.discount <= 0) totalValue += item.price * item.quantity;
      if (item.discount > 0) totalValue += item.discount * item.quantity;
      totalSize += item.size * item.quantity || 0;
    });
    this.totalWeight = totalWeight;
    this.totalValue = totalValue;
    this.totalSize = totalSize;
    localStorage.setItem('totalWeight', JSON.stringify(this.totalWeight));
    localStorage.setItem('totalValue', JSON.stringify(this.totalValue));
    localStorage.setItem('totalSize', JSON.stringify(this.totalSize));
    let purchaseData = {
      preference: {},
      username: '',
      usermail: '',
    };
    if (!this.isGuest) {
      purchaseData.username = this.currentUser.name;
      purchaseData.usermail = this.currentUser.email;
    } else {
      purchaseData.username = this.addressInfo.get('firstName').value;
      purchaseData.usermail = this.guestMail;
    }
    purchaseData.preference = {
      items: [
        {
          title: 'Pedido en GM',
          unit_price: this.totalCost + this.shippingCost - this.discount,
          quantity: 1,
        },
      ],
      payer: {
        name: purchaseData.username,
        email: purchaseData.usermail,
      },
      back_urls: {
        success: `gmeshop.mx/#/pago`,
        failure: `gmeshop.mx/#/pago`,
        pending: `gmeshop.mx/#/pago`,
      },
      payment_methods: {
        excluded_payment_types: [
          {
            id: 'ticket',
          },
          {
            id: 'atm',
          },
          {
            id: 'digital_wallet',
          },
        ],
        installments: 1,
      },
      statement_descriptor: 'GMe-shop-',
    };
    tryToPay(purchaseData).subscribe((res) => {
      const mp = new MercadoPago(
        'APP_USR-d8771e74-9c62-4478-ab0c-172512305f5a',
        {
          locale: 'es-MX',
        }
      );
      mp.checkout({
        preference: {
          id: res,
        },
        autoOpen: true,
      });
    });
  }

  //EnvioClick
  async calculateShipping() {
    let totalHeight = 17,
      totalWidth = 20,
      totalLength = 20,
      totalWeight = 0,
      totalValue = 0,
      totalSize = 0;
    this.cart.items.forEach((item) => {
      totalWeight += item.weight * item.quantity;
      if (item.discount <= 0) totalValue += item.price * item.quantity;
      if (item.discount > 0) totalValue += item.discount * item.quantity;
      totalSize += item.size * item.quantity || 0;
    });
    if (totalSize > 5) {
      totalHeight = 16;
      totalWidth = 16;
      totalLength = 25;
    }
    this.totalWeight = totalWeight;
    this.totalValue = totalValue;
    this.totalSize = totalSize;
    let tempAddress;
    if (this.addressInfo.get('selectedAddress').value != '') {
      tempAddress =
        this.addressList[this.addressInfo.get('selectedAddress').value];
    } else {
      tempAddress = this.addressInfo.getRawValue();
    }
    if (this.totalCost < 2000) {
      this.spinner.show('shippingSpinner');
      let tempDate = new Date();
      tempDate.setDate(tempDate.getDate() + 1);
      tempDate.setMonth(tempDate.getMonth() + 1);
      let year = tempDate.getFullYear();
      let month = tempDate.getMonth().toString();
      let day = tempDate.getDate().toString();
      if (parseInt(month) < 10) month = '0' + month;
      if (parseInt(day) < 10) day = '0' + day;
      let tempRequest = {
        destinationPostalCode: tempAddress.zipCode,
        destinationCity: tempAddress.city,
        weight: totalWeight,
        height: totalHeight,
        width: totalWidth,
        length: totalLength,
        shippingDate: `${year}-${month}-${day}`,
      };
      let getRates = this.fns.httpsCallable('getShippingRates');
      getRates(tempRequest).subscribe(
        (res: any) => {
          let product = res.find((x) => x.productCode == 'G');
          this.spinner.hide('shippingSpinner');
          this.shippingCost = product.totalPrice[0].price;
          if (this.freeShippingCoupon) this.shippingCost = 0
          this.shippingCostOriginal = product.totalPrice[0].price;
          this.shippingRate = product;
          this.addressInfo.disable();
          this.showPaymentForm = true;
          this.showAddressForm = false;
        },
        (error) => {
          window.alert(
            'Error al calcular envío, por favor intente de nuevo más tarde'
          );
          var mobile =
            /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
              navigator.userAgent.toLowerCase()
            );
          if (mobile)
            alert(
              'Error al calcular envío, por favor intente de nuevo más tarde'
            );
          this.spinner.hide('shippingSpinner');
        }
      );
    } else {
      this.addressInfo.disable();
      this.showPaymentForm = true;
      this.showAddressForm = false;
    }
  }

  applyCoupon() {
    let code = this.couponCode;
    this.freeShippingCoupon = false;
    if (this.shippingCostOriginal != 0)
      this.shippingCost = this.shippingCostOriginal;
    let validCoupon = this.couponList.filter((x) => x.code == code);
    if (validCoupon.length == 0) {
      this.couponError = 'Cupón inválido';
      this.couponText = '';
    }
    if (validCoupon.length > 0) {
      let tempCoupon = validCoupon[0];
      this.couponError = '';
      this.couponValidCode = tempCoupon;
      if (tempCoupon.type == 'fijo') {
        this.couponText = `Cupón ${tempCoupon.name} aplicado, válido por $${tempCoupon.amount} de descuento`;
        this.discount = tempCoupon.amount;
      }
      if (tempCoupon.type == 'porcentaje') {
        this.couponText = `Cupón ${tempCoupon.name} aplicado, válido por ${tempCoupon.amount}% de descuento`;
        this.discount = this.totalCost * (tempCoupon.amount / 100);
      }
      if (tempCoupon.type == 'envios') {
        this.couponText = `Cupón ${tempCoupon.name} aplicado, válido por envios gratis.`;
        this.freeShippingCoupon = true;
        this.discount = this.shippingCost;
      }
    }
  }

  createShippingRequest() {
    let totalHeight = 17,
      totalWidth = 20,
      totalLength = 20,
      totalWeight = 0,
      totalValue = 0,
      totalSize = 0;
    this.cart.items.forEach((item) => {
      totalWeight += item.weight * item.quantity;
      if (item.discount <= 0) totalValue += item.price * item.quantity;
      if (item.discount > 0) totalValue += item.discount * item.quantity;
      totalSize += item.size * item.quantity || 0;
    });
    if (totalSize > 5) {
      totalHeight = 16;
      totalWidth = 16;
      totalLength = 25;
    }
    let tempAddress;
    if (this.addressInfo.get('selectedAddress').value != '') {
      tempAddress =
        this.addressList[this.addressInfo.get('selectedAddress').value];
    } else {
      tempAddress = this.addressInfo.getRawValue();
    }
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var date = `${yyyy}-${mm}-${dd}`;

    let tempReference = 'Pedido GM';

    if (!this.isGuest) tempReference = this.currentUser.name;

    const request = {
      idRate: this.selectedCarrierId,
      myShipmentReference: tempReference,
      requestPickup: true,
      pickupDate: date,
      insurance: false,
      thermalLabel: false,
      package: {
        description: 'Shipment',
        contentValue: totalValue,
        weight: totalWeight,
        height: totalHeight,
        width: totalWidth,
        length: totalLength,
      },
      origin: {
        firstName: 'Leonardo',
        lastName: 'Guerrero',
        email: 'leo.guemtz@gmail.com',
        phone: '5534712452',
        street: 'Coscomate',
        number: '71',
        suburb: 'Toriello Guerra',
        crossStreet: 'NA',
        reference: 'NA',
        zipCode: '14050',
      },
      destination: {
        firstName: tempAddress.firstName,
        lastName: tempAddress.lastName,
        email: tempAddress.email,
        phone: tempAddress.phone,
        street: tempAddress.street,
        number: tempAddress.extNumber,
        suburb: tempAddress.suburb,
        crossStreet: tempAddress.crossStreet,
        reference: tempAddress.reference,
        zipCode: tempAddress.zipCode,
      },
    };
    return new Promise((resolve, reject) => {
      this.shippingService.createShippingOrder(request).subscribe((res) => {
        this.orderData = res;
        resolve(res);
      });
    });
  }
}



/* payWithStripe() {
  this.spinner.show('paymentSpinner');
  let payment_methods = [];
  let card_id;
  let card_details;
  var payAmount = Math.round(
    (this.totalCost + this.shippingCost - this.discount) * 100
  );
  const pay = this.fns.httpsCallable('pay');
  const newPaymentMethod = this.fns.httpsCallable('newPaymentMethod');
  if (this.paymentInfo.get('selectedCard').value != '') {
    let selectedCard =
      this.currentUser.paymentMethods[
        this.paymentInfo.get('selectedCard').value
      ];
    pay({
      id: this.currentUser.stripe_id,
      amount: payAmount,
      card_id: selectedCard.stripe_id,
    }).subscribe((res) => {
      if (res.success) {
        //Si es una nueva dirección, guardarla en la base de datos
        if (this.addressInfo.get('selectedAddress').value == '') {
          this.saveAddress();
          this.createOrderWithNewAddress();
        } else {
          this.createOrderWithExistingAddress();
        }
        this.spinner.hide('paymentSpinner');
        alert(
          'El pago se ha realizado con éxito. Te llegará un correo a la brevedad.'
        );
        this.router.navigate(['/gracias']);
      } else {
        alert('Error al pagar. Porfavor intenta más tarde.');
        console.log(res);
        this.spinner.hide('paymentSpinner');
      }
    });
  } else {
    newPaymentMethod({
      card: this.paymentInfo.value,
      stripe_id: this.currentUser.stripe_id,
    }).subscribe(
      (res) => {
        card_id = res.stripe_id;
        card_details = res;
        pay({
          id: this.currentUser.stripe_id,
          amount: payAmount,
          card_id: card_id,
        }).subscribe((res) => {
          if (res.success) {
            if (this.currentUser.paymentMethods)
              payment_methods = this.currentUser.paymentMethods;
            if (card_details.brand == 'visa')
              card_details.image = '/assets/Logos/visa-logo.png';
            if (card_details.brand == 'mastercard')
              card_details.image = '/assets/Logos/mastercard-logo.png';
            card_details.expirationYear = this.paymentInfo.get('year').value;
            card_details.expirationMonth =
              this.paymentInfo.get('month').value;
            payment_methods.push(card_details);
            this.userService.updatePaymentMethods(
              this.userID,
              payment_methods
            );
            //Si es una nueva dirección, guardarla en la base de datos
            if (this.addressInfo.get('selectedAddress').value == '') {
              this.saveAddress();
              this.createOrderWithNewAddress();
            } else {
              this.createOrderWithExistingAddress();
            }
            this.spinner.hide('paymentSpinner');
            alert(
              'El pago se ha realizado con éxito. Te llegará un correo a la brevedad.'
            );
            this.router.navigate(['/gracias']);
          } else {
            alert('Error al pagar');
            this.spinner.hide('paymentSpinner');
          }
        });
      },
      (error) => {
        console.log(error);
        alert('Error al pagar');
      }
    );
  }
} */
