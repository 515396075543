import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root", })
export class ShippingService {
  header = new Headers({
    'Authorization': `${environment.authorization}`,
    'Content-Type': 'application/json'
  });
  private pickupDateDoc: AngularFirestoreDocument
  private pickupDateValue

  constructor(private http: HttpClient, private firestore: AngularFirestore) {
    this.pickupDateDoc = this.firestore.collection('pickup').doc('scheduled')
    this.pickupDateValue = this.pickupDateDoc.valueChanges()/* .pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    ); */
  }

  getPickupDate() {
    return this.pickupDateValue
  }

  setPickupDate(date) {
    return this.pickupDateDoc.update({ date: date })
  }

  createShippingOrder(data) {
    return this.http.post(`${environment.endpoint}/shipment/request`, data, {
      headers: { 'Authorization': "55f68891-71f8-4078-86f3-5c288918be1f", 'Content-Type': 'application/json' }
    })
  }

  trackOrder(orderID) {
    try {
      return this.http.get(`${environment.endpoint}/track-by-orders/${orderID}`, {
        headers: { 'Authorization': "55f68891-71f8-4078-86f3-5c288918be1f", 'Content-Type': 'application/json' }
      })
    } catch (error) {
      return error
    }
  }

}
