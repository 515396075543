<div class="wrapper">
    <div class="sidebar">
        <!-- <img src="../../../assets/Logos/Gm-navbar-logo.png" alt="" class="sidebar-logo"> -->
        <p class="admin-tab" routerLink="/admin-productos" routerLinkActive="selected-tab">Productos</p>
        <p class="admin-tab" routerLink="/admin-caracteristicas" routerLinkActive="selected-tab">Características</p>
        <p class="admin-tab" routerLink="/admin-pedidos" routerLinkActive="selected-tab">Pedidos</p>
        <p class="admin-tab" routerLink="/admin-perfiles" routerLinkActive="selected-tab">Perfiles</p>
        <p class="admin-tab" routerLink="/admin-cupones" routerLinkActive="selected-tab">Cupones</p>
        <p class="admin-tab" routerLink="/admin-slider" routerLinkActive="selected-tab">Slider</p>
        <p class="admin-tab" routerLink="/admin-campañas" routerLinkActive="selected-tab">Campañas</p>
    </div>
    <div class="content">
        <div class="header">
            <p>Campañas</p>
        </div>
        <div class="body">
            <div class="input">
                <label for="">Fuente:</label>
                <input type="text" [(ngModel)]="source">
            </div>
            <div class="input">
                <label for="">Medio:</label>
                <input type="text" [(ngModel)]="medium">
            </div>
            <div class="input">
                <label for="">Nombre de Campaña:</label>
                <input type="text" [(ngModel)]="campaign">
            </div>
            <div class="input">
                <label for="">ID de producto:</label>
                <input type="text" [(ngModel)]="product">
            </div>
            <div class="input">
                <label for="">URL de registro:</label>
                <p class="utm-url">https://gmeshop.mx/#/registro?utm_source={{source}}&utm_medium={{medium}}&utm_campaign={{campaign}}</p>
            </div>
            <div class="input">
                <label for="">URL de producto:</label>
                <p class="utm-url">https://gmeshop.mx/#/producto/{{product}}?utm_source={{source}}&utm_medium={{medium}}&utm_campaign={{campaign}}</p>
            </div>
        </div>
    </div>
</div>
